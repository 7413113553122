const { observer } = require("mobx-react-lite");

import { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { toJS } from "mobx";
import { ClientContext } from "../../stores/ClientStore";
import "./ClaimedPage.css";

const ClaimedPage = observer(() => {
  const clientStore = useContext(ClientContext);

  //

  return (
    <Container className="container-report-box">
      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="8">
          <div className="claim-page pt-4 pb-1 mb-3">
            <p>
              Liebe Reisende/lieber Reisender,{" "}
              {/* {clientStore.responses["Allgemeines"].sex}{" "}
              {clientStore.responses["Allgemeines"].firstName}{" "}
              {clientStore.responses["Allgemeines"].lastName} */}
            </p>
            <p>
              vielen Dank für die Beauftragung von LegalAI zur Durchsetzung Ihrer
              Ansprüche.
            </p>

            <p>
              Wir beginnen nun mit der Bearbeitung Ihres Rechtsfalles und werden
              Sie innerhalb der nächsten drei Werktage darüber informieren, ob und
              in welcher Höhe wir das Reiseunternehmen zur Zahlung einer
              Entschädigungsforderung auffordern.
            </p>

            <p>
              Bitte bewahren Sie noch vorhandene Reiseunterlagen (z.B.
              Buchungsbestätigungen, Rechnungen, schriftliche Kommunikation bzgl.
              Mängelanzeige etc.) gut auf, denn es könnte sein, dass wir dem
              Reiseunternehmen Ihre Unterlagen zukommen werden. Bei Bedarf kommen
              wir diesbezüglich auf Sie zurück.
            </p>
            <p>
              Selbstverständlich werden wir Sie über den Verlauf des Verfahrens
              auf dem Laufenden halten.
            </p>
            {/* <p>
              Außerdem können Sie jederzeit den Status Ihres Falles im Portal
              LegalAI einsehen.
            </p> */}
            <p>Mit freundlichen Grüßen</p>
            <p>Ihr LegalAI Team</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default ClaimedPage;
