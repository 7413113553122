import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./MultiSelect.styles.css";

const animatedComponents = makeAnimated();

function MultiSelect({ isMultiSelectEnabled, options, onChange, ...props}) {
    // const [selectedOptions, setSelectedOptions] = useState([]);
    // const handleSelect = () => {
    //     console.log(selectedOptions);
    // };

    return (
        <>
            <Select
                defaultValue={props.defaultValue ? props.defaultValue : ""}
                components={animatedComponents}
                isMulti={isMultiSelectEnabled}
                options={options}
                onChange={onChange}
                className="select"
                isClearable={true}
                isSearchable={true}
                isDisabled={props.disabled}
                isLoading={false}
                isRtl={false}
                closeMenuOnSelect={true}
                placeholder="Bitte auswählen"
            />
            {/* <button onClick={handleSelect}>Proceed</button> */}
        </>
    );
};

export default MultiSelect;
