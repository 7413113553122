import { useContext } from "react";
import { DocumentContext } from "../../stores/DocumentStore";
import Sklate from "./Sklate";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import "./sklate.css";

const EditorTestPage = observer((props) => {
  return (
    <div>
      <Sklate />
      <ConditionAdding />
    </div>
  );
});

const ConditionAdding = observer((props) => {
  const documentStore = useContext(DocumentContext);

  const conditionBlock = (cnd, ind) => {
    const reference =
      documentStore.conditions[documentStore.activeConditionThread].conditions[
        ind
      ];

    return (
      <div key={ind} className="condition-block-main">
        <div className="condition-block-sub">
          {reference.mixer ? (
            dropDown(
              "",
              ["and", "or", "not"],
              reference.mixer,
              (e) => (reference.mixer = e.target.value)
            )
          ) : (
            <></>
          )}
        </div>
        <div>
          {reference.modifier ? (
            dropDown(
              "modifier",
              ["equals", "greater than", "lesser than"],
              reference.modifier,
              (e) => (reference.modifier = e.target.value)
            )
          ) : (
            <></>
          )}
        </div>
        <div>
          {reference.operator1 ? (
            dropDown(
              "1st Operator",
              ["var1", "var2", "var3"],
              reference.operator1,
              (e) => (reference.operator1 = e.target.value)
            )
          ) : (
            <></>
          )}
        </div>
        <div>
          {reference.operator2 ? (
            dropDown(
              "2nd Operator",
              ["var1", "var2", "var3"],
              reference.operator2,
              (e) => (reference.operator2 = e.target.value)
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{documentStore.activeConditionThread}</div>
      <div>
        {documentStore.conditions[
          documentStore.activeConditionThread
        ]?.conditions?.map(conditionBlock)}
        <div>
          {documentStore.conditions[documentStore.activeConditionThread]
            ?.conditions ? (
            <button
              onClick={(e) => {
                documentStore.conditions[
                  documentStore.activeConditionThread
                ].conditions.push({
                  mixer: "and",
                });
                documentStore.conditions[
                  documentStore.activeConditionThread
                ].conditions.push({
                  modifier: "equals",
                  operator1: "var1",
                  operator2: "var2",
                });
              }}
            >
              Add Condition
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
});

const dropDown = (label, options, value, onChange) => {
  return (
    <div className="condition-dd">
      <span>{label}</span>
      <span>
        <select value={value} onChange={onChange} className="editor-select">
          <option>select...</option>
          {options.map((x, i) => (
            <option value={x} key={i}>
              {x}
            </option>
          ))}
        </select>
      </span>
    </div>
  );
};

export default EditorTestPage;
