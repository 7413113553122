import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useContext } from "react";
import { ClientContext } from "../../../stores/ClientStore";
import {
  subQuestionElements,
  registerResponse,
  renderOptionsForQues,
} from "./Utils";

import { toJS } from "mobx";

import { observer } from "mobx-react-lite";
import ResolveElement from "../ResolveElement";

export const ResolveRadio = observer(({ question, ...props }) => {
  const clientStore = useContext(ClientContext);

  const radioType = question.type;

  const getStore = () => (props.adminStore ? props.adminStore : clientStore);

  const radioStyle = {
    color: "#3249B4",
    fontSize: "100%",
  };

  const resolveQuestionId = () =>
    `${question.id}${props.addSuffix ? `-${props.suffix}` : ""}`;

  const isChecked = (option)=>{
    if(props.adminStore){
      if(getStore().responses[`${resolveQuestionId()}`] == option.text)
        return true;
      return false;
    }
    for( let key in getStore().responses){
      if(getStore().responses[key][`${resolveQuestionId()}`] == option.text)
        return true;
    }
    return false;
  }

  const onChange = async (event, onChangeOption) => {
    getStore().endCurrentFlow = false;
    getStore().endFlow = false;

    //registerResponse(event, question, getStore());

    ({ event, question });

    var temp = getStore().currentResponses();

    temp[`${resolveQuestionId()}`] = event.target.value;

    getStore().responses[getStore().activeTab[1].formName] = {
      ...temp,
    };

    //Reset respones for all subquestions if option is unchecked
    question.options.map((option) => {
      if (getStore().currentResponses()[question.id] != option.text) {
        if (option.subquestions) {
          let temp = getStore().currentResponses();

          option.subquestions.map((subqid) => {
            temp[subqid] = null;
          });

          getStore().responses[getStore().activeTab[1].formName] = {
            ...temp,
          };
        }
        else if (option.triggers?.length >= 1){
          let temp = getStore().currentResponses();

          option.triggers.map((questions) => {
            questions.questions.map((question)=>{
              temp[question.name] = null;
            });
          });

          getStore().responses[getStore().activeTab[1].formName] = {
            ...temp,
          };
        }
      }
    });

    //Check for scroll
    if (getStore().currentQuestion == question.id) {
      if (onChangeOption.subquestions) {
        var element = document.getElementById(`${question.name}`);

        setTimeout(() => {
          window.scrollBy(0, element?.offsetHeight);
        }, 300);
      } else {
        document
          .getElementById(`${getStore().activeQuestion}-next-button`)
          ?.click();
      }
    } else {
      var element = document.getElementById(`${question.name}`);

      if (!props.shouldNotScroll) {
        setTimeout(() => {
          window.scrollBy(0, element?.offsetHeight);
        }, 300);
      }
    }
  };

  const resolveProps = {
    disabled: props.disabled ?? false,
    shouldNotScroll: props.shouldNotScroll ?? true,
    rawElement: props.rawElement ?? false,
    tableFix: props.tableFix ?? false,
    tableSuffix: props.tableSuffix ?? false,
  };

  const isRawElement = props.rawElement ? true : false;

  return (
    <>
      <div>
        <RadioGroup
          row={radioType == "inlineradio"}
          aria-label={question.name}
          name="controlled-radio-group"
        >
          {(renderOptionsForQues(question.options, question.name, question)
            .length == question.options
            ? renderOptionsForQues(question.options, question.name, question)
            : question.options
          ).map((option, optionIndex) => {
            if (option.hide) return;
            return (
              <FormControlLabel
                name={option.text}
                onChange={(event) => onChange(event, option)}
                value={option.text}
                id={`${question.name}-${option.value}`}
                checked={
                  isChecked(option)
                }
                control={<Radio size="small" style={radioStyle} />}
                label={option.text}
                disabled={option.disabled || props.disabled}
              />
            );
          })}
        </RadioGroup>
        <div className="sub-question">
          {subQuestionElements(
            question,
            ResolveElement,
            resolveProps,
            getStore(),
            props.rawElement,
            props.adminStore
          )}
        </div>
      </div>
    </>
  );
});
