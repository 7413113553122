import React, { useState, useEffect, useRef, useContext } from "react";
import { ClientContext } from "../../stores/ClientStore";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(regions)"] }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  console.log(addressObject);
}

export const CityElement = observer((props) => {
  const clientStore = useContext(ClientContext);

  let city = clientStore.currentResponses() ? clientStore.currentResponses()['city'] ? clientStore.currentResponses()['city'] : '' : '';
  const [query, setQuery] = useState(city);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC_cr0NEfSB_YQFQscXhEMNwCa__dUtaH8&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if(props.adminStore){
      setQuery(props.adminStore.responses['city']);
      return;
    }
    
    const temp = clientStore.currentResponses();
    temp["city"] = query;

    clientStore.responses[clientStore.activeTab[1].formName] = {
      ...temp,
    };

    console.log("responses", toJS(clientStore.currentResponses()));
  }, [query]);

  return (
    <div className="search-location-input">
      <form
        action="/dontgohere"
        onSubmit={(e) => e.preventDefault()}
        onSubmitCapture={(e) => e.preventDefault()}
      >
        <input
          type="text"
          ref={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Stadt, Region oder Land"
          onSubmit={(e) => e.preventDefault()}
          onSubmitCapture={(e) => e.preventDefault()}
          value={query}
          disabled={props.disabled}
          style={{width: '100%'}}
        />
      </form>
    </div>
  );
});
