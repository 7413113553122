import React from 'react';
import "./form.css";
import { Container, Row } from 'react-bootstrap';

const InstructionPage = () => {
  return (
    <Container className="instructionBar my-4 p-4">
      <Row>
        <b>Merkblatt zur erfolgreichen Durchsetzung Deiner Ansprüche im Reiseprozess</b>
        <p>Danke nochmal für Dein Vertrauen in HolidaySherpa!</p>
        <p>Im Folgenden wollen wir Dir ein paar Informationen zum Reiseprozess mit an die Hand geben, damit Du uns bestmöglich dabei unterstützen kannst, Deine Rechte durchzusetzen. </p>
        <p>Der erfolgreiche Ausgang eines Rechtsstreits im Reiserecht hängt maßgeblich davon ab, inwiefern das Gericht von der Mangelhaftigkeit der Reise überzeugt wird. Dies hängt wiederum von der Genauigkeit der Angaben des Reisenden zum Sachverhalt ab.</p>
        <p>Deshalb sind wir für einen erfolgreichen Ausgang des Rechtsstreits unbedingt auf Deine Hilfe angewiesen!</p>

        <b>Was ist ein Reisemangel?</b>
        <p>Um einen Anspruch auf Reisepreisminderung geltend machen zu können, muss die Pauschalreise mangelhaft sein. Nach § 651i BGB liegt ein Reisemangel vor, wenn die vertraglich vereinbarten Leistungen überhaupt nicht, nur ungenügend oder anders als vereinbart erbracht wurden. Es muss daher ein Vergleich zwischen geschuldeter Leistung und tatsächlich erbrachter Leistung hergestellt werden. Wird zum Beispiel von Dir angezeigt, dass ein Pool in Deiner Unterkunft geschlossen war, müssen wir zunächst prüfen, ob gemäß Deinen Reiseunterlagen das Vorhandensein eines Pools überhaupt zugesichert wurde. </p>
        <p>Deshalb benötigen wir zur Bearbeitung Deines Falls grundsätzlich immer sowohl den Reisevertrag (Reisebestätigung) und die Reisebeschreibung, die ebenfalls Bestandteil des Vertrags geworden ist. Dies kann einerseits die Beschreibung im Katalog sein, andererseits aber auch die Beschreibung im Internet, aufgrund der Du die Buchung Deiner Reise vorgenommen hast. </p>

        <b>Anzeige des Reisemangels</b>
        <p>Für die erfolgreiche Geltendmachung eines Reisepreisminderungsanspruchs ist erforderlich, dass Du den Reisemangel unverzüglich nach Feststellung angezeigt hast. Dabei reicht es nicht aus, dass Du den Mangel an der Rezeption beanstandet hast. Du bist vielmehr dazu verpflichtet, Deine Reiseleitung, das Reisebüro oder den Reiseveranstalter an seinem Sitz so schnell wie möglich darüber zu informieren, dass ein Reisemangel vorliegt. Nach Art. 250 § 6 Abs. 2 Nr. 4 EGBGB hat der Reiseveranstalter in den Reiseunterlagen eine Kontaktstelle anzugeben, an welche Du Deine Mängelanzeige richten solltest. Nicht erforderlich ist hingegen, dass Du die Abhilfe des Mangels verlangst. Es kommt bei der Mängelanzeige darauf an, dass die Reiseleitung von dem Mangel in Kenntnis gesetzt wird. Solltest Du den Reiseveranstalter jedoch dazu aufgefordert haben, einen Mangel zu beheben, so steckt in diesem Verlangen gleichzeitig auch eine Mängelanzeige, weshalb sie nicht noch einmal gesondert erfolgen muss. </p>
        <p>Das Gericht erwartet hierbei eine ausführliche Darlegung, wann, welcher Reisemangel wem gegenüber angezeigt wurde. Pauschale Angaben, „dass die Reisemängel unverzüglich angezeigt wurden“, genügen nicht den Anforderungen. Gerade bei mehreren, vielleicht sogar zeitlich versetzt auftretenden Mängeln ist erforderlich, dass Du zu jeder einzelnen Mängelrüge konkrete Angaben machst, wann sie wem gegenüber erfolgte. Dabei solltest Du auch Angaben dazu machen, welche Reaktion die Mängelanzeige nach sich zog. </p>
        <p>Unter gewissen Umständen bedarf es keiner Mängelanzeige, zum Beispiel wenn der Mangel ohnehin nicht behoben werden kann oder der Reiseveranstalter den Mangel bereits kennt. Sollte der Reiseveranstalter keine Kontaktstelle angegeben haben, stehst Du ebenso nicht in der Pflicht, den Reisemangel anzuzeigen. </p>
        <p>Da Du als Reisende(r) jedoch beweisen musst, warum eine Mängelanzeige im Einzelfall nicht erforderlich war, empfehlen wir dringend, den Mangel trotzdem anzuzeigen.</p>

        <b>Beschreibung des Reisemangels </b>
        <p>Wichtig ist, dass Du den Reisemangel für uns genau beschreibst, damit wir die für Dich bestmöglichen Ergebnisse im Rechtsstreit erzielen können. Durch äußerst detaillierte Tatsachenangaben muss deutlich werden, welcher Reisemangel in welchem Zeitraum der Reise und in welcher Intensität vorgelegen hat. </p>
        <p>Pauschale Angaben, wie z.B. „das Essen war ungenießbar“, „der Hotelstandard entsprach nicht der gebuchten Kategorie“, „zeitweise ist der Strom ausgefallen, „die Klimaanlage war zu kalt“ oder „der Service war unzureichend“ reichen nicht aus. Es müssen objektive und erhebliche Mängel vorliegen, um einen Anspruch auf Reisepreisminderung zu begründen. Daher wäre es hilfreich, wenn Du den genauen Geschehensablauf schriftlich zusammenfassen könntest. </p>
        <p>Da es durchaus vorkommen kann, dass verschiedene Reisemängel während Deiner Reise nicht zeitgleich auftreten, bitten wir Dich außerdem, in chronologischer Reihenfolge für jeden einzelnen Reisemangel separat Angaben zum Auftreten zu machen. Sollte Deine Reise nur teilweise von dem Reisemangel betroffen gewesen sein, bitten wir Dich, den genauen Zeitraum anzugeben. Dafür eignet es sich gut, dass Du das Auftreten der einzelnen Reisemängel in tabellarischer Form angibst. </p>

        <b>Beweislage</b>
        <p>Der Erfolg Deines Rechtsstreits hängt maßgeblich von der Beweislage ab. Da Du im Rahmen eines Gerichtsprozesses für den Beweis des Vorliegens von Reisemängeln zuständig bist, wird das Gericht nicht zu Deinen Gunsten entscheiden, wenn Du den Mangel nicht beweisen kannst. Bitte stelle uns daher alle Beweise zur Verfügung, die wir im Streitfall für Dich vorlegen können: Fotos oder Videos von Reisemängeln, Anschriften von Zeugen, die den Reisemangel bestätigen können, Belege über die erfolgte Mängelanzeige, Zahlungsablehnung des Reiseveranstalters bzw. weitere Korrespondenz etc. </p>
        <b className='ps-4'>Fotos</b>
        <p className='ps-4'>Da Fotos meist nicht selbsterklärend sind, bitten wir Dich - solltest Du Fotos als Beweismittel für das Vorliegen von Reisemängeln verwenden wollen - den Fotos eine kurze Notiz bzw. Erläuterung beizufügen, inwiefern sich das Vorliegen des jeweiligen Reisemangels aus der Fotografie ergibt. </p>
        <b className='ps-4'>Zeugen</b>
        <p className='ps-4'>Zeugen sind grundsätzlich geeignete Beweismittel für den Reiseprozess. So kommen als Zeugen zB. unbeteiligte Mitreisende sein, die den festgestellten Mangel tatsächlich beobachtet haben, aber auch Familienmitglieder. Grundsätzlich wird die Person, die die Reise gebucht hat, zum Vertragspartner des Reiseveranstalters und somit zum Kläger im Rechtsverfahren. Daher könnten auch mitreisende Ehepartner als Zeugen in Frage kommen. Es gibt keine grundsätzlichen Einschränkungen bezüglich der Aussagen von Ehepartnern. Das Gericht geht davon aus, dass jeder Zeuge, auch wenn er ein naher Angehöriger ist, die Wahrheit sagt. Daher kann auch ein Ehepartner, der ein eigenes wirtschaftliches Interesse am Ausgang des Prozesses hat, uneingeschränkt als Zeuge aussagen.
          <br></br>
          Es ist nicht notwendig, eine umfangreiche Liste von Zeugen zu erstellen, die dann im Prozess benannt werden. Eine große Anzahl von Zeugen würde den Rechtsprozess unnötig verlängern, da diese Zeugen möglicherweise nach und nach vor ihren Wohnsitzgerichten vernommen werden müssten. Ein zuverlässiger Zeuge, der den Inhalt seiner Aussage glaubhaft bestätigen kann, ist vorteilhafter als viele Zeugen, deren persönliches Erinnerungsvermögen und Überzeugungskraft unbekannt sind.
        </p>

        <b>Anspruchshöhe bei Reisemängeln</b>
        <p>Wenn das Gericht tatsächlich feststellt, dass ein Reisemangel vorliegt, dann bestimmt es für den Reisemangel eine Minderungsquote, d.h. Du hast je nach Minderungsquote Anspruch auf Rückzahlung des Reisepreis, den Du gezahlt hast. 
          <br></br>
          Übersteigt die Minderung eines jeweiligen Tagesreisepreises die Quote von 50 %, so tritt der Anspruch auf Entschädigung wegen nutzlos aufgewendeter Urlaubszeit hinzu. Der Höhe nach orientiert sich dieser Entschädigungsanspruch am Betrag des geminderten Reisepreises.
          <br></br>
          Ob und in welcher Höhe ein Anspruch auf Reisepreisminderung oder auf Schadensersatz besteht, ist gesetzlich nicht definiert und steht daher nach § 287 ZPO im freien Ermessen des Gerichts. Deshalb hängt die Höhe des Anspruches ganz besonders von der Genauigkeit Deines Vortrags ab.
        </p>
      </Row>
    </Container>
  )
};

export default InstructionPage;
