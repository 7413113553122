import { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import firebase from "../../Utils/firebase";
import { UserContext } from "../../Utils/UserContext";

function PrivateRoute2({ component: Component, ...rest }) {
  // 0 - Loading
  // 1 - DENIED
  // 2 - Valid

  const { authState } = useContext(UserContext);

  const [state, setState] = useState(0);

  useEffect(() => {
    const initRouteCheck = async () => {
      // gives access if user is logged in and have multi-factor authentication enabled
      if ( authState.user ) {
        setState(2);
      } else {
        setState(1);
      }
    };

    initRouteCheck();
  }, [authState.user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (state === 0) {
          return <div>Loading...</div>;
        }
        if (state === 1) {
          return <Redirect to={{ path: "/" }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute2;
