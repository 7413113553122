// import { observable, get, makeObservable, makeAutoObservable } from "mobx";
import { useLocalStore } from "mobx-react-lite";
import { toJS } from "mobx";
import { createContext } from "react";

export const createClientStore = () => {
  return {
    activeQuestion: 0,

    forms: [],
    addedFormOrder: [],

    completedForms: {},

    lastAttemptedQuestion: 0,

    addList: [],
    modifyList: [],
    appendList: [],
    removeList: [],
    formsUsed: [],

    refs: {
      demo: null,
    },

    allFlowsCompleted: false,

    validateQuestions: [],

    currentQuestion: {},

    responses: {},

    errorMessages: {},

    flowMap: [],

    currentReport: {},

    countries: [],
    cities: [],

    endFlow: false,
    endMessage: "",

    finalQuestion: true,
    submit: false,

    // screenHeight: 100,
    loadingOptions: {
      questions: false,
      claimed: false,
      report: false,
    },

    uploadedFiles: {},

    displayComponent: "QUESTIONS",
    endCurrentFlow: false,

    currentDisclaimer: "",

    modifyWith: [],
    appendFlow: [],

    blockWeiter: 0,

    get activeTab() {
      var y = this.traverseForm(this.activeQuestion)[1];
      return [y, this.forms[y]];
    },

    isTabLocked(index) {
      const firstQuestion = this.forms
        .slice(0, index)
        .reduce((v, x) => v + x.questions.length, 0);

      "isTabLocked", toJS(this.lastAttemptedQuestion), toJS(firstQuestion);

      if (this.lastAttemptedQuestion >= firstQuestion) {
        return false;
      }

      return true;
    },

    get getProgress() {
      var index = this.activeQuestion;

      var ret = [];

      for (var i = 0; i < this.forms.length; i++) {
        if (!(this.forms[i] && this.forms[i].questions)) continue;
        if (index < this.forms[i].questions.length) {
          ret.push(index);
          index = 0;
        } else {
          ret.push(this.forms[i].questions.length);
          index -= this.forms[i].questions.length;
        }
      }

      return ret.map((x, i) => (x * 100) / this.forms[i].questions.length);
    },

    traverseForm(index) {
      for (var i = 0; i < this.forms.length; i++) {
        if (index < this.forms[i].questions.length) {
          return [this.forms[i].questions[index], i];
        } else {
          index -= this.forms[i].questions.length;
        }
      }

      return [-1, -1];
    },

    updateTab(index) {
      // The reduce returns the accumulated index of the first question of a particular form
      if (index < this.forms.length) {
        this.updateQuestion(
          this.forms.slice(0, index).reduce((v, x) => v + x.questions.length, 0)
        );
      }

      this.validateQuestions = [];
    },

    currentResponses() {
      if (
        !this.activeTab ||
        !this.activeTab[1] ||
        !this.activeTab[1].formName ||
        !this.responses
      ) {
        return;
      }
      if (!this.responses[this.activeTab[1].formName]) {
        this.responses[this.activeTab[1].formName] = {};
      }
      return this.responses[this.activeTab[1].formName];
    },

    getFormIdFromName(query) {
      this.forms.filter((form) => form.formName == query)[0];
    },

    get questionList() {
      var ret = [];
      this.forms.map((x) => {
        ret = ret.concat(x.questions);
      });

      return ret;
    },

    updateQuestion(index) {
      if (index < this.forms.reduce((i, x) => i + x.questions.length, 0)) {
        this.currentQuestion = this.traverseForm(index)[0];
        this.activeQuestion = index;
      } else {
        this.submit = true;
      }
    },
  };
};

export const ClientContext = createContext(null);

export const ClientProvider = ({ children }) => {
  const clientStore = useLocalStore(createClientStore);

  return (
    <ClientContext.Provider value={clientStore}>
      {children}
    </ClientContext.Provider>
  );
};
