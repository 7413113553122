import { useEffect, useMemo, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../Utils/Helper";
import { deserialize } from "./serializingUtils";
import Sklate from "./Sklate";
import { AdminContext } from "../../stores/AdminStore";
import ReactLoading from "react-loading";
import { observer } from "mobx-react-lite";

const PreviewPage = observer(() => {
  const { documentId } = useParams();
  const adminStore = useContext(AdminContext);
  const [previewData, setPreview] = useState(null);

  useEffect(() => {
    const fetchStuff = async () => {
      const [preview, error] = await fetchData(
        `/docapi/new-document/preview/fetch/${documentId}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(adminStore.currentCase.responses),
        }
      );

      if (error) {
        return;
      }

      const value = preview;
      const dom = new DOMParser().parseFromString(value, "text/html");
      // console.log('value', value);

      const dslVal = deserialize(dom.body);

      setPreview(dslVal);
    };
    fetchStuff();
  }, []);

  return (
    <div>
      <Header />
      {previewData ? (
        <div className="center">
          <Sklate
            value={previewData}
            mentionList={[]}
            onChange={(newValue) => {
              setPreview([...newValue]);
            }}
          />
        </div>
      ) : (
        <ReactLoading type="spokes" color="black" />
      )}
    </div>
  );
});

const Header = (props) => {
  return <div></div>;
};

export default PreviewPage;
