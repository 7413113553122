import { observer } from "mobx-react-lite";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const DragList = observer((props) => {
  var { list, resolver, setList } = props;

  const handleDrag = (list, start, end) => {
    const result = Array.from(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);

    return result;
  };

  return (
    <div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return;

          const items = handleDrag(
            list,
            result.source.index,
            result.destination.index
          );

          setList([...items]);
          //list = [...items];
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list &&
                list.map((x, i) => {
                  return (
                    <Draggable key={`${i}`} draggableId={`${i}`} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {resolver(x, i)}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
});
