import "./App.css";
import { useMemo } from "react";
import Questionaire from "./Pages/QuestionPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Header from "./Components/Common/Header2";
import AdminPage from "./Pages/AdminPage";
import HolidaysherpaPage from "./Pages/HolidaysherpaPage";
import InstructionPage from "./Components/HolidaySherpaForm/InstructionPage";
import PrivateRoute from "./Components/Common/PrivateRoute";
import PrivateRoute2 from "./Components/Common/PrivateRoute2";
import RestrictedRoute from "./Components/Common/RestrictedRoute";
import AddElement from "./Components/Flow/AddElement";
import LoginPage from "./Pages/LoginPage";
import HolidaySherpaLoginPage from "./Pages/HolidaySherpaLoginPage";
import HolidaySherpaCases from "./Pages/HolidaySherpaCases";
import ReactLoading from "react-loading";
import EditTable from "./Components/Flow/EditTable";

import { UserContext } from "./Utils/UserContext";
import { useAuth } from "./Utils/useAuth";

import { ClientProvider } from "./stores/ClientStore";
import { DocumentProvider } from "./stores/DocumentStore";
import { EditProvider } from "./stores/EditStore";
import MainDocument from "./Components/Document/MainDocument";
import { Subdocument } from "./Components/Document/Subdocument";
import { AdminProvider } from "./stores/AdminStore";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SimilarityEngineProvider } from "./stores/SimilarityStore";
import CaseSimilarityEngine from "./Components/SimilarityEngine/similarityEngine";
import ClientsPage from "./Pages/ClientsPage";
import Preview from "./Components/Flow/Preview";
import { CacheProvider } from "./stores/CacheStore";
import EditorTestPage from "./Components/DocumentSklate/EditorTestPage";

import NewSubdocument from "./Components/DocumentSklate/SubDocuments";
import NewMainDocument from "./Components/DocumentSklate/MainDocument";
import PreviewPage from "./Components/DocumentSklate/PreviewPage";
import SubdocumentListPage from "./Components/DocumentSklate/SubdocumentListPage";
import MainDocumentListPage from "./Components/DocumentSklate/MainDocumentListPage";
import EditForm from "./Components/Flow/EditForm";
import Sklate from "./Components/DocumentSklate/Sklate";

import HolidaySherpaForm from "./Components/HolidaySherpaForm/index";
import { Playground } from "./Playground";
import { DisplayResponses } from "./Components/Admin/DisplayResponses";
import { DisplayWebResponses } from "./Components/Admin/DisplayWebResponses";
import LandingPage2 from "./Pages/LandingPage2";
import Footer from "./Components/Common/Footer";

function initializeReactGA() {
  ReactGA.initialize("G-QFQZGHJQZL");
  ReactGA.send("pageview");
}

function App() {
  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};

  //calling function
  initializeReactGA();
  const { authState, setAuthState } = useAuth();

  const userValue = useMemo(
    () => ({ authState, setAuthState }),
    [authState, setAuthState]
  );

  return authState.initializing ? (
    <div className="full-size-center">
      <ReactLoading type="spokes" color="black" />
    </div>
  ) : (
    <div className="App">
      <UserContext.Provider value={userValue}>
        <CacheProvider>
          <EditProvider>
            <ClientProvider>
              <DocumentProvider>
                <SimilarityEngineProvider>
                  <AdminProvider>
                    <Router>
                      <Header />
                      <Switch>
                        <RestrictedRoute component={AdminPage} path="/admin" />
                        <RestrictedRoute path="/holidaysherpa" exact component={HolidaysherpaPage} />
                        <Route path="/login" exact children={<LoginPage />} />
                        <Route path="/holidaysherpa/login" exact children={<HolidaySherpaLoginPage />} />
                        <PrivateRoute2 path="/holidaysherpa/cases" exact component={HolidaySherpaCases} />

                        <Route
                          path="/holidaysherpa/instructions"
                          exact
                          children={<InstructionPage />}
                        />

                        <Route
                          path="/test/editor"
                          exact
                          children={<EditorTestPage />}
                        />

                        <RestrictedRoute
                          path="/preview"
                          exact
                          component={Preview}
                        />
                        <RestrictedRoute
                          path="/editform"
                          exact
                          component={EditForm}
                        />
                        {/* <RestrictedRoute
                          path="/editform"
                          exact
                          component={AddElement}
                        /> */}
                        <RestrictedRoute
                          path="/view-sub/:subDocumentId"
                          exact
                          component={NewSubdocument}
                        />
                        <RestrictedRoute
                          path="/view-form/:formId"
                          exact
                          component={AddElement}
                        />

                        <RestrictedRoute
                          path="/subdocument"
                          exact
                          component={SubdocumentListPage}
                        />

                        <RestrictedRoute
                          path="/view-doc/:documentId"
                          exact
                          component={NewMainDocument}
                        />

                        <RestrictedRoute
                          path="/maindocument"
                          exact
                          component={MainDocumentListPage}
                        />

                        <RestrictedRoute
                          path="/old/subdocument"
                          exact
                          component={Subdocument}
                        />
                        <RestrictedRoute
                          path="/old/maindocument"
                          exact
                          component={MainDocument}
                        />
                        <RestrictedRoute
                          path="/similarityEngine"
                          exact
                          component={CaseSimilarityEngine}
                        />

                        <RestrictedRoute
                          path="/clients"
                          exact
                          component={ClientsPage}
                        />

                        <RestrictedRoute
                          path="/managetables"
                          exact
                          component={EditTable}
                        />

                        <Route
                          path="/preview/:documentId"
                          exact
                          children={<PreviewPage />}
                        />

                        <Route
                          path="/editortest"
                          exact
                          children={
                            <Sklate
                              mentionList={["han solo"]}
                              value={[{ text: "demo" }]}
                              onChange={() => { }}
                            />
                          }
                        />

                        <Route
                          path="/view-response/:responseId"
                          exact
                          children={<Playground />}
                        />

                        <Route
                          path="/playground"
                          exact
                          children={<Playground />}
                        />

                        <RestrictedRoute
                          path="/display-response"
                          exact
                          component={DisplayResponses}
                        />

                        <RestrictedRoute
                          path="/display-web-response"
                          exact
                          component={DisplayWebResponses}
                        />

                        <PrivateRoute
                          path="/:quesId"
                          exact
                          component={Questionaire}
                        />

                        <PrivateRoute2
                          path="/holidaysherpa/form/:formId/:caseId/:uid"
                          exact
                          component={HolidaySherpaForm}
                        />

                        <Route
                          path="/"
                          exact
                          component={() => <LandingPage2 />}
                        />
                      </Switch>
                    </Router>
                  </AdminProvider>
                </SimilarityEngineProvider>
              </DocumentProvider>
            </ClientProvider>
          </EditProvider>
        </CacheProvider>
      </UserContext.Provider>
      <Footer />
    </div>
  );
}

const OldFooter = () => {
  return (
    <Container className="mt-4 pt-4">
      <Row className="justify-content-center">
        <Col xs="12">
          <p className="text-center">
            Copyright © 2022 by{" "}
            <a
              className="link"
              href="https://www.legalai.io/"
              target="_blank"
            >
              LegalAI
            </a>
            |{" "}
            <a
              className="link"
              href="https://www.legalai.io/impressum"
              target="_blank"
            >
              Impressum
            </a>{" "}
            |{" "}
            <a
              href="https://www.legalai.io/privacy"
              target="_blank"
            >
              Datenschutz & cookies
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
