import { useLocalStore } from "mobx-react-lite";
import { createContext } from "react";

export const createEditStore = () => {
  return {
    variablesNew: [],
    tableList: [],
    formID: "",
    formName: "",
    elements: [], // list of all top level questions in any flow
    fileName: "select", // current file name selected in dropdown
    allFlows: [], // list of all the flows/forms available Schema of single flow : {"id" : "","updatedAt":""}
    flowMap: [], // flow map to trigger any sub flow from the selected flow
    selectedFlow: {
      id: "select",
    },
    newFlowName: "",

    allTables: [],
    currentTable: {},
    currentTableId: "",

    refresh: false,

    previewResponses: {},

    deleteQuestion(questionName) {
      const dfs = (elements) => {
        if (!elements) return;

        for (var i = 0; i < elements.length; i++) {
          if (elements[i].name == questionName) {
            elements.splice(i, 1);
            return;
          }
          if (
            elements[i].type == "radio" ||
            elements[i].type == "checkbox" ||
            elements[i].type == "dropdown"
          ) {
            for (var j = 0; j < elements[i].options?.length; j++) {
              if (elements[i].options[j].triggers) {
                dfs(elements[i].options[j].triggers[0].questions);
              }
            }
          } else if (elements[i].type == "parent") {
            dfs(elements[i].children);
          } else if (elements[i].type == "table") {
            dfs(elements[i].headings);
          }
        }
      };

      dfs(this.elements);
    },
  };
};

export const EditContext = createContext(null);

export const EditProvider = ({ children }) => {
  const editStore = useLocalStore(createEditStore);

  return (
    <EditContext.Provider value={editStore}>{children}</EditContext.Provider>
  );
};
