import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { ClientContext } from "../../stores/ClientStore";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./sidebar.css";

const Sidebar = observer((props) => {
  const clientStore = useContext(ClientContext);

  const getScrollOffset = () => {
    let totalOffsetHeight = 0;
    for (let i = 0; i < clientStore.activeQuestion; i++) {
      let element = document.getElementById(`${i.toString().toString()}`);
      totalOffsetHeight += element?.offsetHeight;
    }
    return totalOffsetHeight;
  };

  var AllSections = clientStore.forms.map((x) => x);

  const progressBar = () => {
    var percentage = clientStore.getProgress;

    if (clientStore.allFlowsCompleted) {
      percentage = percentage.map((x) => 100);
    }

    function barStyle(width) {
      return {
        width: `${width}%`,
        backgroundColor: "grey",
        height: "6px",
      };
    }

    function percStyle(percent) {
      return {
        width: `${percent}%`,
        backgroundColor: "#3249B4",
        height: "6px",
        borderRadius: "5px",
        marginBottom: "2px",
      };
    }

    const getWidth = (index, length)=>{
      const progressSize = 100;
      let size = length <= 3 ? progressSize/length : clientStore.activeTab[0] == index ? 35 : (progressSize - 35)/(length-1);
      return `${size}%`;
    }

    return (
      <Container>
        <Row className="justify-content-center" style={{ width: '100%'}}>
          <Col
            xs="12"
            md="10"
            lg="8"
          >
            <div style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "9px",
            }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {AllSections.map((s1, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: getWidth(index, AllSections.length)
                      }}
                    >
                      <span
                        className={`dot`}
                        style={{
                          backgroundColor:
                            clientStore.activeTab[0] == index ? "#3249B4" : "grey",
                        }}
                        title={s1.formName}
                        onClick={() => {
                          if (clientStore.displayComponent == "QUESTIONS") {
                            const progress = clientStore.getProgress;

                            const locked = !progress
                              .slice(0, index)
                              .every((x) => x == 100);

                            if (!locked) {
                              clientStore.updateTab(index);

                              const scrollOffset = getScrollOffset();

                              "scroll", { scrollOffset };

                              setTimeout(() => {
                                window.scrollTo(0, scrollOffset);
                              }, 200);
                            } else {
                              clientStore.rightPanelError =
                                "Bitte beenden Sie zum Fortfahren den aktuellen Fragebogen.";

                              setTimeout(() => {
                                clientStore.rightPanelError = null;
                              }, 5000);
                            }
                          }
                        }}
                        type="button"
                      ></span>
                      <div style={barStyle(98)}>
                        <div style={percStyle(percentage[index])}></div>
                        <div
                          title={s1.formName}
                          style={{
                            color:
                              clientStore.activeTab[0] == index ? "#3249B4" : "black",
                            fontWeight:
                              clientStore.activeTab[0] == index ? "bold" : "normal",
                            marginLeft: "-12px",
                            border: "none",
                            display: clientStore.allFlowsCompleted ? 'none' : 'default'
                          }}
                          className="progress-form-heading"
                        >
                          {clientStore.activeTab[0] == index ? s1.formName : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <span style={{ marginRight: "15px" }} />
                {clientStore.allFlowsCompleted ? <b>Geschafft!</b> : null}
              </div>
              <div 
                className="new-form-heading" 
                style={{
                  color: "#3249B4",
                  fontWeight: "bold",
                  marginLeft: "-12px",
                  border: "none",
                  fontSize: "12px",
                  display: clientStore.allFlowsCompleted ? 'none' : 'default'
                }}
              >
                {clientStore.activeTab[0] >= 0 ? AllSections[clientStore.activeTab[0]].formName : ''}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div className="sidebar">{props.progressBar ? progressBar() : null}</div>
  );
});

export default Sidebar;
