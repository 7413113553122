import React, { useState, useEffect} from 'react';
import { Container, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import firebase from "../Utils/firebase";

function HolidaySherpaCases() {
  const history = useHistory();
  const [caseList, setCaseList] = useState([]);
  const noFormMessage = 'Für das weitere Vorgehen in dieser Rechtssache ist derzeit das Ausfüllen von Formularen nicht erforderlich.';

  useEffect(() => {
    const fetchUserCaseList = async() => {
      try{
        const userMail = firebase.auth().currentUser.email;
        const result = await fetch(`/api/holidaysherpa/fetchUserCaseList/${userMail}`);
        const resp = await result.json();
        console.log('fetchUserCaseList', resp);
        if(resp.success){
          setCaseList(resp.data);
        }
      } catch(err) {
        console.log(err);
      }
    };

    fetchUserCaseList();
  }, []);

  const renderCaseFormStatus = (Case) => {
    if(typeof Case.formStatus == 'undefined'){
      return <h5> {noFormMessage} </h5>
    }

    let bool = true;
    let ind = 0;
    Object.values(Case.formStatus).forEach((form) => {
      if(form.action == 'added'){
        bool = false;
      }
    });

    // console.log('case - ', Case, bool);
    if(bool){
      return <h5> {noFormMessage} </h5>
    }

    return (
      <Table className="table table-striped">
        <thead>
          <tr key="0">
            <th scope="col" width="10%"></th>
            <th scope="col" width="40%">Name des Formulars</th>
            <th scope="col" width="25%">Status</th>
            <th scope="col" width="25%">Formular ausfüllen</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(Case.formStatus).map((formId) => {
              if (Case.formStatus[formId].action == 'added') {
                ind++;
              }

              return Case.formStatus[formId].action == 'added' ? (
                <tr key={ind}>
                  <th scope="row" width="10%">{ind}</th>
                  <td width="40%"> {Case.formStatus[formId].name} </td>
                  <td width="25%"> {Case.formStatus[formId].status  == 'completed' ? 'abgeschlossen' : Case.formStatus[formId].status } </td>
                  <td width="25%"> 
                    <button
                      className='px-4'
                      disabled={ Case.formStatus[formId].status == 'completed' }
                      onClick= {() => {
                        history.push(`/holidaysherpa/form/${formId}/${Case.caseId}/${firebase.auth().currentUser.uid}`);
                      }}
                    >
                      Hier Klicken
                    </button>
                  </td>
                </tr>
              ) : <></>;
            })
          }
        </tbody>
      </Table>
    );
  };

  return (
    <Container className='mt-4'>
      {
        Object.values(caseList).map((Case) => {
          return (
            <div className='mt-4'>
              <h3 className='text-center'><b> {Case.caseId} </b></h3>
              { renderCaseFormStatus(Case) }
            </div>
          );
        })
      }
    </Container>
  );
};

export default HolidaySherpaCases;
