import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import ReactLoading from "react-loading";

import { CityElement } from "./CityElement";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { fetchData } from "../../Utils/Helper";

import React, { useState, useContext, useEffect, useRef } from "react";

import "./element.css";
import { observer } from "mobx-react-lite";
import { ClientContext } from "../../stores/ClientStore";
import { toJS } from "mobx";

import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaTemperatureHigh,
  FaInfoCircle,
} from "react-icons/fa";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { maintainOrderFlows } from "../../Utils/constants";
import { CacheContext } from "../../stores/CacheStore";
import { ResolveTable } from "./ResolveElementUtils/ResolveTable";
import { ResolveRadio } from "./ResolveElementUtils/ResolveRadio";
import { ResolveText } from "./ResolveElementUtils/ResolveText";
import { ResolveDateTime } from "./ResolveElementUtils/ResolveDateTime";
import { DisclaimerElement } from "./DisclaimerElement";
import MultiSelect from "../Common/MultiSelect";
import firebase from "../../Utils/firebase";

const ResolveElement = observer(
  (props, ref) => {
    const clientStore = useContext(ClientContext);
    const cacheStore = useContext(CacheContext);
    const fileRef = useRef(null);

    const getStore = () => (props.adminStore ? props.adminStore : clientStore);

    const [question, setQuestion] = useState({});

    const tableNameIfTable = props.tableNameIfTable || ""

    let [showOffcanvas, setShowOffcanvas] = useState(false);

    const [table, setTable] = useState({});

    const putStar = (question)=>{
      return question.validation ? question.validation.required ? question.text ? <span className="red-error">* </span> : <></> : <></> : <></>;
    }

    let { transcript, listening, resetTranscript, isMicrophoneAvailable } =
      useSpeechRecognition();

    const getAllResponse = ()=>{
      let temp = {};
      for( let key in getStore().responses){
        for( let question in getStore().responses[key]){
          temp[question] = getStore().responses[key][question];
        }
      }
      return temp;
    }
    
    const isCheckboxChecked = (optionText) => {
      if(props.adminStore){
        return getStore().responses[question.id] ? getStore().responses[question.id][optionText] ? getStore().responses[question.id][optionText] : false : false;
      }
    
      for( let key in getStore().responses){
        if (getStore().responses[key][question.id]){
          return getStore().responses[key][question.id][optionText];
        }
      }
      return false;
    }

    const selectedOptions = (options)=>{
      let temp = getStore().currentResponses()[question.id];
      if(!temp)  return [];

      let selectedOpts = [];
      if(typeof temp == "object"){
        Object.keys(temp).map((selectedOpt) => {
          if(temp[selectedOpt]){
            options.forEach((opt) => {
              if(opt.text == selectedOpt){
                selectedOpts.push(opt);
              }
            });
          }
        });
      } else {
        options.forEach((opt) => {
          if(opt.text == temp){
            selectedOpts.push(opt);
          }
        });
      }
      return selectedOpts;
    }

    const DeleteFile = async()=>{
      var temp = getStore().currentResponses();
      const fileName = temp[question.id];
      temp[question.id] = null;
      getStore().responses[getStore().activeTab[1].formName] = {
        ...temp,
      };
      const del = await fetch('/api/questionaire/deletefile', {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: firebase.auth().currentUser.uid,
          fileName: fileName,
          responses: getStore().responses
        })
      });
      const result = await del.json();
    }

    const renderOptionsForQues = (options, name, element) => {
      //

      if (!options) return [];

      let gesamteIndex = options?.findIndex((op) =>
        op.value?.toLowerCase().startsWith("gesamte")
      );

      let gesamteRemoved;
      if (gesamteIndex != -1) {
        [gesamteRemoved] = options?.splice(gesamteIndex, 1);
      }

      let andereIndex = options?.findIndex((op) =>
        op.value?.toLowerCase().startsWith("andere")
      );

      let andereRemoved;
      if (andereIndex != -1) {
        [andereRemoved] = options?.splice(andereIndex, 1);
      }

      let sonstigeIndex = options?.findIndex((op) =>
        op.value?.toLowerCase().startsWith("sonstige")
      );

      let sonstigeRemoved;
      if (sonstigeIndex != -1) {
        [sonstigeRemoved] = options?.splice(sonstigeIndex, 1);
        //
      }

      if (maintainOrderFlows.findIndex((e) => e == name) == -1) {
        options = options
          ?.slice()
          .sort((a, b) =>
            a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1
          );
      } else {
      }
      if (andereIndex != -1) {
        //
        options.push(andereRemoved);
      }
      if (sonstigeIndex != -1) {
        //
        options.push(sonstigeRemoved);
      }

      if (gesamteIndex != -1) {
        options.splice(0, 0, gesamteRemoved);
      }

      element.optionsSorted = true;
      element.gesamtes = gesamteRemoved;
      element.options = options;
      return options;
    };

    const deleteAllKeysTable = (elements) => {
      if (!elements) return;

      for (var i = 0; i < elements.length; i++) {
        delete getStore().currentResponses()[elements[i].name];
        if (
          elements[i].type == "radio" ||
          elements[i].type == "checkbox" ||
          elements[i].type == "dropdown" ||
          elements[i].type == "inlineradio"
        ) {
          for (var j = 0; j < elements[i].options?.length; j++) {
            if (elements[i].options[j].triggers) {
              deleteAllKeysTable(elements[i].options[j].triggers[0].questions);
            }
          }
        } else if (elements[i].type == "parent") {
          deleteAllKeysTable(elements[i].children);
        }
      }
    };

    //Looks like legacy code (have to be refactored?)
    const validate = (question) => {
      getStore().errorMessages[question.id] = "";
      console.log(toJS(getStore().currentResponses()[question.id]));

      if (!question.validation) {
        question.validation = {
          required: true,
          type: "alphanumeric",
          regex: "",
        };
      }

      if (question.type == "parent") {
        const arr = question.children.map((x) => {
          return validate(x);
        });

        const msg = arr.every((e) => e);

        if (!msg) {
          return false;
        }

        return true;
      }

      if (
        getStore().currentResponses()[question.id] == null &&
        question.type != "parent" &&
        question.validation.regex != "skip" &&
        (question.validation.required || question.validation.regex)
      ) {
        getStore().errorMessages[question.id] =
          "Dieses Feld ist ein Pflichtfeld";
        return false;
      }

      if (
        question.type == "checkbox" &&
        question.validation.regex != "skip" &&
        (question.validation.required || question.validation.regex)
      ) {
        console.log(toJS(getStore().currentResponses()[question.id]));
        if (getStore().currentResponses()[question.id]) {
          let valid = false;
          Object.entries(getStore().currentResponses()[question.id]).forEach(
            (op) => {
              if (op[1] == true) {
                valid = true;
              }
            }
          );
          if (!valid) {
            getStore().errorMessages[question.id] =
              "Dieses Feld ist ein Pflichtfeld";
            return false;
          }
        } else {
          getStore().errorMessages[question.id] =
            "Dieses Feld ist ein Pflichtfeld";
          return false;
        }
      }

      if (
        question.type == "text" &&
        (question.validation.required || question.validation.regex)
      ) {
        if (question.validation.type == "numeric") {
          const isNumeric = (str) => {
            if (typeof str != "string") return false;
            return !isNaN(str) && !isNaN(parseFloat(str));
          };

          var processed = clientStore
            .allResponses()
          [question.id].split(".")
            .join("");

          processed = processed.split(",").join(".");

          if (!isNumeric(processed)) {
            getStore().errorMessages[question.id] =
              "Eintrag im Textfeld muss nummerisch sein";
            return false;
          }
        }
        if (question.validation.type == "alphanumeric") {
        }
        if (question.validation.type == "email") {
          const validateEmail = (email) => {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          };

          if (!validateEmail(getStore().currentResponses()[question.id])) {
            getStore().errorMessages[question.id] = "Ungültige E-Mail-Adresse!";
            return false;
          }
        }
      }

      if (
        question.type == "date" &&
        question.validation &&
        (question.validation.required || question.validation.regex)
      ) {
        if (!question.validation.regex) return true;

        const b = question.validation.regex.split(" ");
        const c = question.validation.regex.split("/");

        const a = c.length > 1 ? c : b;

        for (var x = 0; x < a.length; x++) {
          a[x] = a[x].trim();
        }

        const convert = (str) => {
          if (!str) return "1970-01-20";
          return str.split(".").reverse().join("-");
        };

        let iter = parseInt(`${a.length / 2}`);
        for (let k = 0; k < iter; k++) {
          if (a[k * 2] == "greater") {
            let greaterArray = a[k * 2 + 1];
            if (a[k * 2 + 1][0] == "[") {
              greaterArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
            }

            let greaterArrayData = greaterArray.split(",");
            var d1 = Date.parse(
              convert(getStore().currentResponses()[question.id])
            );
            for (let i = 0; i < greaterArrayData.length; i++) {
              var temp = greaterArrayData[i].split(":");
              if (getStore().currentResponses()[temp[0]?.trim()]) {
                var d2 = Date.parse(
                  convert(getStore().currentResponses()[temp[0]?.trim()])
                );
                if (d1 >= d2) {
                  continue;
                } else {
                  getStore().errorMessages[question.id] =
                    temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";

                  return false;
                }
              }
            }
          }

          if (a[k * 2 + 0] == "lesser") {
            let lesserArray = a[k * 2 + 1];
            if (a[k * 2 + 1][0] == "[") {
              lesserArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
            }

            let lesserArrayData = lesserArray.split(",");
            var d1 = Date.parse(
              convert(getStore().currentResponses()[question.id])
            );
            for (let i = 0; i < lesserArrayData.length; i++) {
              var temp = lesserArrayData[i].split(":");
              if (getStore().currentResponses()[temp[0]?.trim()]) {
                var d2 = Date.parse(
                  convert(getStore().currentResponses()[temp[0]?.trim()])
                );
                if (d1 <= d2) {
                  continue;
                } else {
                  getStore().errorMessages[question.id] =
                    temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";

                  return false;
                }
              }
            }
          }
        }

        if (a[0] == "between") {
          var d1 = Date.parse(
            convert(getStore().currentResponses()[question.id])
          );
          var d2 = Date.parse(convert(getStore().currentResponses()[a[1]]));
          var d3 = Date.parse(convert(getStore().currentResponses()[a[2]]));

          if (d1 >= d2 && d1 <= d3) {
            getStore().errorMessages[question.id] = "";
            return true;
          } else {
            getStore().errorMessages[question.id] = "Ungültiges Datum";

            return false;
          }
        }

        getStore().errorMessages[question.id] = "";
        return true;
      }

      if (
        question.type == "time" &&
        question.validation &&
        (question.validation.required || question.validation.regex)
      ) {
        const b = question.validation.regex.split(" ");
        const c = question.validation.regex.split("/");

        const a = c.length > 1 ? c : b;

        for (var x = 0; x < a.length; x++) {
          a[x] = a[x].trim();
        }

        const convert = (str) => {
          if (!str) return "1970-01-20";
          return str.split(".").reverse().join("-");
        };

        let iter = parseInt(`${a.length / 2}`);
        for (let k = 0; k < iter; k++) {
          if (a[k * 2] == "greater") {
            let greaterArray = a[k * 2 + 1];
            if (a[k * 2 + 1][0] == "[") {
              greaterArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
            }

            let greaterArrayData = greaterArray.split(",");

            var d1 = Date.parse(
              `01 Dec 1984 ${getStore().currentResponses()[question.id]} GMT`
              //convert(getStore().currentResponses()[question.id])
            );

            for (let i = 0; i < greaterArrayData.length; i++) {
              var temp = greaterArrayData[i].split(":");
              if (getStore().currentResponses()[temp[0]?.trim()]) {
                var d2 = Date.parse(
                  //convert(getStore().currentResponses()[temp[0]?.trim()])
                  `01 Dec 1984 ${getStore().currentResponses()[temp[0]?.trim()]
                  } GMT`
                );
                if (d1 >= d2) {
                  continue;
                } else {
                  getStore().errorMessages[question.id] =
                    temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";

                  return false;
                }
              }
            }
          }

          if (a[k * 2 + 0] == "lesser") {
            let lesserArray = a[k * 2 + 1];
            if (a[k * 2 + 1][0] == "[") {
              lesserArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
            }

            let lesserArrayData = lesserArray.split(",");
            var d1 = Date.parse(
              `01 Dec 1984 ${getStore().currentResponses()[question.id]} GMT`
              //convert(getStore().currentResponses()[question.id])
            );
            for (let i = 0; i < lesserArrayData.length; i++) {
              var temp = lesserArrayData[i].split(":");
              if (getStore().currentResponses()[temp[0]?.trim()]) {
                var d2 = Date.parse(
                  //convert(getStore().currentResponses()[temp[0]?.trim()])
                  `01 Dec 1984 ${getStore().currentResponses()[temp[0]?.trim()]
                  } GMT`
                );
                if (d1 <= d2) {
                  continue;
                } else {
                  getStore().errorMessages[question.id] =
                    temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";

                  return false;
                }
              }
            }
          }
        }

        getStore().errorMessages[question.id] = "";
        return true;
      }

      if (question.type == "radio") {
        const arr = question.options.map((x) => {
          if (
            x.triggers &&
            x.text === getStore().currentResponses()[question.id]
          ) {
            return x.triggers
              ?.map((t) => {
                if (t.type == "endFlow") return true;

                var a = t.questions
                  ?.map((y) => {
                    return validate(y);
                  })
                  .every((e) => e);

                return a;
              })
              .every((e) => e);
          }
          return true;
        });

        const msg = arr.every((e) => e);

        if (!msg) {
          getStore().errorMessages[question.id] =
            "Dieses Feld is ein Pflichtfeld";
          return false;
        }
      }

      if (question.type == "dropdown" || question.type == "checkbox") {
        const array = question.options?.map((x) => {
          if (
            x.triggers &&
            (x.text === getStore().currentResponses()[question.id] ||
              x.title === getStore().currentResponses()[question.id] ||
              (getStore().currentResponses()[question.id] &&
                getStore().currentResponses()[question.id][x.name]))
          ) {
            return x.triggers
              .map((t) => {
                var a = t.questions
                  .map((y) => {
                    return validate(y);
                  })
                  .every((e) => e);

                return a;
              })
              .every((e) => e);
          }
          return true;
        });

        if (!array) return true;

        const submsg = array.every((e) => e);

        if (!submsg) {
          return false;
        }
      }

      // setError(null);

      getStore().errorMessages[question.id] = "";

      return true;
    };

    const filteredListForSelectedTables = [
      { tableName: "tableDeficiencyPeriods", value: "rtA6qMzYcBPES6Gpm9F3cx" },
      { tableName: "tableDeficiencyPeriodsCruise", value: "hb37ykLygtF82YguGsSGXB" },
      { tableName: "tableDeficiencyPeriodsStorno",value:"qfRmVFmzFEzacDBkGeqrWx"}
    ]

    //This function is also legacy code (have to be refactored)
    const checkForFlowModifications = (question, type, option) => {
      //Append Flow

      return;

      const endFlowMessage = question.options.filter((x) => x.name == option)[0]
        ?.endFlow;

      if (endFlowMessage) {
        getStore().endMessage = endFlowMessage;
        return;
      } else {
        getStore().endMessage = null;
      }

      if (question.appendFlow) {
        const appendFlowId =
          question.appendFlow[
          type ? option : getStore().currentResponses()[question.id]
          ];

        question.options.map((opt) => {
          if (!type && opt.text != option) {
            getStore().appendList = getStore().appendList.filter(
              (x) => x != question.appendFlow[opt.text]
            );
          }
        });

        if (
          !(type
            ? getStore().currentResponses()[question.id]
            : getStore().currentResponses()[question.id][option])
        ) {
          getStore().appendList = getStore().appendList.filter(
            (x) => x != appendFlowId
          );
        } else {
          getStore().appendList.push(appendFlowId);
        }
      }

      //Modify Flow

      if (question.modifyFlow) {
        const modifyFlowId =
          question.modifyFlow[
          type ? option : getStore().currentResponses()[question.id]
          ];

        question.options.map((opt) => {
          if (!type && opt.text != option) {
            getStore().modifyList = getStore().modifyList.filter(
              (x) => x != question.modifyFlow[opt.text]
            );
          }
        });

        if (
          !(type
            ? getStore().currentResponses()[question.id][option]
            : getStore().currentResponses()[question.id] == option)
        ) {
          getStore().modifyList = getStore().modifyList.filter(
            (x) => x != modifyFlowId
          );
        } else {
          getStore().modifyList.push(modifyFlowId);
        }
      }

      //Add a Flow

      if (question.addFlow) {
        if (question.type != "checkbox") {
          question.options.map((opt) => {
            if (!type && opt.text != option) {
              getStore().addList = getStore().addList.filter(
                (x) => x != question.addFlow[opt.text]
              );
            }
          });
        }

        if (
          question.type == "checkbox" &&
          !getStore().currentResponses()[question.id][option]
        ) {
          getStore().removeList.push(
            question.addFlow[
            type ? option : getStore().currentResponses()[question.id]
            ]
          );

          getStore().addList = getStore().addList.filter(
            (x) =>
              x !=
              question.addFlow[
              type ? option : getStore().currentResponses()[question.id]
              ]
          );
        } else {
          const addFlowId =
            question.addFlow[
            type ? option : getStore().currentResponses()[question.id]
            ];

          getStore().addList.push(addFlowId);
        }
      }
    };

    const registerResponse = (event) => {
      var temp = getStore().currentResponses();

      temp[question.id] = event.target.value;

      getStore().responses[getStore().activeTab[1].formName] = {
        ...temp,
      };
    };

    const subQuestionElements = () =>
      question.options?.map((x) => {
        if (
          x.subquestions &&
          x.text === getStore().currentResponses()[question.id]
        ) {
          return x.subquestions.map((y) => {
            return (
              <div key={y.name}>
                <ResolveElement
                  parentType={"question"}
                  questionId={y}
                  disabled={props.disabled}
                  ref={ref}
                  shouldNotScroll={props.shouldNotScroll}
                  adminStore={props.adminStore}
                />
              </div>
            );
          });
        }
        return null;
      });

    const [loading, setLoading] = useState(!props.rawElement);
    useEffect(() => {
      const fetchThings = async () => {
        if (!props.questionId) return;

        getStore().blockWeiter += 1;

        var [data, err] = await fetchData(
          `/flowapi/question/${props.questionId}`
        );

        if (err) return;

        cacheStore.updateQuestionCached(props.questionId, data);

        if (data.type == "table" && data.tableId) {
          const tableRes = await fetch(`/api/table/${data.tableId}`);
          const tableJson = await tableRes.json();

          data.headings = [...tableJson.data.headings];
        }

        if (props.addSuffix) {
          data.name += `-${props.suffix}`;
        }

        setQuestion(data);

        if (
          (!props.adminStore && props.parentType == "question") ||
          props.questionId == getStore().currentQuestion
        ) {
          if (!getStore().validateQuestions.includes(props.questionId))
            getStore().validateQuestions.push(props.questionId);
        }

        getStore().blockWeiter -= 1;
        setLoading(false);
      };

      if (!props.rawElement) {
        fetchThings();
      } else {
        var temp = { ...props.rawElement, id: props.rawElement.name };
        temp.text = temp.text ?? temp.title;
        setQuestion({ ...temp });
      }
    }, []);

    useEffect(() => {
      const populateTextArea = () => {
        if (props.microPhoneQues == true || question.type == "textarea") {
          var temp = getStore().currentResponses();
          temp[question.id] = transcript;

          getStore().responses[getStore().activeTab[1].formName] = {
            ...temp,
          };
        }
      };
      if (!props.adminStore) populateTextArea();
    }, [transcript]);

    useEffect(() => {
      const fetchStuff = async () => {
        if (question.type == "table") {
          if (question.tableId) {
            const resp = await fetch(`/api/table/${question.tableId}`);
            const data = await resp.json();
            setTable(data.data);
          } else {
            setTable(question);
          }
        }
      };
      fetchStuff();
    }, []);

    const [showDisclaimer, setDisclaimer] = useState(false);

    //this part is added to revert back to the old disclaimer functionality since new one is broken.
    const DisclaimerElement = () => {
      if (!showDisclaimer) return <></>;
      return question.disclaimer && <div className="question_hint">{question.disclaimer}</div>;
    };

    const Header = () => (
      <div>
        <div className="questionText" id={`${question.id}-${question.name}`}>
          {putStar(question)}
          {question.text}{" "}
          {question.disclaimer && (
            <FaInfoCircle
            color="black"
            onClick={() => setDisclaimer(!showDisclaimer)} />
          )}
        </div>
        <div className="subtext">{question.subtext}</div>

        <div>
          <DisclaimerElement />
        </div>

        {/* {question.disclaimer && (
          <div>
            <DisclaimerElement
              setShowCanvas={setDisclaimer}
              showCanvas={showDisclaimer}
              disclaimerText={question.disclaimer}
            />
          </div>
        )} */}
      </div>
    );

    if (loading) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "4px",
          }}
        >
          {" "}
          <ReactLoading type="spokes" color="black" />
        </div>
      );
    }

    if (question.type === "parent") {
      return (
        <div id={question.name}>
          <Header />
          {question.children.map((x, i) => {
            return (
              <div>
                <ResolveElement
                  subQuestion={true}
                  questionId={x}
                  parentType={"question"}
                  key={x}
                  disabled={props.disabled}
                  shouldNotScroll={true}
                  ref={ref}
                  adminStore={props.adminStore}
                />
              </div>
            );
          })}
        </div>
      );
    }

    if (question.type == "text") {
      return (
        <>
          <Header />
          <ResolveText
            question={question}
            disabled={false}
            adminStore={props.adminStore}
            {...props}
          />
        </>
      );
    }

    if (question.type === "dropdown") {
      if (question.name == "city") {
        return (
          <div id={question.name} className="mt-2">
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
              {question.disclaimer && (
                <FaInfoCircle
                  color="black"
                  onClick={() => setDisclaimer(!showDisclaimer)}
                />
              )}
              <DisclaimerElement />
              <CityElement
                element={question}
                type="city"
                options={
                  getStore().cities != undefined
                    ? getStore().cities.map((x) => {
                      return { value: x, label: x };
                    })
                    : []
                }
                disabled={props.disabled}
                adminStore={props.adminStore}
              />
              <div className="red-error">
                {getStore().errorMessages[question.id] ||
                  getStore().errorMessages[question.name]}
              </div>
            </Form.Group>
          </div>
        );
      }
      if (question.name == "country") {
        return (
          <div id={question.name} className="mt-2">
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
              {question.disclaimer && (
                <FaInfoCircle
                  color="black"
                  onClick={(e) => setDisclaimer(!showDisclaimer)}
                />
              )}
              <DisclaimerElement />
              <CityElement
                element={question}
                type="country"
                options={
                  getStore().countries != undefined
                    ? getStore().countries.map((x) => {
                      return { value: x, label: x };
                    })
                    : []
                }
                disabled={props.disabled}
                adminStore={props.adminStore}
              />
              <div className="red-error">
                {getStore().errorMessages[question.id] ||
                  getStore().errorMessages[question.name]}
              </div>
            </Form.Group>
          </div>
        );
      }
      return (
        <div className="mt-2">
          <div id={question.name}>
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
              {question.disclaimer && (
                <FaInfoCircle
                  color="black"
                  onClick={(e) => setDisclaimer(!showDisclaimer)}
                />
              )}
              <DisclaimerElement />
              <Form.Select
                value={
                  getStore().currentResponses()[question.id]
                    ? getStore().currentResponses()[question.id]
                    : "select"
                }
                disabled={props.disabled}
                onChange={(e) => {
                  getStore().endCurrentFlow = false;
                  if (e.target.value && e.target.value != "select") {
                    registerResponse(e);

                    question.options.map((option) => {
                      if (
                        getStore().currentResponses()[question.id] !=
                        option.text
                      ) {
                        if (option.subquestions) {
                          //Remove question from validation list
                          getStore().validateQuestions =
                            getStore().validateQuestions.filter(
                              (y) => !option.subquestions.includes(y)
                            );

                          let temp = getStore().currentResponses();

                          //Remove response for subquestions that are not visible
                          option.subquestions.map((subqid) => {
                            temp[subqid] = null;
                          });

                          getStore().responses[
                            getStore().activeTab[1].formName
                          ] = {
                            ...temp,
                          };
                        }
                      }
                    });

                    if (
                      getStore().questionList[getStore().activeQuestion].type ==
                      "dropdown" &&
                      getStore().questionList[getStore().activeQuestion].name ==
                      question.name
                    ) {
                      let autoScroll = false;
                      question.options.map((op) => {
                        if (e.target.value == op.value) {
                          if (
                            op.triggers &&
                            (op.triggers[0]?.type != "addQuestions" ||
                              op.triggers[0]?.type != "addQuestion")
                          ) {
                            autoScroll = false;
                          } else {
                            autoScroll = true;
                          }
                        }
                      });
                      if (autoScroll) {
                        document
                          .getElementById(
                            `${getStore().activeQuestion}-next-button`
                          )
                          .click();
                      } else {
                        var element = document.getElementById(
                          `${question.name}`
                        );

                        if (!props.shouldNotScroll) {
                          setTimeout(() => {
                            window.scrollBy(0, element?.offsetHeight);
                          }, 300);
                        }
                      }
                    } else {
                      var element = document.getElementById(`${question.name}`);

                      if (!props.shouldNotScroll) {
                        setTimeout(() => {
                          window.scrollBy(0, element?.offsetHeight);
                        }, 300);
                      }
                    }
                  }
                }}
              >
                <option value="select" key={`${question.name}-select`}>
                  Wählen ...
                </option>
                {(question.optionsSorted == true
                  ? question.options
                  : renderOptionsForQues(
                    question.options,
                    question.name,
                    question
                  )
                ).map((x) => {
                  if (x.hide) return;
                  return (
                    <option
                      disabled={x.disabled ? true : false}
                      key={x.text}
                      value={x.text}
                    >
                      {x.text}
                    </option>
                  );
                })}
              </Form.Select>
              <div className="red-error">
                {getStore().errorMessages[question.id] ||
                  getStore().errorMessages[question.name]}
              </div>
            </Form.Group>
          </div>
          <div>{subQuestionElements()}</div>
        </div>
      );
      //}
    }
    //uc_heading
    if (question.type === "heading") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
            {
              question.disclaimer && (
              <FaInfoCircle
                color="black"
                onClick={(e) => setDisclaimer(!showDisclaimer)}
              />
            )
            }
            <DisclaimerElement />
            <div className="red-error">
              {getStore().errorMessages[question.id] ||
                getStore().errorMessages[question.name]}
            </div>
          </Form.Group>
          </div>
      );
    }

    if (question.type === "textarea") {
      //temporary fix for disclaimer, Uncomment later.
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
            {
              question.disclaimer /*&& (
              <FaInfoCircle
                color="black"
                onClick={(e) => setDisclaimer(!showDisclaimer)}
              />
            )*/
            }
            <DisclaimerElement />
            <div className="red-error">
              {getStore().errorMessages[question.id] ||
                getStore().errorMessages[question.name]}
            </div>
            <Form.Control
              as="textarea"
              disabled={props.disabled}
              placeholder={question.placeholder}
              value={getStore().currentResponses()[question.id]}
              onChange={(event) => {
                if (props.preview) return;
                var temp = getStore().currentResponses();
                temp[question.id] = event.target.value;

                getStore().responses[getStore().activeTab[1].formName] = {
                  ...temp,
                };
              }}
            />
          </Form.Group>
          {props.microPhoneQues || question.microPhoneQues ? (
            SpeechRecognition.browserSupportsSpeechRecognition() ? (
              <div className="microPhoneButtons">
                <Button
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  onClick={async () => {
                    if (isMicrophoneAvailable) {
                      if (listening) {
                        SpeechRecognition.stopListening();
                      } else {
                        SpeechRecognition.startListening({
                          language: "de-DE",
                          continuous: true,
                        });
                      }
                    }
                  }}
                  variant="outline-success"
                  disableElevation
                  className="text-uppercase"
                >
                  {listening ? <FaMicrophone /> : <FaMicrophoneSlash />}{" "}
                  {listening ? " \thalt" : " \tbeginnen"}
                </Button>
                <Button
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  onClick={resetTranscript}
                  variant="outline-danger"
                  className="text-uppercase"
                  disableElevation
                >
                  zurücksetzen
                </Button>
              </div>
            ) : (
              <p style={{ color: "red" }}>
                Ihr Browser unterstützt derzeit keine Tonaufnahme.
              </p>
            )
          ) : (
            <></>
          )}
          {props.microPhoneQues ? (
            SpeechRecognition.browserSupportsSpeechRecognition() ? (
              isMicrophoneAvailable ? (
                <></>
              ) : (
                <p style={{ color: "red" }}>Allow access to microphone</p>
              )
            ) : (
              "Transcribe not supported in your browser"
            )
          ) : (
            <></>
          )}
        </div>
      );
    }

    if (["inlineradio", "radio"].includes(question.type)) {
      return (
        <>
          <Header />
          <div className="red-error">
            {getStore().errorMessages[question.id]}
          </div>
          <ResolveRadio
            question={question}
            adminStore={props.adminStore}
            {...props}
          />
        </>
      );
    }

    if (["date", "time", "datetime"].includes(question.type)) {
      return (
        <>
          <Header />
          <div style={{ color: "red" }}>
            {getStore().errorMessages[question.id]}
          </div>
          <ResolveDateTime question={question} adminStore={props.adminStore} {...props}/>
        </>
      );
    }

    if (question.type === "file") {
      if (props.adminStore) {
        return <div id={question.name}>
          <input
            disabled
            value= { getStore().responses[question.id] }
            type="text"
            style={{ width: "85%" }}
          />
        </div>
      }
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{putStar(question)}{question.text}</Form.Label>
            {question.disclaimer && (
              <FaInfoCircle
                color="black"
                onClick={(e) => setDisclaimer(!showDisclaimer)}
              />
            )}
            <DisclaimerElement />
            <div className="red-error">
              {getStore().errorMessages[question.id] ||
                getStore().errorMessages[question.name]}
            </div>
            <Form.Control
              type="file"
              disabled={props.disabled}
              id={`${question.id}-file`}
              onChange={(event) => {
                // if (props.preview) return;
                //
                var temp = getStore().currentResponses();
                temp[question.id] = event.currentTarget.files[0].name;
                getStore().uploadedFiles[question.name] = event.currentTarget.files[0];
                getStore().responses[getStore().activeTab[1].formName] = {
                  ...temp,
                };

                var element = document.getElementById(`${question.name}`);
                //
                if (!props.shouldNotScroll) {
                  setTimeout(() => {
                    window.scrollBy(0, element?.offsetHeight);
                  }, 500);
                }
              }}
            />
            
            {
              document.getElementById(`${question.id}-file`) && (document.getElementById(`${question.id}-file`).value == '') && getStore().currentResponses()[question.id] ? (
                <div className="d-flex justify-content-around">
                  <strong className="my-auto">Hochgeladene Dateien </strong>
                  <p className="my-auto">{ getStore().currentResponses()[question.id]} </p>
                  <button type="button" onClick={() => DeleteFile()} disabled={props.disabled}>
                    löschen
                  </button>
                </div>
              ) : <></>
            }
          </Form.Group>
        </div>
      );
    }

    const questionArray = []
    const hasSubQuestions = (questionsArray) => {
      for (let i = 0; i < questionsArray.length; i++) {
        if (questionsArray[i].subquestions && questionsArray[i].subquestions.length !== 0) {
          return true
        }
      }
      return false;
    }

    if (question.type === "checkbox") {
      const selectedOnesInMainResp = []
      const objKeys = []
      filteredListForSelectedTables.forEach((elem) => {
        objKeys.push(elem.tableName)
      })
      if (tableNameIfTable && objKeys.includes(tableNameIfTable)) {
        let tableSourceVal;
        filteredListForSelectedTables.map((item) => {
          if (item.tableName === tableNameIfTable) {
            tableSourceVal = item.value
          }
        })
        let totalResponse = getAllResponse();
        const mainResp = toJS(totalResponse[tableSourceVal])
        if (mainResp) {
          for (const [key, value] of Object.entries(mainResp)) {
            if (value) {
              selectedOnesInMainResp.push(key)
            }
          }
        }

        question?.options?.forEach((optn) => {
          if (!selectedOnesInMainResp.includes(optn.text)) {
            optn.hide = true
          } else {
            optn.hide = false
          }
        })

        if (question?.options) {
          for (let i = 0; i < selectedOnesInMainResp.length; i++) {
            let checkedCount = 0;
            for (let j = 0; j < question.options.length; j++) {
              if (selectedOnesInMainResp[i] === question.options[j].text) {
                question.options[j].hide = false
                break;
              } else {
                checkedCount += 1
              }
            }
            if (checkedCount == question.options.length) {
              const newOpt = {};
              newOpt.name = selectedOnesInMainResp[i].split(' ').join('');
              newOpt.value = selectedOnesInMainResp[i].split(' ').join('');
              newOpt.text = selectedOnesInMainResp[i]
              newOpt.title = selectedOnesInMainResp[i]
              newOpt.hide = false

              question.options.push(newOpt)
            }
          }
        }
      }

      return (
        <div className="mt-2" id={question.name}>
          <div className="questionText">
            {putStar(question)}
            {question.text + " "}  
            {question.disclaimer && (
              <FaInfoCircle
                color="black"
                onClick={(e) => setDisclaimer(!showDisclaimer)}
              />
            )}
          </div>
          <DisclaimerElement />
          <div className="red-error">
            {getStore().errorMessages[question.id] ||
              getStore().errorMessages[question.name]}
          </div>

          {
            (question.optionsSorted == true
              ? question.options
              : renderOptionsForQues(question.options, question.name, question)
            ).map((x, index) => {
              if (x.hide) return;
              return (
                <div key={`${question.name}-${index}`}>
                  <label>
                    <input
                      type="checkbox"
                      name={`${question.name}-${index}`}
                      checked={(() => {
                        return isCheckboxChecked(x.text);
                      })()}
                      color="#3249B4"
                      id={`${question.name}-${index}`}
                      disabled={
                        (x.disabled || props.disabled ? true : false) ||
                        (!x.value.toLowerCase().startsWith("gesamte") &&
                          (getStore().currentResponses()[question.id]
                            ? getStore().currentResponses()[question.id][
                            question.gesamtes?.value
                            ]
                            : false))
                      }
                      onChange={async (event) => {
                        var temp = getStore().currentResponses()[question.id];

                        if (!temp) {
                          getStore().currentResponses()[question.id] = {};
                          temp = getStore().currentResponses()[question.id];
                        }

                        if (
                          x.value.toLowerCase().startsWith("gesamte") &&
                          !temp[x.text]
                        ) {
                          Object.keys(temp).map((x) => {
                            temp[x] = false;
                          });
                        }

                        temp[x.text] = !temp[x.text];

                        checkForFlowModifications(question, 1, x.text);

                        if (!temp[x.text]) {
                          //Remove subquestions from the validation list.
                          if (x.subquestions) {
                            getStore().validateQuestions =
                              getStore().validateQuestions.filter(
                                (y) => !x.subquestions.includes(y)
                              );

                            let t = getStore().currentResponses();

                            x.subquestions.map((subqid) => {
                              t[subqid] = null;
                            });

                            getStore().responses[
                              getStore().activeTab[1].formName
                            ] = {
                              ...t,
                            };
                          }
                        }

                        getStore().responses[getStore().activeTab[1].formName] = {
                          ...getStore().currentResponses(),
                          [question.id]: { ...temp },
                        };

                        if (temp[x.value] && !props.shouldNotScroll) {
                          setTimeout(() => {
                            window.scrollBy(0, 100);
                          }, 200);
                        }
                      }}
                    />
                    {x.text}
                  </label>

                  <div className="sub-question">
                    {x.subquestions &&
                      getStore().currentResponses()[question.id] &&
                      getStore().currentResponses()[question.id][x.text] ? (
                      <div>
                        {x.subquestions?.map((y) => {
                          return (
                            <div key={y}>
                              <ResolveElement
                                parentType={"question"}
                                questionId={y}
                                disabled={props.disabled}
                                ref={ref}
                                shouldNotScroll={props.shouldNotScroll}
                                adminStore={props.adminStore}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          }
        </div >
      );
    }


    if (question.type === "singleselect") {
      (question.optionsSorted == true
        ? question.options
        : renderOptionsForQues(question.options, question.name, question)
      ).forEach((qstn) => {
        if (!qstn.hide) {
          let newObj = qstn;
          newObj.label = qstn.text;
          questionArray.push(newObj)
        }
      })

      return (
        <div className="mt-2" id={question.name}>
          <div className="questionText">{putStar(question)}{question.text}</div>
          {question.disclaimer && (
            <FaInfoCircle
              color="black"
              onClick={(e) => setDisclaimer(!showDisclaimer)}
            />
          )}
          <DisclaimerElement />
          <div className="red-error">
            {getStore().errorMessages[question.id] ||
              getStore().errorMessages[question.name]}
          </div>


          < MultiSelect options={questionArray} isMultiSelectEnabled={false}
            defaultValue={()=> selectedOptions(questionArray)}
            onChange={async (x) => {
              const respValue = x;
              getStore().currentResponses()[question.id] = {}
              if (!x) {
                //called when no value/delete in current response clicked
                return;
              }
              getStore().currentResponses()[question.id] = respValue.text;


              // var temp = getStore().currentResponses()[question.id];

              // if (!temp) {
              //   getStore().currentResponses()[question.id] = {};
              //   temp = getStore().currentResponses()[question.id];
              // }

              // if (
              //   x.value.toLowerCase().startsWith("gesamte") &&
              //   !temp[x.text]
              // ) {
              //   Object.keys(temp).map((x) => {
              //     temp[x] = false;
              //   });
              // }

              //temp[x.text] = true

              // const allResp = toJS(getStore().currentResponses()[question.id])
              // Object.keys(allResp).map((singleResp) => {
              //   if (respValue !== singleResp) {
              //     getStore().currentResponses()[question.id][singleResp] = true
              //   }
              // })

              //checkForFlowModifications(question, 1, x.text);

              // if (!temp[x.text]) {
              //   //Remove subquestions from the validation list.
              //   if (x.subquestions) {
              //     getStore().validateQuestions =
              //       getStore().validateQuestions.filter(
              //         (y) => !x.subquestions.includes(y)
              //       );

              //     let t = getStore().currentResponses();

              //     x.subquestions.map((subqid) => {
              //       t[subqid] = null;
              //     });

              //     getStore().responses[
              //       getStore().activeTab[1].formName
              //     ] = {
              //       ...t,
              //     };
              //   }
              // }

              // getStore().responses[getStore().activeTab[1].formName] = {
              //   ...getStore().currentResponses(),
              //   [question.id]: { ...temp },
              // };

              //scrolling when option is selected in checkbox
              // if (temp[x.value] && !props.shouldNotScroll) {
              //   setTimeout(() => {
              //     window.scrollBy(0, 100);
              //   }, 200);
              // }

            }}
            {...props}
          />
        </div>
      );
    }


    if (question.type === "multiselect") {
      (question.optionsSorted == true
        ? question.options
        : renderOptionsForQues(question.options, question.name, question)
      ).forEach((qstn) => {
        if (!qstn.hide) {
          let newObj = qstn;
          newObj.label = qstn.text;
          questionArray.push(newObj)
        }
      })

      return (
        <div className="mt-2" id={question.name}>
          {/* {question.id} */}
          <div className="questionText">{putStar(question)}{question.text}</div>
          {question.disclaimer && (
            <FaInfoCircle
              color="black"
              onClick={(e) => setDisclaimer(!showDisclaimer)}
            />
          )}
          <DisclaimerElement />
          <div className="red-error">
            {getStore().errorMessages[question.id] ||
              getStore().errorMessages[question.name]}
          </div>


          < MultiSelect options={questionArray} isMultiSelectEnabled={true}
            defaultValue={()=> selectedOptions(questionArray)}
            onChange={async (el) => {
              const newArray = el.map(e => e.text)
              if (el.length == 0) {
                getStore().currentResponses()[question.id] = {}
                return;
              }

              el.forEach((x) => {
                var temp = getStore().currentResponses()[question.id];

                if (!temp) {
                  getStore().currentResponses()[question.id] = {};
                  temp = getStore().currentResponses()[question.id];
                }

                if (
                  x.value.toLowerCase().startsWith("gesamte") &&
                  !temp[x.text]
                ) {
                  Object.keys(temp).map((x) => {
                    temp[x] = false;
                  });
                }

                temp[x.text] = true

                const allResp = toJS(getStore().currentResponses()[question.id])
                Object.keys(allResp).map((singleResp) => {
                  if (!newArray.includes(singleResp)) {
                    getStore().currentResponses()[question.id][singleResp] = false
                  }
                })


                checkForFlowModifications(question, 1, x.text);

                if (!temp[x.text]) {
                  //Remove subquestions from the validation list.
                  if (x.subquestions) {
                    getStore().validateQuestions =
                      getStore().validateQuestions.filter(
                        (y) => !x.subquestions.includes(y)
                      );

                    let t = getStore().currentResponses();

                    x.subquestions.map((subqid) => {
                      t[subqid] = null;
                    });

                    getStore().responses[
                      getStore().activeTab[1].formName
                    ] = {
                      ...t,
                    };
                  }
                }

                getStore().responses[getStore().activeTab[1].formName] = {
                  ...getStore().currentResponses(),
                  [question.id]: { ...temp },
                };

                // console.log(toJS(getStore().responses[getStore().activeTab[1].formName]), "all")

                if (temp[x.value] && !props.shouldNotScroll) {
                  setTimeout(() => {
                    window.scrollBy(0, 100);
                  }, 200);
                }
              });
              
            }}
            {...props}
          />
        </div>
      );
    }

    if (question.type == "table") {
      return (
        <>
          <ResolveTable question={question} adminStore={props.adminStore} />
        </>
      );
    }

    if (question.type == "comment") {
      return <div style={{ color: "red" }}>{putStar(question)}{question.text}</div>;
    }

    return <div>{question.subtext}</div>;
  },
  { forwardRef: true }
);

export default ResolveElement;
