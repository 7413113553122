import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useParams, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import firebase from "../../Utils/firebase";
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import "./form.css";

var responses;
var uploadedFiles = {};
const HolidaySherpaForm = observer((props) => {
  const history = useHistory();
  const { formId, caseId, uid } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const step_to_form_map = {
    1: "Mängel der Unterkunft bzw. Mängel der Kreuzfahrt", 
    2: "Stornierung der Pauschalreise (inkl. Kreuzfahrt)", 
    3: "Flugverspätung", 
    4: "Flugannullierung"
  };

  useEffect(() => {
    const validateAccess = async() => {
      if( uid != firebase.auth().currentUser.uid ) {
        history.push("/holidaysherpa/cases");
      }

      const validateFormAccess = await fetch("/api/holidaysherpa/formAccessValidation", {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: caseId,
          formId: formId
        })
      });
      const result = await validateFormAccess.json();
      if((!result.success) || (!result.allowed)) {
        history.push("/holidaysherpa/cases");
      }
    };
    
    if(!props.preview) {
      validateAccess();
    }
  }, []);

  const handleSubmit = (stepResponses) => {
    responses = stepResponses;
    setShowAlert(true);
  };

  const formSubmit = async () => {
    if(!consent) {
      setConsentError(true);
      return;
    }

    setConsentError(false);
    console.log('stepresponse', responses, uploadedFiles);
    setDisableSubmitBtn(true);
    let resp;
    try{
      const result = await fetch("/api/holidaysherpa/formResponse", {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: caseId,
          formId: formId,
          responses: responses
        })
      });
      resp = await result.json();
      console.log('resp', resp);

      if(Object.keys(uploadedFiles).length) {
        var formData = new FormData();

        Object.values(uploadedFiles).map((file, index) => {
          formData.append(`document`, file);
        });
        formData.append("caseId", caseId);
        formData.append("formId", formId);

        const result2 = await fetch(`/api/holidaysherpa/formUploads`, {
          method: "post",
          body: formData,
        });
        const resp2 = await result2.json();
        console.log('resp2', resp2);
      }
    } catch(err) {
      console.log(err);
    }

    setDisableSubmitBtn(false);
    if(resp.success) {
      history.push("/holidaysherpa/cases");
    }
  };
  
  const renderStepForm = () => {

    if(props.preview) {
      return (
        <>
          <Form1 formName={step_to_form_map[1]} disabled={true} responses={props.responses[1] ? props.responses[1] : {} } preview={true} downloadFiles={props.downloadFiles} gcpFolderName={`${props.caseId}_1`} />
          <Form2 formName={step_to_form_map[2]} disabled={true} responses={props.responses[2] ? props.responses[2] : {} } preview={true} downloadFiles={props.downloadFiles} gcpFolderName={`${props.caseId}_2`} />
          <Form3 formName={step_to_form_map[3]} disabled={true} responses={props.responses[3] ? props.responses[3] : {} } preview={true} downloadFiles={props.downloadFiles} gcpFolderName={`${props.caseId}_3`} />
          <Form4 formName={step_to_form_map[4]} disabled={true} responses={props.responses[4] ? props.responses[4] : {} } preview={true} downloadFiles={props.downloadFiles} gcpFolderName={`${props.caseId}_4`} />
        </>
      );
    }

    switch(formId) {
      case '1':
        return <Form1 formName={step_to_form_map[formId]} uploadedFiles={uploadedFiles} disabled={false} handleSubmit={handleSubmit} />
      case '2':
        return <Form2 formName={step_to_form_map[formId]} uploadedFiles={uploadedFiles} disabled={false} handleSubmit={handleSubmit} />
      case '3':
        return <Form3 formName={step_to_form_map[formId]} uploadedFiles={uploadedFiles} disabled={false} handleSubmit={handleSubmit} />
      case '4':
        return <Form4 formName={step_to_form_map[formId]} uploadedFiles={uploadedFiles} disabled={false} handleSubmit={handleSubmit} />
      default:
        return <Form1 formName={step_to_form_map[1]} uploadedFiles={uploadedFiles} disabled={false} handleSubmit={handleSubmit} />
    }
  };

  return (
    <>
      <Modal show={showAlert} className="alert">
        <div className="p-4">
          <h4 className="mb-3"><b> Bestätigung </b></h4>

          <div className="alert-body">
            <div className='mb-3'>
              <label for="consent" className="alert-label mt-0"> 
                <input type="checkbox" id="consent" value="consent" 
                  onChange={(e)=>{
                    setConsent(e.target.checked);
                  }}
                />
                Hiermit bestätige ich, dass ich das <a href="https://app.holidaysherpa.de/holidaysherpa/instructions" target="_blank" className="text-decoration-none"> „Merkblatt zur erfolgreichen Durchsetzung Deiner Ansprüche im Reiseprozess”</a> gelesen habe und mit deren Inhalten vertraut bin. Ich erkläre mich hiermit einverstanden, den vorgesehenen Vorgang fortzusetzen. 
              </label>
              <figcaption id="consent-error" className="red-error alert-err"
                style={{
                  display: consentError ? 'block' : 'none'
                }}
              >
                Dieses Feld ist ein Pflichtfeld
              </figcaption>
            </div>

            <p className="mb-1">Sie sind dabei, die Details an Holiday Sherpa zu übermitteln. Möchten Sie fortfahren? </p>
          </div>

          <div className="d-flex mt-2">
            <button className="alert-btn" 
              onClick={()=> {
                setConsent(false);
                setConsentError(false);
                setShowAlert(false);
              }}
            >
              Abbrechen
            </button>
            <button className="alert-btn" disabled={disableSubmitBtn} onClick={()=> formSubmit()} style={{background: '#3249B4'}}>Absenden</button>
          </div>
        </div>
      </Modal>
      { renderStepForm() }
    </>
  );
});

export default HolidaySherpaForm;
