import { useContext, useEffect, useState } from "react";
import Element from "../Components/Flow/Element";
import "./questionaire.css";
import { useParams } from "react-router-dom";
import Sidebar from "../Components/Common/Sidebar";
import { observer } from "mobx-react-lite";
import { ClientContext } from "../stores/ClientStore";
import ReactLoading from "react-loading";
import { flow, toJS } from "mobx";
import { ButtonFetch } from "../Utils/Helper";
import ClaimedPage from "../Components/Flow/ClaimedPage";
import CustomerReport from "../Components/Flow/CustomerReport";
import EarlyEnd from "../Components/Flow/EarlyEnd";
import firebase from "../Utils/firebase";
import { CacheContext } from "../stores/CacheStore";
import { Modal } from 'react-bootstrap';
import { FaCheck } from "react-icons/fa";
import { fetchData } from "../Utils/Helper";

/*

going forward to a flow: locked unless all previous flows are completed
going backward to a flow: free to do (progress is likely completed).


*/

const FormHeadings = observer((props) => {
  const clientStore = useContext(ClientContext);

  const allSections = clientStore.forms.map((x) => x.formName);
  let unlockedTabIndex = 0;
  for(let i = 0; i < allSections.length; i++){
    if(clientStore.completedForms[allSections[i]]){
      unlockedTabIndex++;
    } else {
      break;
    }
  }

  const scrollToRef = (ref) => {
    "ref", toJS(ref);
    if (!ref) return false;

    "offset", ref.current.offsetTop, ref.current.offsetHeight;

    //128 is offset of first element. Hacky
    //window.scrollTo(0, 2);

    setTimeout(() => {
      window.scrollTo(0, ref.current.offsetTop - 128);
    }, 200);

    return true;
  };
  
  const getScrollOffset = () => {
    let totalOffsetHeight = 0;
    for (let i = 0; i < clientStore.activeQuestion; i++) {
      let element = document.getElementById(`${i.toString().toString()}`);
      totalOffsetHeight += element?.offsetHeight;
    }
    return totalOffsetHeight;
  };

  return (
    <div className="formheadingsflex">
      {allSections.map((x, index) => {
        const isActive = clientStore.activeTab[0] == index;

        return (
          <div
            className="formheading"
            style={{
              color: clientStore.allFlowsCompleted ? "#7c8799" : isActive ? "#3249B4" : "#7c8799",
              marginTop: allSections.length > 12 ? "6px" : "10px",
              marginBottom: allSections.length > 12 ? "6px" : "10px",
              cursor: "pointer",
              width: '80%',
              fontSize: allSections.length > 12 ? '80%' : '100%'
            }}
            onClick={() => {
              if (clientStore.displayComponent == "QUESTIONS") {
                if (index <= unlockedTabIndex) {
                  clientStore.updateTab(index);

                  "refs",
                    toJS(clientStore.refs),
                    toJS(clientStore.currentQuestion);

                  setTimeout(() => {
                    window.scrollTo(0, getScrollOffset()); 
                  }, 200);
                  // scrollToRef(clientStore.refs[clientStore.currentQuestion]);
                } else {
                  clientStore.rightPanelError =
                    "Bitte beenden Sie zum Fortfahren den aktuellen Fragebogen.";

                  setTimeout(() => {
                    clientStore.rightPanelError = null;
                  }, 5000);
                }
              }
            }}
          >
            {x + " "}
            {clientStore.completedForms[x] ? <FaCheck color="green"/> : <></>}
          </div>
        );
      })}
    </div>
  );
});

const QuestionPage = observer(() => {
  const clientStore = useContext(ClientContext);
  const cacheStore = useContext(CacheContext);

  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [removedFormList, setRemovedFormList] = useState([]);

  const allSections = clientStore.forms.map((x) => x.formName);
  const displayAlert = ()=>{
    let filledButRemovedForms = [];
    Object.keys(clientStore.responses).forEach((formName)=>{
      if(!allSections.includes(formName) && (formName != "Allgemeines")){
        filledButRemovedForms.push(formName);
      }
    });
    setRemovedFormList(filledButRemovedForms);
    setShowAlert(true);
  }
  
  const alertAction = async(bool)=>{
    if(bool){
      if(consent){
        setConsentError(false);
        setShowAlert(false);
        clientStore.allFlowsCompleted = true;
        await submitFunction();
      } else {
        setConsentError(true);
      }
    } else {
      setConsent(false);
      setConsentError(false);
      setShowAlert(false);
    }
  };

  const getScrollOffset = () => {
    let totalOffsetHeight = 0;
    for (let i = 0; i < clientStore.activeQuestion; i++) {
      let element = document.getElementById(`${i.toString().toString()}`);
      totalOffsetHeight += element?.offsetHeight;
    }
    return totalOffsetHeight;
  };

  const traverseForms = (index) => {
    for (var i = 0; i < clientStore.forms.length; i++) {
      if (index < clientStore.forms[i].questions.length) {
        return [clientStore.forms[i].questions[index], i];
      } else {
        index -= clientStore.forms[i].questions.length;
      }
    }

    return [-1, -1];
  };

  const saveClientState = async ()=>{
    let state = {};
    for(const key in clientStore){
      if((typeof clientStore[key] != 'function') && (!['refs', 'uploadedFiles'].includes(key)) ){
        state[key] = clientStore[key];
      }
    }
    state['errorMessages'] = {};

    const result = await fetch(`/api/clientState`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: firebase.auth().currentUser.uid,
        clientState: state
      }),
    });
    const data = await result.json();

    if(Object.keys(clientStore.uploadedFiles).length) {
      var formData = new FormData();

      Object.values(clientStore.uploadedFiles).map((file, index) => {
        formData.append(`document`, file);
      });
      
      let name = firebase.auth().currentUser.uid;
      formData.append("name", name);

      const [resp, err] = await fetchData(
        `/api/uploadFilesOnWeiterClick`,
        {
          method: "post",
          body: formData,
        }
      );

      if (err) console.log("error uploading files.");
      clientStore.uploadedFiles = {};
    }
  };

  const resolveTab = () => {
    if (clientStore.forms.length === 0) {
      return (
        <div className="full-size-center" style={{ height: "90vh" }}>
          <ReactLoading type="spokes" color="black" />
        </div>
      );
    }

    if (
      clientStore.loadingOptions.report ||
      clientStore.loadingOptions.claimed
    ) {
      return (
        <div className="full-size-center" style={{ height: "90vh" }}>
          <ReactLoading type="spokes" color="black" />
        </div>
      );
    }

    if (clientStore.displayComponent == "CLAIMED") {
      return <ClaimedPage />;
    }

    if (clientStore.displayComponent == "REPORT") {
      return <CustomerReport />;
    }

    if (clientStore.displayComponent == "QUESTIONS") {
      const totalQuestions = Math.min(
        ( clientStore.forms.reduce((v, x) => v + x.questions.length, 0) - 1),
        clientStore.lastAttemptedQuestion 
      );

      if(clientStore.activeQuestion == 0){
        return (
          <div>
            <Element id={'0'} questionId={traverseForms(0)[0]} displayAlert={displayAlert} saveClientState={saveClientState}/>
          </div>
        )
      }
      return (
        <div>
          {Array.from("a".repeat(totalQuestions + 1)).map((_, i) => {
            return (
              <Element id={i.toString()} questionId={traverseForms(i)[0]} displayAlert={displayAlert} saveClientState={saveClientState}/>
            );
          })}
        </div>
      );
    }

    if (clientStore.displayComponent == "EARLYEND") {
      return <EarlyEnd />;
    }

    return (
      <div className="full-size-center" style={{ height: "90vh" }}>
        <ReactLoading type="spokes" color="black" />
      </div>
    );
  };

  const submitFunction = async () => {
    removedFormList.forEach((form)=>{
      delete clientStore.responses[form];
    })

    var responses = {};

    Object.keys(clientStore.responses).map((x) => {
      responses = { ...responses, ...clientStore.responses[x] };
    });

    Object.keys(responses).map((x) => {
      const name = cacheStore.questionNameFromId(x);
      responses[name] = responses[x];
    });

    ({ responses });

    const result = await fetch("/api/questionaire/1/getcompensation", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...responses,
      }),
    });

    const resp = await result.json();

    if (resp.success) {
      console.log("I am here, the trip price is"+responses["tripPrice"] + " ant the type" + typeof responses["tripPrice"])
      clientStore.currentReport = {
        compensationAmount: 0.3 * parseFloat(responses["tripPrice"].replace(/\./g, '').replace(',', '.')),
        predictionAccuracy: 80,
      };

      clientStore.displayComponent = "REPORT";
    } else {
      console.log("I am here, the trip price is"+responses["tripPrice"] + " ant the type" + typeof responses["tripPrice"])
      clientStore.currentReport = {
        compensationAmount: 0.3 * parseFloat(responses["tripPrice"].replace(/\./g, '').replace(',', '.')),
        predictionAccuracy: 80,
      };
    }
    clientStore.currentReport.maxRefundAmount = parseFloat(
      clientStore.genericResponses?.tripPrice?.toString()
    );
    saveClientState();
  };

  useEffect(() => {
    const getQuestions = async () => {
      const uid = firebase.auth().currentUser.uid;
      const getClientState = await fetch(`api/clientState/${uid}`, {
        method: "get",
      });
      const data = await getClientState.json();

      if((data.success) && (typeof data.data != 'string')){
        for(const key in data.data){
          clientStore[key] = data.data[key];
        }

        // setTimeout(() => {
        //   window.scrollTo(0, getScrollOffset()); 
        // }, 10000);
      } else {
        const genericQ = await fetch("/flowapi/demo-questions-init");
        const genericQuestions = await genericQ.json();

        if (!clientStore.forms.map((x) => x.formName).includes("Allgemeines")) {
          clientStore.forms.push({
            formName: "Allgemeines",
            id: "3pKyf4VYSSXn6WCnhcfYN8",
            questions: genericQuestions.data.questionList,
          });

          clientStore.formsUsed.push("3pKyf4VYSSXn6WCnhcfYN8");

          clientStore.uploadedFiles = [];

          clientStore.currentQuestion = traverseForms(0)[0];
        }
      }
    };

    getQuestions();
  }, []);

  return (
    <div style={{marginTop: '-6px'}}>
      <Sidebar progressBar={true} />
      <div className="disclaimer" >
        <FormHeadings />
      </div>
      <ErrorMessages />
      
      <Modal show={showAlert} className="alert">
        <div className="p-4">
          <h4 className="mb-3"><b> Bestätigung </b></h4>

          <div className="alert-body">
            <p className="mb-1"><b>Datenschutz </b></p>
            <figcaption id="consent-error" className="red-error alert-err"
              style={{
                display: consentError ? 'block' : 'none'
              }}
            >
              Dieses Feld ist ein Pflichtfeld
            </figcaption>
            <label for="consent" className="alert-label mt-0"> 
              <input type="checkbox" id="consent" value="consent" 
                onChange={(e)=>{
                  setConsent(e.target.checked);
                }}
              />
              Ich bin mit der Speicherung und Verwendung meiner Daten zum Zweck der Vertragsanbahnung einverstanden. Hierdurch wird eine benutzerfreundliche und effektive Vervollständigung meiner Angaben und eine zuverlässige Beantwortung von Rückfragen sichergestellt. Weitere Informationen finden Sie in unserer <a href="https://holidaysherpa.de/datenschutzerklaerung/" target="_blank" className="text-decoration-none"> Datenschutzerklärung.</a>
            </label>

            
            <div className="mt-2">
              <p className="mb-0">Die folgenden Fragebögen wurden von Ihnen bereits ausgefüllt : </p>
              <ul className="my-0">
                {
                  allSections.map((form)=>{
                    return <li>{form}</li>;
                  })
                }
              </ul>
              { removedFormList.length > 0 ? 
                <>
                  <p className="mt-2 mb-0">Sie haben mit dem Ausfüllen folgender Fragebögen begonnen, diese aber später wieder gelöscht : </p>
                  <ul className="my-0">
                    {
                      removedFormList.map((form)=>{
                        return <li>{form}</li>;
                      })
                    }
                  </ul>
                </>: 
                <></>
              }
              <p className="mt-2"> Sind Sie sicher, dass Sie das Formular abschicken möchten? </p>
            </div> 
          </div>

          <div className="d-flex mt-2">
            <button className="alert-btn" onClick={()=> alertAction(false)}>Abbrechen</button>
            <button className="alert-btn" onClick={()=> alertAction(true)} style={{background: '#3249B4'}}>Absenden</button>
          </div>
        </div>
      </Modal>
      <div style={{ paddingTop: "6vh" }}>{resolveTab()}</div>
    </div>
  );
});

const ErrorMessages = observer(() => {
  const clientStore = useContext(ClientContext);

  return <div className="right-panel">{clientStore.rightPanelError}</div>;
});

export default QuestionPage;
