import React, { useState, useContext, useEffect, useRef } from "react";

import "./element.css";
import { observer } from "mobx-react-lite";
import { ClientContext } from "../../stores/ClientStore";
import { toJS } from "mobx";
import ReactLoading from "react-loading";
import firebase from "../../Utils/firebase";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import ResolveElement from "./ResolveElement";

import { fetchData, PrimaryButton, SecondaryButton } from "../../Utils/Helper";
import { CacheContext } from "../../stores/CacheStore";
import { DisclaimerElement } from "./DisclaimerElement";

const Element = observer((props) => {
  const clientStore = useContext(ClientContext);
  const cacheStore = useContext(CacheContext);

  const [isActive, setActive] = useState(
    props.questionId == clientStore.currentQuestion
  );

  var { nextFunction, prevFunction } = props;

  const myRef = useRef(null);

  clientStore.refs[`${props.questionId}`] = myRef;

  useEffect(() => {
    setActive(props.questionId == clientStore.currentQuestion);

    if (isActive) {
      myRef.current?.scrollIntoView();
    }

    const question = cacheStore.questionCached(clientStore.currentQuestion);

    if (!clientStore.validateQuestions.includes(clientStore.currentQuestion))
      clientStore.validateQuestions.push(clientStore.currentQuestion);

    if (
      question.id == "tiaSN8om2M9czf97wAKpRu" ||
      question.id == "pFgqhk5EWUWdqEp1KMh9ng"
    ) {
      // Reload generic flow;
      (async () => {
        const [data, err] = await fetchData("/flowapi/demo-questions-init");

        if (err) return;

        const traverseForms = (index) => {
          for (var i = 0; i < clientStore.forms.length; i++) {
            if (index < clientStore.forms[i].questions.length) {
              return [clientStore.forms[i].questions[index], i];
            } else {
              index -= clientStore.forms[i].questions.length;
            }
          }

          return [-1, -1];
        };

        if (!clientStore.forms[0]) {
          clientStore.forms[0] = { formName: "Allgemeines", questions: [] };
        }
        clientStore.forms[0].questions = [...data.questionList];

        setActive(true);
        console.log("precheck", toJS(clientStore));
        if(clientStore.completedForms[clientStore.forms[0].formName]){
          clientStore.lastAttemptedQuestion = clientStore.forms[0].questions.length;
        } else {
          clientStore.lastAttemptedQuestion = 0;
        }
        clientStore.activeQuestion = 0;
        clientStore.currentQuestion = traverseForms(0)[0];
      })();

      //Remove any added flows
      clientStore.forms = clientStore.forms.filter(
        (x) => x.formName == "Allgemeines"
      );
    }
  }, [clientStore.currentQuestion, myRef]);

  const scrollNotAnswered = (question)=>{
    let element = document.getElementById(`${question.id}-${question.name}`);
    if(element){
      element.scrollIntoView({block: 'center'});
    } else {
      element = document.getElementById(question.name);
      if(element){
        element.scrollIntoView({block: 'center'});
      }
    }
  };

  //TODO: move this to some top level file prolly
  const getScrollOffset = () => {
    let totalOffsetHeight = 0;
    for (let i = 0; i < clientStore.activeQuestion; i++) {
      let element = document.getElementById(`${i.toString().toString()}`);
      totalOffsetHeight += element?.offsetHeight;
    }
    return totalOffsetHeight;
  };

  const removeUndefined = (arr) => arr.filter((x) => x);

  const getDisplayedQuestions = (rootQuestionId) => {
    var ret = [];
    ret.push(rootQuestionId);

    const question = cacheStore.questionCached(rootQuestionId);

    if (question.type == "parent") {
      question.children.map((childQuestId) => {
        ret = ret.concat(getDisplayedQuestions(childQuestId));
      });
    }

    if (
      question.type == "checkbox" &&
      clientStore.currentResponses()[question.id]
    ) {
      question.options.map((option) => {
        if (clientStore.currentResponses()[question.id][option.text]) {
          option.subquestions?.map((childQuestId) => {
            ret = ret.concat(getDisplayedQuestions(childQuestId));
          });
        }
      });
    }

    if (["radio", "inlineradio", "dropdown"].includes(question.type)) {
      question.options.map((option) => {
        if (clientStore.currentResponses()[question.id] == option.text) {
          option.subquestions?.map((childQuestId) => {
            ret = ret.concat(getDisplayedQuestions(childQuestId));
          });
        }
      });
    }

    return ret;
  };

  const checkAllValidated = () => {
    const displayedQuestions = getDisplayedQuestions(
      clientStore.currentQuestion
    );

    let validated = displayedQuestions
      .map((questionId) => {
        const question = cacheStore.questionCached(questionId);
        if (question == 0) return true;

        if (validate(question)) {
          processQuestion(question);
          question.id, true;
          return true;
        }

        question.id, "false";
        return false;
      })
      .every((x) => x);

    let questionCnt = 0;
    for(let i = 0; i < clientStore.forms.length; i++){
      questionCnt += clientStore.forms[i].questions.length;
      if(questionCnt <= clientStore.activeQuestion){
        clientStore.completedForms[clientStore.forms[i].formName] = true;
      } else {
        if(!validated){
          clientStore.completedForms[clientStore.forms[i].formName] = false;
        } else if (questionCnt == clientStore.activeQuestion + 1) {
          clientStore.completedForms[clientStore.forms[i].formName] = true;
        }

        break;
      }
    }
    return validated;
  };

  if (!nextFunction) {
    nextFunction = async (e) => {
      "blockWeiter", toJS(clientStore.blockWeiter);

      // if (clientStore.blockWeiter > 0) {
      //   return;
      // }

      if (!checkAllValidated()) {
        return;
      }
      ("all validated");
      setTimeout(setActive(true), 3000);
      setActive(false);

      clientStore.currentDislcaimer = null;

      if (clientStore.endMessage) {
        clientStore.allFlowsCompleted = true;
        clientStore.displayComponent = "EARLYEND";
        return;
      }

      if (removeUndefined(clientStore.appendList).length > 0) {
        await Promise.all(
          clientStore.appendList.map(async (x) => {
            if (!x) return;
            const resp = await fetch(`/flowapi/form/${x}`);
            const data = await resp.json();

            const appendFlow = data.data.questionList;
            clientStore.forms[
              clientStore.traverseForm(clientStore.activeQuestion)[1]
            ].questions =
              clientStore.forms[
                clientStore.traverseForm(clientStore.activeQuestion)[1]
              ].questions.concat(appendFlow);

            clientStore.formsUsed.push(x);
          })
        );

        clientStore.appendList = [];
      }

      if (removeUndefined(clientStore.modifyList).length > 0) {
        clientStore.lastAttemptedQuestion = clientStore.activeQuestion;
        await Promise.all(
          clientStore.modifyList.map(async (x) => {
            if (!x) return;
            const resp = await fetch(`/flowapi/form/${x}`);
            const data = await resp.json();

            const modifyFlow = {
              id: data.data.id,
              questions: data.data.questionList,
              formName: data.data.formName,
            };

            clientStore.forms[
              clientStore.traverseForm(clientStore.activeQuestion)[1]
            ].questions = clientStore.forms[
              clientStore.traverseForm(clientStore.activeQuestion)[1]
            ].questions
              .slice(0, clientStore.activeQuestion + 1)
              .concat(modifyFlow.questions);

            clientStore.forms[
              clientStore.traverseForm(clientStore.activeQuestion)[1]
            ].formName = modifyFlow.formName;

            clientStore.formsUsed.push(x);
            if(clientStore.completedForms[modifyFlow.formName]){
              clientStore.lastAttemptedQuestion += modifyFlow.questions.length + 1;
            }
          })
        );

        clientStore.modifyList = [];
      }

      if (removeUndefined(clientStore.removeList).length > 0) {
        const difference = clientStore.forms.filter(
          (x) => !clientStore.removeList.includes(x.id)
        );
        clientStore.forms = [...difference];
        clientStore.removeList = [];
      }

      if (removeUndefined(clientStore.addList).length > 0) {
        let addedFlows = {};
        await Promise.all(
          clientStore.addList.map(async (x) => {
            if (!x) return;
            const resp = await fetch(`/flowapi/form/${x}`);
            const data = await resp.json();
            const addFlow = {
              id: data.data.id,
              questions: data.data.questionList,
              formName: data.data.formName,
            };

            if (
              addFlow.questions &&
              addFlow.formName &&
              !clientStore.forms
                .map((x) => x.formName)
                .includes(addFlow.formName)
            ) {
              addedFlows[addFlow.formName] = addFlow;
              clientStore.formsUsed.push(x);
            }
          })
        );

        let preiouslyAddedFormCnt = clientStore.addedFormOrder.length;
        if(preiouslyAddedFormCnt){
          for(let i = 0; i < preiouslyAddedFormCnt; i++){
            if(addedFlows[clientStore.addedFormOrder[i]]){
              clientStore.forms.push(addedFlows[clientStore.addedFormOrder[i]]);
            }
          }
        }
        Object.keys(addedFlows).forEach((formName)=>{
          if(!clientStore.addedFormOrder.includes(formName)){
            clientStore.forms.push(addedFlows[formName]);
          }
        });
        clientStore.addedFormOrder = [];
        let formsCnt = clientStore.forms.length;
        if(formsCnt > 1){
          for(let i = 1; i < formsCnt; i++){
            clientStore.addedFormOrder.push(clientStore.forms[i].formName);
          }

          if(clientStore.lastAttemptedQuestion == clientStore.forms[0].questions.length){
            for(let i = 1; i < formsCnt; i++){
              if(clientStore.completedForms[clientStore.forms[i].formName]){
                clientStore.lastAttemptedQuestion += addedFlows[clientStore.forms[i].formName].questions.length;
              } else {
                break;
              }
            }
          }
        }
        clientStore.addList = [];
      }

      if (clientStore.activeQuestion == clientStore.lastAttemptedQuestion) {
        clientStore.lastAttemptedQuestion += 1;
      }

      clientStore.updateQuestion(clientStore.activeQuestion + 1);

      if (clientStore.submit) {
        // if (clientStore.finalQuestion) {
        //   clientStore.addList.push('31uQXDUN88CYbBfPGSMwXF');
        //   clientStore.finalQuestion = false;
        //   clientStore.submit = false;
        //   nextFunction();
        // } else {
          if((parseInt(props.id) + 1) == clientStore.forms.reduce((v, x) => v + x.questions.length, 0)){
            props.displayAlert();
            setActive(true);
          }
        // }
      }

      props.saveClientState();
      setTimeout(() => {
        window.scrollTo(0, getScrollOffset());
      }, 200);
    };
  }

  if (!prevFunction) {
    prevFunction = async (e) => {
      if (!isActive) {
        let index = clientStore.questionList.findIndex(
          (q) => q == props.questionId
        );
        for(let i = 1; i < index; i++){
          clientStore.updateQuestion(i);
          if (!checkAllValidated()) {
            index = i;
            break;
          }
        }

        clientStore.updateQuestion(index);

        setTimeout(() => {
          window.scrollTo(0, getScrollOffset());
        }, 0);
      } else {
        clientStore.updateQuestion(clientStore.activeQuestion - 1);
        setTimeout(() => {
          window.scrollTo(0, getScrollOffset());
        }, 100);
      }
    };
  }

  let microPhoneQues = false;

  const processQuestion = (question) => {
    const processSubquestion = (id) => {
      const question = cacheStore.questionCached(id);
      processQuestion(question);
    };

    switch (question.type) {
      case "checkbox":
      case "multiselect":
        question.options.map((opt) => {
          if (
            clientStore.currentResponses()[question.id] &&
            clientStore.currentResponses()[question.id][opt.text]
          ) {
            if (opt.subquestions) {
              opt.subquestions.map((subq) => processSubquestion(subq));
            }

            if (question.addFlow) {
              if (clientStore.addList.includes(question.addFlow[opt.text]))
                return;
              clientStore.addList.push(question.addFlow[opt.text]);
            }
          }
        });

        break;
      case "radio":
      case "inlineradio":
      case "dropdown":
        // addList check

        const option = clientStore.currentResponses()[question.id];

        const endFlowMessage = question.options.filter(
          (x) => x.text == option
        )[0]?.endFlow;

        if (endFlowMessage) {
          clientStore.endMessage = endFlowMessage;
          return;
        } else {
          clientStore.endMessage = null;
        }

        question.options.map((opt) => {
          if (opt.text == option) {
            if (opt.subquestions) {
              opt.subquestions.map((subq) => processSubquestion(subq));
            }

            if (question.addFlow) {
              if (clientStore.addList.includes(question.addFlow[opt.text]))
                return;
              clientStore.addList.push(question.addFlow[opt.text]);
            }
          }
        });

        if (question.modifyFlow) {
          question.options.map((opt) => {
            if (opt.text == option) {
              if (opt.subquestions) {
                opt.subquestions.map((subq) => processSubquestion(subq));
              }

              if (
                clientStore.modifyList.includes(question.modifyFlow[opt.text])
              )
                return;
              clientStore.modifyList.push(question.modifyFlow[opt.text]);
            }
          });
        }

        if (question.appendFlow) {
          question.options.map((opt) => {
            if (opt.text == option) {
              if (opt.subquestions) {
                opt.subquestions.map((subq) => processSubquestion(subq));
              }

              if (
                clientStore.appendList.includes(question.appendFlow[opt.text])
              )
                return;
              clientStore.appendList.push(question.appendFlow[opt.text]);
            }
          });
        }

        break;
    }
  };

  const validate = (question) => {
    //return true;

    clientStore.errorMessages[question.id] = "";

    console.log(toJS(clientStore.currentResponses()[question.id]));

    if (!question.validation) {
      question.validation = {
        required: false,
      };

      return true;
    }

    if (!question.validation.required && 
      clientStore.currentResponses()[question.id] == null &&
      clientStore.currentResponses()[question.name] == null) {
      return true;
    }

    if(!question.validation.required && ((question.type == "checkbox") || (question.type == "multiselect"))){
      return true;
    }

    const skipped = ["table", "parent"];

    if (skipped.includes(question.type)) {
      return true;
    }

    //check for empty/null boxes but not empty array in case of list/checkboxes
    if (
      clientStore.currentResponses()[question.id] == null &&
      clientStore.currentResponses()[question.name] == null &&
      !clientStore.currentResponses()[question.id]
    ) {
      clientStore.errorMessages[question.id] =
        "Dieses Feld ist ein Pflichtfeld";

        clientStore.refs[question.id] = myRef;
        scrollNotAnswered(question);

      return false;
    }

    if (question.type == "text") {
      if (question.validation.type == "numeric") {
        const isNumeric = (str) => {
          if (typeof str != "string") return false;
          return !isNaN(str) && !isNaN(parseFloat(str));
        };

        var processed = clientStore
          .currentResponses()
          [question.id].split(".")
          .join("");

        processed = processed.split(",").join(".");

        if (!isNumeric(processed)) {
          clientStore.errorMessages[question.id] =
            "Eintrag im Textfeld muss nummerisch sein";
          scrollNotAnswered(question);
          return false;
        }

        if(question.validation.regex){

          const allResponses = Object.keys(clientStore.responses).reduce(
            (former, key) => ({ ...former, ...clientStore.responses[key] }),
            {}
          );
    
          const convertToArray = question.validation.regex
            .split("/")
            .map((term) => {
              return term.trim();
            });
    
          clientStore.errorMessages[question.id] = "";
    
          let valBool = [];
    
          // console.log('convertToArray ', convertToArray);
          for (let ind = 0; ind < convertToArray.length; ind++) {
            if (
              convertToArray[ind] == "greater" ||
              convertToArray[ind] == "lesser"
            ) {
              ind, convertToArray[ind];
              let curr_response = parseInt(clientStore.currentResponses()[question.id]);

              valBool = convertToArray[ind + 1]
                .slice(1, convertToArray[ind + 1].length - 1)
                .split(",")
                .map((condition) => {
                  const [variable, message] = condition.split(":");
                  let comparing_val = allResponses[cacheStore.questionIdFromName(variable.trim())];
    
                  if (!comparing_val) return true;
    
                  if (convertToArray[ind].trim() == "greater" && curr_response < comparing_val) {
                    clientStore.errorMessages[question.id] =
                      message ?? "Ungültige Eingabe";
                      scrollNotAnswered(question);
    
                    return false;
                  }
    
                  if (convertToArray[ind] == "lesser" && curr_response > comparing_val) {
                    clientStore.errorMessages[question.id] =
                      message ?? "Ungültige Eingabe";
                      scrollNotAnswered(question);
    
                    return false;
                  }
                });
    
              valBool = valBool.map((x) => (x == undefined ? true : x));
              valBool;
            }
            question.name, valBool, !valBool.every((x) => x);
    
            if (!valBool.every((x) => x)) {
              return false;
            }
          }
        }
      }

      if (question.validation.type == "alphanumeric") {
      }
      if (question.validation.type == "email") {
        const validateEmail = (email) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        };

        if (!validateEmail(clientStore.currentResponses()[question.id])) {
          clientStore.errorMessages[question.id] = "Ungültige E-Mail-Adresse!";
          scrollNotAnswered(question);
          return false;
        }
      }
    }

    if (question.type == "checkbox"||question.type == "multiselect") {
      //if you select and unselect an option the validation would fail if we only consider the check above, so checking again in case of multiselect
      const ticked =
        Object.entries(clientStore.currentResponses()[question.id])
          .map((option) => option[1])
          .filter((x) => x).length > 0; //ticked or selected in case of multiselect
      if (!ticked) {
        clientStore.errorMessages[question.id] =
          "Dieses Feld ist ein Pflichtfeld";
          scrollNotAnswered(question);

        return false;
      }
    }

    if (question.type=="singleselect"){
      const selected =
      clientStore.currentResponses()[question.id];
      //check if the object is empty for singleselect
      if(Object.keys(selected).length===0){
        clientStore.errorMessages[question.id] =
        "Dieses Feld ist ein Pflichtfeld";
        scrollNotAnswered(question);
      return false;
      }
    }

    const dateTypes = ["date", "time", "datetime"];

    if (dateTypes.includes(question.type)) {
      const allResponses = Object.keys(clientStore.responses).reduce(
        (former, key) => ({ ...former, ...clientStore.responses[key] }),
        {}
      );

      const convertToArray = question.validation.regex
        .split("/")
        .map((term) => {
          return term.trim();
        });

      const convert = (str) => {
        if (question.type == "date") {
          if (!str) return "1970-01-20";
          return str.split(".").reverse().join("-");
        }

        if (question.type == "time") {
          return `21st July 1969 ${str} GMT`;
        }

        if (question.type == "datetime"){
          if (!str) return "1970-01-20 5:30";
          if(str.split("T").length == 1) return str.split(".").reverse().join("-");
          return str.split("T").join(" ");
        }
      };

      clientStore.errorMessages[question.id] = "";

      var date1 = Date.parse(
        convert(clientStore.currentResponses()[question.id])
      );

      let valBool = [];

      for (let ind = 0; ind < convertToArray.length; ind++) {
        if (
          convertToArray[ind] == "greater" ||
          convertToArray[ind] == "lesser"
        ) {
          ind, convertToArray[ind];
          valBool = convertToArray[ind + 1]
            .slice(1, convertToArray[ind + 1].length - 1)
            .split(",")
            .map((condition) => {
              const [variable, message] = condition.split(":");

              const checkingDate =
                allResponses[cacheStore.questionIdFromName(variable.trim())];

              if (!checkingDate) return true;

              var date2 = Date.parse(convert(checkingDate));

              if (convertToArray[ind] == "greater" && date1 < date2) {
                clientStore.errorMessages[question.id] =
                  message ?? "Ungültiges Datum";
                  scrollNotAnswered(question);

                return false;
              }

              if (convertToArray[ind] == "lesser" && date1 > date2) {
                clientStore.errorMessages[question.id] =
                  message ?? "Ungültiges Datum";
                  scrollNotAnswered(question);

                return false;
              }
            });

          valBool = valBool.map((x) => (x == undefined ? true : x));
          valBool;
        }
        question.name, valBool, !valBool.every((x) => x);

        if (!valBool.every((x) => x)) {
          return false;
        }
      }
    }

    clientStore.errorMessages = {
      ...clientStore.errorMessages,
    };

    if (clientStore.errorMessages[question.id] != "") return false;

    return true;
  };

  //legacy code has to be refactored (Never called?)
  const validateOld = (question) => {
    //return true;
    "validating...", question.id, { question: toJS(question) };

    clientStore.errorMessages[question.id] = "";

    if (!question.validation) {
      question.validation = {
        required: false,
        type: "alphanumeric",
        regex: "",
      };
    }

    if (question.type == "table") {
      return true;
    }

    if (question.type == "parent") {
      return true;
    }

    if (
      clientStore.currentResponses()[question.id] == null &&
      clientStore.currentResponses()[question.name] == null &&
      question.type != "parent" &&
      question.validation.regex != "skip" &&
      (question.validation.required || question.validation.regex)
    ) {
      clientStore.errorMessages[question.id] =
        "Dieses Feld ist ein Pflichtfeld";
      ("we did come here man");
      return false;
    }

    if (
      question.type == "checkbox" &&
      question.validation.regex != "skip" &&
      (question.validation.required || question.validation.regex)
    ) {
      if (clientStore.currentResponses()[question.id]) {
        let valid = false;
        Object.entries(clientStore.currentResponses()[question.id]).forEach(
          (op) => {
            if (op[1] == true) {
              valid = true;
            }
          }
        );
        if (!valid) {
          clientStore.errorMessages[question.id] =
            "Dieses Feld ist ein Pflichtfeld";
          return false;
        }
      } else {
        clientStore.errorMessages[question.id] =
          "Dieses Feld ist ein Pflichtfeld";
        return false;
      }
    }

    if (
      question.type == "text" &&
      (question.validation.required || question.validation.regex)
    ) {
      if (question.validation.type == "numeric") {
        const isNumeric = (str) => {
          if (typeof str != "string") return false;
          return !isNaN(str) && !isNaN(parseFloat(str));
        };

        var processed = clientStore
          .currentResponses()
          [question.id].split(".")
          .join("");

        processed = processed.split(",").join(".");

        if (!isNumeric(processed)) {
          clientStore.errorMessages[question.id] =
            "Eintrag im Textfeld muss nummerisch sein";
          return false;
        }
      }
      if (question.validation.type == "alphanumeric") {
      }
      if (question.validation.type == "email") {
        const validateEmail = (email) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        };

        if (!validateEmail(clientStore.currentResponses()[question.id])) {
          clientStore.errorMessages[question.id] = "Ungültige E-Mail-Adresse!";
          return false;
        }
      }
    }

    if (
      (question.type == "date" || question.type == "time") &&
      question.validation &&
      (question.validation.required || question.validation.regex)
    ) {
      const convertToArray = question.validation.regex
        .split("/")
        .map((term) => {
          return term.trim();
        });

      const convert = (str) => {
        if (question.type == "date") {
          if (!str) return "1970-01-20";
          return str.split(".").reverse().join("-");
        }

        if (question.type == "time") {
          return `21st July 1969 ${str} GMT`;
        }
      };

      for (let ind = 0; ind < convertToArray.length; ind++) {
        if (
          convertToArray[ind] == "greater" ||
          convertToArray[ind] == "lesser"
        ) {
          convertToArray[ind + 1]
            .slice(1, convertToArray[ind + 1].length - 1)
            .split(",")
            .map((condition) => {
              const [variable, message] = condition.split(":");

              var date1 = Date.parse(convert(response));

              const checkingDate =
                clientStore.currentResponses()[
                  cacheStore.questionIdFromName(variable)
                ];

              var date2 = Date.parse(convert(checkingDate));

              if (convertToArray[ind] == "greater" && date1 < date2) {
                clientStore.errorMessages[question.id] =
                  message ?? "Ungültiges Datum";
                return false;
              }

              if (convertToArray[ind] == "lesser" && date1 > date2) {
                clientStore.errorMessages[question.id] =
                  message ?? "Ungültiges Datum";
                return false;
              }
            });
        }
      }
    }

    if (question.type == "radio") {
      const arr = question.options.map((x) => {
        if (
          x.triggers &&
          x.text === clientStore.currentResponses()[question.id]
        ) {
          return x.triggers
            ?.map((t) => {
              if (t.type == "endFlow") return true;

              var a = t.questions
                ?.map((y) => {
                  return validate(y);
                })
                .every((e) => e);

              return a;
            })
            .every((e) => e);
        }
        return true;
      });

      const msg = arr.every((e) => e);

      if (!msg) {
        clientStore.errorMessages[question.id] =
          "Dieses Feld ist ein Pflichtfeld";
        ("or here");
        return false;
      }
    }

    if (question.type == "dropdown" || question.type == "checkbox") {
      const array = question.options?.map((x) => {
        if (
          x.triggers &&
          (x.text === clientStore.currentResponses()[question.id] ||
            x.title === clientStore.currentResponses()[question.id] ||
            (clientStore.currentResponses()[question.id] &&
              clientStore.currentResponses()[question.id][x.name]))
        ) {
          return x.triggers
            .map((t) => {
              var a = t.questions
                .map((y) => {
                  return validate(y);
                })
                .every((e) => e);

              return a;
            })
            .every((e) => e);
        }
        return true;
      });

      if (!array) return true;

      const submsg = array.every((e) => e);

      if (!submsg) {
        return false;
      }
    }

    clientStore.errorMessages[question.id] = "";
    return true;
  };

  return clientStore.loadingOptions.questions && isActive ? (
    <div className="full-size-center" id={props.id} style={{ height: "90vh" }}>
      <ReactLoading type="spokes" color="black" />
    </div>
  ) : (
    <Container
      ref={myRef}
      id={props.id}
      className={`pt-4 ${isActive ? "ques-active" : ""}`}
    >
      <Row className="justify-content-end" id={`row-${props.id}`} >
        <Col xs="12" md="3"
          style={{display: props.id == 0 ? isActive ? 'none' : 'inherit' : 'default' }}
        >
          <SecondaryButton
            disabled={clientStore.activeQuestion === 0}
            onClick={prevFunction}
            className="text-uppercase"
          >
            {isActive ? "Zurück" : "Hier beginnen"}
          </SecondaryButton>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          xs="12"
          md="10"
          lg="8"
          className={`${!isActive ? "ques-inactive" : ""} mt-4`}
        >
          <Form>
            <ResolveElement
              key={`elem-${props.questionId}`}
              questionId={props.questionId}
              disabled={!isActive}
              microPhoneQues={microPhoneQues}
            />
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-end pt-4 pb-2">
        <Col xs="12" md="3" className={`${!isActive ? "ques-inactive" : ""}`}
          style={{display: isActive ? 'block' : 'none' }}
        >
          <PrimaryButton
            id={`${props.id}-next-button`}
            disabled={!isActive}
            onClick={nextFunction}
            className="text-uppercase"
          >
            Weiter
          </PrimaryButton>
        </Col>
      </Row>
    </Container>
  );
});

export default Element;
