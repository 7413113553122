import { useContext, useEffect, useState } from "react";

import { BsFillFileTextFill, BsTrash } from "react-icons/bs";

import { observer } from "mobx-react-lite";
import "./listtable.css";

import { Button } from "@material-ui/core";

import ReactLoading from "react-loading";

const ListTable = observer((props) => {
  const { listTitle, newItem, ListItem, list } = props;

  return (
    <div className="entire-page">
      <div className="page-header">
        <div>{listTitle}</div>
        <Button
          size="small"
          variant="contained"
          disableElevation
          style={{
            marginBottom: "10px",
          }}
          onClick={newItem}
        >
          New {listTitle}
        </Button>
      </div>

      <div className="list">
        <div className="header">
          <div className="list-icon fixer">
            <BsFillFileTextFill size={30} color={`transparent`} />
          </div>
          <div className="main-title table-heading">Name</div>
          <div className="secondary-title table-heading">Last Modified</div>
          <div className="list-icon">
            <BsTrash size={30} color="transparent" />
          </div>
        </div>
        <div className="list-scroll">
          {list.length > 0 ? (
            list.map((item, ind) => (
              <ListItem index={ind} item={item} key={ind} />
            ))
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              <ReactLoading type="spokes" color="black" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ListTable;
