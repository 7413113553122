import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import React, { useState, useContext, useEffect } from "react";

import "./element.css";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import Table from "react-bootstrap/Table";
import { EditContext } from "../../stores/EditStore";
import { FaInfoCircle } from "react-icons/fa";

const maintainOrderFlows = [
  "MangelSpecify",
  "PauschalorIndividualTrip",
  "dateOfDeficiency",
  "mangelBeseitigung",
  "deficiencyNotice",
  "typeOfDeficiencyReplacementAccomodation",
  "defectBalconyTerraceOrSeaview",
  "bugsLocation",
  "bugsDeadOrAlive",
  "baularmSub",
  "tierGehort",
  "acProblemsLocation",
  "locationVerschmutzung",
  "LocationSmell",
  "heatingProblemsLocation",
  "reasonsForCancellationPriorToHolidays",
  "bugsExactPlacesPrivate",
  "typeofDeficiencySanitarySupplies",
  "artLarm",
  "extentVolume3",
  "bugsQuantity",
  "whichRoomTypeSizeOfApartment",
  "assuredRoomTypeTravelDocuments",
  "newRoomType",
  "oldRoomType",
  "HowOftenWasTheRoomCleaned",
  "HowOftenNewHandtuecher",
  "specificHowOftenNewBedclothes",
];

const renderOptionsForQues = (options, name, element) => {
  //

  let gesamteIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("gesamte")
  );

  let gesamteRemoved;
  if (gesamteIndex != -1) {
    if (options) [gesamteRemoved] = options.splice(gesamteIndex, 1);
  }

  let andereIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("andere")
  );

  let andereRemoved;
  if (andereIndex != -1) {
    if (options) [andereRemoved] = options?.splice(andereIndex, 1);
  }

  let sonstigeIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("sonstige")
  );

  let sonstigeRemoved;
  if (sonstigeIndex != -1) {
    if (options) [sonstigeRemoved] = options?.splice(sonstigeIndex, 1);
    //
  }

  if (maintainOrderFlows.findIndex((e) => e == name) == -1) {
    options = options
      ?.slice()
      .sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1
      );
  } else {
  }
  if (andereIndex != -1) {
    //
    if (options) options.push(andereRemoved);
  }
  if (sonstigeIndex != -1) {
    //
    if (options) options.push(sonstigeRemoved);
  }

  if (gesamteIndex != -1) {
    if (options) options.splice(0, 0, gesamteRemoved);
  }

  element.optionsSorted = true;
  element.gesamtes = gesamteRemoved;
  element.options = options;
  return options;
};

const PreviewElement = observer(
  (props, ref) => {
    const editStore = useContext(EditContext);
    // const clientStore = useContext(E)

    const [question, setQuestion] = useState({});
    const [errorLoading, setError] = useState(false);

    useEffect(() => {
      const fetchThings = async () => {
        if (!props.questionId) return;
        const a = await fetch(`/flowapi/question/${props.questionId}`);

        var b = await a.json();

        if (!b.success) {
          setError(true);
        }

        if (b.data.type == "table") {
          const tableRes = await fetch(`/api/table/${b.data.tableId}`);
          const tableJson = await tableRes.json();

          b.data.headings = [...tableJson.data.headings];
        }

        setQuestion(b.data);
      };
      if (!props.rawElement) {
        fetchThings();
      } else {
        var temp = { ...props.rawElement };
        temp.text = temp.text ?? temp.title;
        setQuestion({ ...props.rawElement });
      }
    }, []);

    const deleteAllKeysTable = (elements) => {
      if (!elements) return;

      for (var i = 0; i < elements.length; i++) {
        delete editStore.previewResponses[elements[i].name];
        if (
          elements[i].type == "radio" ||
          elements[i].type == "checkbox" ||
          elements[i].type == "dropdown" ||
          elements[i].type == "inlineradio"
        ) {
          for (var j = 0; j < elements[i].options?.length; j++) {
            if (elements[i].options[j].triggers) {
              deleteAllKeysTable(elements[i].options[j].triggers[0].questions);
            }
          }
        } else if (elements[i].type == "parent") {
          deleteAllKeysTable(elements[i].children);
        }
      }
    };

    let [showOffcanvas, setShowOffcanvas] = useState(false);

    if (errorLoading) {
      return <>[error loading this question: {props.questionId}]</>;
    }

    if (question.type === "parent") {
      return (
        <div id={question.name}>
          <div className="questionText">{question.text} </div>
          <div className="subtext">{question.subtext}</div>
          {question.children.map((x, i) => {
            return (
              <PreviewElement
                subQuestion={true}
                questionId={x}
                key={x}
                disabled={props.disabled}
                shouldNotScroll={true}
                ref={ref}
              />
            );
          })}
        </div>
      );
    }

    if (question.type === "text") {
      var arrayNumber = (() => {
        var a = Number.parseInt(editStore.previewResponses[question.name]);
        if (isNaN(a)) return new Array(0);
        var ret = [];
        for (var i = 0; i < a; i++) ret.push("A");
        return ret;
      })();

      return (
        <div className="mt-2">
          <div id={question.name}>
            <Form.Group controlId={question.text}>
              <Form.Label className="questionText">{question.text}</Form.Label>
              {question.disclaimer && (
                <div>
                  <FaInfoCircle color="black" /> {question.disclaimer}
                </div>
              )}
              <Form.Control
                disabled={props.disabled}
                lang="de"
                language="de"
                placeholder={question.text}
                value={editStore.previewResponses[question.name]}
                onChange={(event) => {
                  if (props.preview) return;
                  var temp = editStore.previewResponses;

                  temp[question.name] = event.target.value;
                  clientStore.responses[clientStore.activeTab[1].formName] = {
                    ...temp,
                  };
                }}
                onKeyPress={(k) => {
                  if (k.key == "Enter") {
                    k.preventDefault();
                    if (
                      clientStore.questionList[clientStore.activeQuestion]
                        .type == "text" &&
                      clientStore.questionList[clientStore.activeQuestion]
                        .name == question.name
                    ) {
                      if (
                        clientStore.questionList[clientStore.activeQuestion]
                          .triggers
                      ) {
                        var element = document.getElementById(
                          `${question.name}`
                        );

                        if (!props.shouldNotScroll) {
                          setTimeout(() => {
                            window.scrollBy(0, element?.offsetHeight);
                          }, 300);
                        }
                      } else {
                        document
                          .getElementById(
                            `${clientStore.activeQuestion}-next-button`
                          )
                          .click();
                      }
                    } else {
                      var element = document.getElementById(`${question.name}`);

                      if (!props.shouldNotScroll) {
                        setTimeout(() => {
                          window.scrollBy(0, element?.offsetHeight);
                        }, 300);
                      }
                    }
                  }
                }}
                type="text"
              />
            </Form.Group>
          </div>

          <div className="sub-question">
            {question.triggers && question.triggers.type === "addQuestion" ? (
              <div>
                {question.triggers.number === "value"
                  ? arrayNumber.map((x, i) => {
                      return (
                        <PreviewElement
                          key={`${question.name}-${i}`}
                          element={{
                            ...question.triggers.question,
                            name: `${question.name}-${i}`,
                          }}
                          disabled={props.disabled}
                          ref={ref}
                          shouldNotScroll={props.shouldNotScroll}
                        />
                      );
                    })
                  : arrayNumber.map((x, i) => {
                      return (
                        <PreviewElement
                          key={`${question.name}-${i}`}
                          element={{
                            ...question.triggers.question,
                            name: `${question.name}-${i}`,
                          }}
                          disabled={props.disabled}
                          ref={ref}
                          shouldNotScroll={props.shouldNotScroll}
                        />
                      );
                    })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    if (question.type === "dropdown") {
      if (question.name == "city") {
        return (
          <div id={question.name} className="mt-2">
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{question.text}</Form.Label>
              {question.disclaimer && (
                <div>
                  <FaInfoCircle color="black" /> {question.disclaimer}
                </div>
              )}
              <div className="subtext">{question.subtext}</div>[ DropDownList
              API Fetch ]<div className="red-error">[ ]</div>
            </Form.Group>
          </div>
        );
      }
      if (question.name == "country") {
        return (
          <div id={question.name} className="mt-2">
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{question.text}</Form.Label>
              {question.disclaimer && (
                <div>
                  <FaInfoCircle color="black" /> {question.disclaimer}
                </div>
              )}
              <div className="subtext">{question.subtext}</div>[ DropDownList
              API Fetch ]<div className="red-error">[ ]</div>
            </Form.Group>
          </div>
        );
      }
      return (
        <div className="mt-2">
          <div id={question.name}>
            <Form.Group controlId={question.name}>
              <Form.Label className="questionText">{question.text}</Form.Label>
              {question.disclaimer && (
                <div>
                  <FaInfoCircle color="black" /> {question.disclaimer}
                </div>
              )}
              <div className="subtext">{question.subtext}</div>
              <Form.Select
                value={
                  editStore.previewResponses[question.name]
                    ? editStore.previewResponses[question.name]
                    : "select"
                }
                disabled={props.disabled}
                onChange={(e) => {
                  editStore.previewResponses[question.name] = e.target.value;
                }}
              >
                <option value="select" key={`${question.name}-select`}>
                  Wählen ...
                </option>
                {(question.optionsSorted == true
                  ? question.options
                  : renderOptionsForQues(
                      question.options,
                      question.name,
                      question
                    )
                ).map((x) => {
                  if (x.hide) return;
                  return (
                    <option
                      disabled={x.disabled ? true : false}
                      key={x.text}
                      value={x.text}
                    >
                      {x.text}
                    </option>
                  );
                })}
              </Form.Select>
              <div className="red-error">[ ]</div>
            </Form.Group>
          </div>
          <div>
            {/* {props.disabled ? <></> : <div id={question.name} ref={ref}></div>} */}
            {question.options?.map((x) => {
              if (
                x.subquestions &&
                x.text === editStore.previewResponses[question.name]
              ) {
                return x.subquestions.map((y) => {
                  return (
                    <div key={y.name}>
                      <PreviewElement
                        disabled={props.disabled}
                        element={{}}
                        subQuestion={true}
                        questionId={y}
                        ref={ref}
                        shouldNotScroll={props.shouldNotScroll}
                      />
                    </div>
                  );
                });
              }
              return null;
            })}
          </div>
        </div>
      );
      //}
    }

    if (question.type === "textarea") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <Form.Control
              as="textarea"
              disabled={props.disabled}
              placeholder={question.placeholder}
              value={editStore.previewResponses[question.name]}
              onChange={(event) => {
                if (props.preview) return;
                var temp = editStore.previewResponses;
                temp[question.name] = event.target.value;

                editStore.previewResponses = { ...temp };
              }}
            />
          </Form.Group>
        </div>
      );
    }

    if (question.type === "radio") {
      return (
        <div className="mt-2">
          <div id={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={question.name}
                name="controlled-radio-buttons-group"
              >
                {(question.optionsSorted == true
                  ? question.options
                  : renderOptionsForQues(
                      question.options,
                      question.name,
                      question
                    )
                ).map((x, i) => {
                  if (x.hide) return;
                  return (
                    <FormControlLabel
                      name={`${x.text}`}
                      value={x.text}
                      id={`${question.name}-${x.value}`}
                      checked={
                        x.text === editStore.previewResponses[question.name]
                      }
                      onChange={async (event) => {
                        var temp = editStore.previewResponses;
                        temp[question.name] = event.currentTarget.value;
                        editStore.previewResponses = {
                          ...temp,
                        };
                      }}
                      control={
                        <Radio
                          size="small"
                          style={{
                            color: "#3249B4",
                            fontSize: "100%",
                          }}
                        />
                      }
                      label={x.text}
                      disabled={x.disabled || props.disabled ? true : false}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="fix-font">
            {question.options.map((x) => {
              if (
                x.subquestions &&
                x.text === editStore.previewResponses[question.name]
              ) {
                return x.subquestions.map((y) => {
                  return (
                    <div key={y.name}>
                      <PreviewElement
                        disabled={props.disabled}
                        element={{}}
                        subQuestion={true}
                        questionId={y}
                        ref={ref}
                        shouldNotScroll={props.shouldNotScroll}
                      />
                    </div>
                  );
                });
                // });
              }
              return null;
            })}
          </div>
        </div>
      );
    }

    if (question.type === "inlineradio") {
      return (
        <div className="mt-2">
          <div id={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label={question.name}
                name="controlled-radio-buttons-group"
              >
                {(question.optionsSorted == true
                  ? question.options
                  : renderOptionsForQues(
                      question.options,
                      question.name,
                      question
                    )
                ).map((x, i) => {
                  if (x.hide) return;
                  return (
                    <FormControlLabel
                      name={`${x.text}`}
                      value={x.text}
                      id={`${question.name}-${x.value}`}
                      checked={
                        x.text === editStore.previewResponses[question.name]
                      }
                      onChange={(event) => {
                        var temp = editStore.previewResponses;
                        temp[question.name] = event.currentTarget.value;

                        editStore.previewResponses = {
                          ...temp,
                        };
                      }}
                      control={
                        <Radio
                          size="small"
                          style={{
                            color: "#3249B4",
                            fontSize: "100%",
                          }}
                        />
                      }
                      label={x.text}
                      disabled={x.disabled || props.disabled ? true : false}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="sub-question">
            {question.options.map((x) => {
              if (
                x.triggers &&
                x.text === editStore.previewResponses[question.name]
              ) {
                return x.triggers.map((t) => {
                  return t.questions.map((y) => {
                    if (y.type == "comment" && props.disabled != true) {
                      clientStore.endCurrentFlow = true;
                    } else {
                      clientStore.endCurrentFlow = false;
                    }
                    return (
                      <div className="individual-question" key={y.name}>
                        <PreviewElement
                          element={y}
                          disabled={props.disabled}
                          ref={ref}
                          shouldNotScroll={props.shouldNotScroll}
                        />
                      </div>
                    );
                  });
                });
              }
              return null;
            })}
          </div>
        </div>
      );
    }

    if (question.type === "date") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <Form.Control
              type="date"
              disabled={props.disabled}
              value={editStore.previewResponses[question.name]
                ?.split(".")
                .reverse()
                .join("-")}
              onChange={(event) => {
                var temp = editStore.previewResponses;
                temp[question.name] = event.currentTarget.value
                  .split("-")
                  .reverse()
                  .join(".");

                editStore.previewResponses = {
                  ...temp,
                };
              }}
            />
          </Form.Group>
        </div>
      );
    }

    if (question.type === "file") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <Form.Control
              type="file"
              disabled={props.disabled}
              onChange={(event) => {
                var temp = editStore.previewResponses;
                temp[question.name] = event.currentTarget.files[0];

                editStore.previewResponses = {
                  ...temp,
                };
              }}
            />
          </Form.Group>
        </div>
      );
    }

    if (question.type === "checkbox") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Label className="questionText">{question.text}</Form.Label>
          {question.disclaimer && (
            <div>
              <FaInfoCircle color="black" /> {question.disclaimer}
            </div>
          )}
          <div className="red-error">[ ]</div>
          {(question.optionsSorted == true
            ? question.options
            : renderOptionsForQues(question.options, question.name, question)
          )?.map((x, index) => {
            if (x.hide) return;
            return (
              <div key={`${question.name}-${index}`}>
                <label>
                  <input
                    type="checkbox"
                    name={`${question.name}-${index}`}
                    checked={
                      editStore.previewResponses[question.name]
                        ? editStore.previewResponses[question.name][x.value]
                        : false
                    }
                    color="#3249B4"
                    value={x.value ?? "unnamed option"}
                    id={`${question.name}-${index}`}
                    disabled={
                      (x.disabled || props.disabled ? true : false) ||
                      (!x.value?.toLowerCase().startsWith("gesamte") &&
                        (editStore.previewResponses[question.name]
                          ? editStore.previewResponses[question.name][
                              question.gesamtes?.value
                            ]
                          : false))
                    }
                    onChange={async (event) => {
                      var temp = editStore.previewResponses[question.name];

                      if (!temp) {
                        editStore.previewResponses[question.name] = {};
                        temp = editStore.previewResponses[question.name];
                      }

                      if (
                        x.value.toLowerCase().startsWith("gesamte") &&
                        !temp[x.text]
                      ) {
                        Object.keys(temp).map((x) => {
                          temp[x] = false;
                        });
                      }

                      temp[x.text] = !temp[x.text];

                      editStore.previewResponses = {
                        ...editStore.previewResponses,
                        [`${question.name}`]: { ...temp },
                      };
                    }}
                  />
                  {x.text}
                </label>

                <div className="sub-question">
                  {x.subquestions &&
                  editStore.previewResponses[question.name] &&
                  editStore.previewResponses[question.name][x.text] ? (
                    <div>
                      {x.subquestions?.map((y) => {
                        return (
                          <div key={y}>
                            <PreviewElement
                              questionId={y}
                              disabled={props.disabled}
                              ref={ref}
                              shouldNotScroll={props.shouldNotScroll}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (question.type === "table") {
      return (
        <div>
          <Offcanvas
            show={showOffcanvas}
            placement="end"
            onHide={() => {
              setShowOffcanvas(false);
            }}
            backdrop={false}
          >
            <Offcanvas.Header closeButton>Daten eingeben</Offcanvas.Header>
            <Offcanvas.Body>
              <Container>
                <Row>
                  {question?.headings?.map((h) => {
                    var head = { ...h };
                    head.text = h.text ?? h.title;

                    return (
                      <Col xs="12">
                        <PreviewElement
                          rawElement={head}
                          disabled={props.disabled}
                          ref={ref}
                          shouldNotScroll={true}
                        />
                      </Col>
                    );
                  })}
                  <Col xs="12">
                    <Button
                      onClick={(e) => {
                        let vali = true;

                        let data = {};
                        question?.headings?.map((h) => {
                          if (!editStore.previewResponses[h.name]) {
                            editStore.previewResponses[h.name] = "-";
                          }
                          if (h.type != "checkbox") {
                            data[h.name] = editStore.previewResponses[h.name];
                          } else {
                            let res = [];
                            if (editStore.previewResponses[h.name] == "-") {
                              res = ["-"];
                              data[h.name] = res;
                              return;
                            }
                            res = Object.entries(
                              editStore.previewResponses[h.name]
                            ).map((k, v) => {
                              if (k[1]) {
                                return k[0];
                              }
                            });
                            data[h.name] = res;
                          }
                        });
                        var temp = editStore.previewResponses;

                        var v = question?.headings?.map((h) => {
                          try {
                            const s = validate(h);
                            return s;
                          } catch {
                            return true;
                          }
                        });

                        if (v.every((x) => x)) {
                          if (!temp[question.name]) temp[question.name] = [];
                          temp[question.name].push(data);
                          setShowOffcanvas();
                          deleteAllKeysTable(table.headings);
                        }
                      }}
                    >
                      Hinzufügen
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Offcanvas.Body>
          </Offcanvas>
          <div className="individual-question" id={question.name}>
            <div className="questionText">{question.text}</div>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div>
              <Table responsive="xl">
                <thead>
                  <tr>
                    {question?.headings &&
                      question?.headings.map((x) => {
                        return <th key={x.title}>{x.title}</th>;
                      })}
                  </tr>
                </thead>

                <tbody>
                  {editStore.previewResponses[question.name] &&
                    editStore.previewResponses[question.name].map((x, i) => {
                      return (
                        <tr>
                          {x &&
                            Object.entries(x).map((y, ind) => {
                              return <td>{y[1]}</td>;
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <Button
              disabled={props.disabled}
              variant="primary"
              onClick={(e) => {
                question?.headings?.map((h) => {
                  editStore.previewResponses[h.name] = null;
                });
                setShowOffcanvas(true);
              }}
            >
              Daten eingeben
            </Button>
          </div>
        </div>
      );
    }

    if (question.type == "comment") {
      return (
        <div className="mt-2" id={question.name}>
          <p style={{ color: "red", fontWeight: "bold" }}>{question.text}</p>
          {question.disclaimer && (
            <div>
              <FaInfoCircle color="black" /> {question.disclaimer}
            </div>
          )}
        </div>
      );
    }

    if (question.type == "time") {
      return (
        <div className="mt-2" id={question.name}>
          <Form.Group controlId={question.name}>
            <Form.Label className="questionText">{question.text}</Form.Label>
            {question.disclaimer && (
              <div>
                <FaInfoCircle color="black" /> {question.disclaimer}
              </div>
            )}
            <div className="red-error">[ ]</div>
            <Form.Control
              type="time"
              disabled={props.disabled}
              value={editStore.previewResponses[question.name]}
              onChange={(event) => {
                var temp = editStore.previewResponses;
                temp[question.name] = event.currentTarget.value;

                editStore.previewResponses = {
                  ...temp,
                };
              }}
            />
          </Form.Group>
        </div>
      );
    }

    return <div>{question.subtext}</div>;
  },
  { forwardRef: true }
);

export default PreviewElement;
