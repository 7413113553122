import { useEffect, useContext, useState } from "react";
import "./pagestyle.css";
import "../App.css";
import { observer } from "mobx-react-lite";
import { AdminContext } from "../stores/AdminStore";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import firebase from "../Utils/firebase";
import ContractModal from "../Components/Admin/ContractModal";
import FormModal from "../Components/Admin/FormModal";
import CommunicationModal from "../Components/Admin/CommunicationModal";
import ReactLoading from "react-loading";

var mailTemplates = [];
const HolidaysherpaPage = observer((props) => {
  const adminStore = useContext(AdminContext);
  const history = useHistory();
  const [showContractStatus, setShowContractStatus] = useState(false);
  const [showFormsStatus, setShowFormsStatus] = useState(false);
  const [showCommunicationStatus, setShowCommunicationStatus] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(0);
  const [showSpinnerId, setShowSpinnerId] = useState(0);

  useEffect(() => {
    const initAdmin = async () => {
      try {
        const result = await fetch(`/api/holidaysherpa/caselist`);
        const data = await result.json();
        adminStore.caseList = data.data;

        const result2 = await fetch(`/api/holidaysherpa/mailTemplates`);
        const resp = await result2.json();
        console.log('mail templates ', resp);
        if(resp.success) {
          mailTemplates = resp.data;
        }
      } catch(err) {
        console.log(err);
      }
    };
  
    initAdmin();
  }, []);

  const spinner = (bool) => {
    return (
      bool
        ? <ReactLoading type="spokes" color="black" className='ms-2' height="22px" width="22px"/>
        : <></>
    );
  };

  const handleToggleDropdown = (ind) => {
    if(ind == openDropdownId){
      setOpenDropdownId(0);
    } else {
      setOpenDropdownId(ind);
    }
  };

  const sendResetCredentialsMail = async(email, ind) => {
    setShowSpinnerId(ind);
    try{
      const sent = await firebase.auth().sendPasswordResetEmail(email);
    } catch(err) {
      console.log('error ', err);
    }
    setShowSpinnerId(0);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-3">
        <Col xs="12" md="12" lg="12" style={{ width: '100%'}}>
          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col" width="6%">S. No.</th>
                <th scope="col" width="30%">Case ID</th>
                <th scope="col" width="16%">Case Status</th>
                <th scope="col" width="16%">Contract Status</th>
                <th scope="col" width="16%">Forms Status</th>
                <th scope="col" width="16%">Communication</th>
              </tr>
            </thead>
          </Table>
          <div className="mt-0 pt-0" style={{
            maxHeight: '60vh',
            overflowY: 'auto',
          }}>
            <Table className="table table-striped mt-0">
              <tbody>
                {
                  adminStore.caseList.map((Case, i) => {
                    return (
                      <tr>
                        <th scope="row" width="6%">{i + 1}</th>
                        <td width="30%">
                          <button className="btn btn-link" 
                            onClick={()=>{
                              adminStore.currentCase = Case;
                              history.push('display-web-response');
                            }}
                          >
                            {Case.id}
                          </button>
                        </td>
                        <td width="16%">
                          <select className="form-select" 
                            value={Case.status}
                            onChange={ async(e)=>{
                              Case.status = e.target.value;
                              const result = await fetch("/api/admin/updateStatus", {
                                method: "post",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  id : Case.id,
                                  dest: 'wordpress',
                                  status : e.target.value
                                }),
                              });
                            }}
                          >
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Resolve">Resolve</option>
                          </select>
                        </td>
                        <td width="16%"> 
                          <button className="px-4"
                            onClick={() => {
                              adminStore.currentCase = Case;
                              setShowContractStatus(true);
                            }}
                          > 
                            Show 
                          </button> 
                        </td>
                        <td width="16%"> 
                          <button className="px-4"
                            onClick={() => {
                              adminStore.currentCase = Case;
                              setShowFormsStatus(true);
                            }}
                          > 
                            Show 
                          </button> 
                        </td>
                        <td width="16%">
                          <button className="px-4"
                            onClick={() => {
                              adminStore.currentCase = Case;
                              setShowCommunicationStatus(true);
                            }}
                          > 
                            Show 
                          </button>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <ContractModal showContractStatus={showContractStatus} setShowContractStatus={setShowContractStatus} Case={adminStore.currentCase} />
      <FormModal showFormsStatus={showFormsStatus} setShowFormsStatus={setShowFormsStatus} Case={adminStore.currentCase} />
      <CommunicationModal showCommunicationStatus={showCommunicationStatus} setShowCommunicationStatus={setShowCommunicationStatus} Case={adminStore.currentCase} mailTemplates={mailTemplates} />
    </Container>
  );
});

export default HolidaysherpaPage;
