import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../Utils/firebase";
import "./loginpage.css";
import { UserContext } from "../Utils/UserContext";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function HolidaySherpaLoginPage(props) {
  const [email, setMail] = useState("");
  const [pass, setPass] = useState("");

  const [loginError, setLoginError] = useState("");
  const [validate, setValidate] = useState(false);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(100);

  const { authState, setAuthState } = useContext(UserContext);
  const AuthErrorMessageMap = require('./firebase_auth_error.json');
  const history = useHistory();

  const setAlert = (msg) => {
    setMessage(msg);
    setLoginError('');
  }

  const handleAuthError = (errCode) => {
    let err_message = AuthErrorMessageMap[errCode] ? AuthErrorMessageMap[errCode] : 'Es tut uns leid ! Es ist ein Fehler aufgetreten. Wir bedauern die Unannehmlichkeiten.';
    setLoginError(err_message);
  }

  const loginUser = async () => {
    try {
      setProgress(0);
      const userCredentials = await firebase.auth().signInWithEmailAndPassword(email, pass);
      const uid = firebase.auth().currentUser.uid;
      setProgress(80);
      setAuthState({ initializing: false, user: firebase.auth().currentUser, mfa_enabled: false });
      setProgress(100);
      history.push(`/holidaysherpa/cases`);
    } catch (err) {
      setProgress(100);
      handleAuthError(err.code);
    };
  };

  const domainNameLayer = async () => {
    let res = await fetch("../api/checkDomain");
    let data = await res.json();

    return data;
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() == false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      try {
        const val = await domainNameLayer();
        if (val.success) {
          loginUser();
        } else {
          setLoginError("Sie sind nicht berechtigt, ein Konto zu erstellen.");
        }
      } catch (e) {
        setLoginError(e.message);
      };
    }
    setValidate(true);
  };

  return (
    <>
      <div className='progressBar d-flex m-0 p-0'>
        <div className='m-0 p-0' 
        style={{ 
          width: `${progress}vw`,
          height: '4px',
          background: '#0226B9',
          display: progress == 100 ? 'none' : 'block'
          }}
        ></div>
        <div className='m-0 p-0' 
        style={{ 
          width: `${100 - progress}vw`,
          height: '4px',
          background: '#5290ec',
          display: progress == 100 ? 'none' : 'block'
          }}
        ></div>
      </div>

      <div className="container-signup">
        <Container>
          <Row className="justify-content-center my-4 mx-2">
            <Col xs="12" md="10" lg="6" className="box-signup pt-4 pb-3 px-4 shadow-sm">

              <h2 className="text-center text-uppercase">
                <b> anmelden </b>
              </h2>

              {message != "" ? (
                <p className={`box-success p-3 mt-4`}>{message}</p>
              ) : (
                <></>
              )}
              {loginError != "" ? (
                <p className={`box-error p-3 mt-4`}>{loginError}</p>
              ) : (
                <></>
              )}

              <Form
                id="SignupForm"
                className='mt-4'
                noValidate
                validated={validate}
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="email" className="text-nowrap mt-2">E-Mail-Adresse</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        required
                        value={email}
                        type="email"
                        name="email"
                        placeholder="E-Mail eingeben"
                        id="email"
                        onChange={(e) => setMail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Bitte geben Sie eine gültige E-Mail-Adresse an.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="password" className="text-nowrap mt-2">Passwort</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        required
                        minLength={6}
                        maxLength={40}
                        value={pass}
                        type="password"
                        name="password"
                        placeholder="Passwort eingeben"
                        id="password"
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Das Passwort muss mindestens sechs Zeichen lang sein.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Button
                  variant="primary"
                  className="text-uppercase"
                  type="submit"
                  disabled={progress != 100}
                >
                  Anmelden
                </Button>
              </Form>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HolidaySherpaLoginPage;
