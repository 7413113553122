const { observer } = require("mobx-react-lite");

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { ClientContext } from "../../stores/ClientStore";
import { CacheContext } from "../../stores/CacheStore";
import { fetchData } from "../../Utils/Helper";
import firebase from "../../Utils/firebase";

const CustomerReport = observer(() => {
  const clientStore = useContext(ClientContext);
  const cacheStore = useContext(CacheContext);

  return (
    <Container className="mt-4 container-report-box">
      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="8">
          <p>Geschafft!</p>
          <p>
            Unseren vorläufigen Berechnungen zufolge haben Sie einen Anspruch
            auf eine Erstattung bis zu{" "}
            <b>
              {/* {clientStore.currentReport.maxRefundAmount != NaN
                ? parseInt(0.3 * clientStore.currentReport.maxRefundAmount)
                : 0}{" "} */}
              {Math.round(clientStore.currentReport?.compensationAmount)} €.
            </b>
          </p>
          <p>
            Sie können uns jetzt damit beauftragen, eine Entschädigung von Ihrem
            Reiseunternehmen durchzusetzen.
          </p>
          {/* <p>
            Unser Service ist für Sie ohne Risiko, denn LegalAI arbeitet rein
            erfolgsbasiert.
          </p>
          <p>
            Nur im Erfolgsfall berechnen wir ein Honorar in Höhe von 25% inkl.
            MwSt.
          </p> */}
          <Button
            style={{
              backgroundColor: "#3249B4",
            }}
            className="text-uppercase"
            onClick={async (e) => {
              clientStore.loadingOptions.claimed = true;

              var responses = {};

              var formsUsed = [];
              var questionOrder = [];

              Object.keys(clientStore.responses).map((x) => {
                responses = { ...responses, ...clientStore.responses[x] };
                formsUsed.push(clientStore.getFormIdFromName(x));
                questionOrder = questionOrder.concat(
                  Object.keys(clientStore.responses[x])
                );
              });

              Object.keys(responses).map((x) => {
                const name = cacheStore.questionNameFromId(x);
                responses[name] = responses[x];
              });

              console.log(clientStore.formsUsed);

              const resp = await fetch(`/api/questionaire/1/claimright`, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userID: 42,
                  responses: {
                    ...responses,
                  },
                  formsUsed: clientStore.formsUsed,
                  currentReport: {
                    ...clientStore.currentReport,
                  },
                }),
              });
              const result = await resp.json();
              
              let name = `${responses["firstName"]}${responses["lastName"]}${responses["ReiseveranstalterNameGeneric"]}`.replace(
                / /g,
                "_"
              );

              const [data, err] = await fetchData(
                `/api/afterClaimUploadFolder`,
                {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    uid: firebase.auth().currentUser.uid,
                    finalName: name
                  })
                }
              );

              if (err) console.log("error in folder name change");

              clientStore.displayComponent = "CLAIMED";

              clientStore.loadingOptions.claimed = false;
              clientStore.uploadedFiles = {};

              const uid = firebase.auth().currentUser.uid;
              const [del, error] = await fetch(`api/clientState/${uid}`, {
                method: "delete",
              });
            }}
          >
            Jetzt Ansprüche durchsetzen
          </Button>
        </Col>
      </Row>
    </Container>
  );
});

export default CustomerReport;
