import React, { useState, useEffect } from 'react';
import "./form.css";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

function Form4({ formName, uploadedFiles, disabled, handleSubmit, ...props }) {
  const [stepResponse, setStepResponse] = useState({ name: [''] });
  const [nameError, setNameError] = useState(false);
  const [nameFieldCnt, setNameFieldCnt] = useState(0);

  const radioStyle = {
    color: "#3249B4",
    fontSize: "100%",
  };
  useEffect(() => {
    if(props.preview) {
      console.log('ye hai', props.responses);
      if(Object.keys(props.responses).length != 0) {
        setStepResponse(props.responses);
      }
    }
  }, []);

  const checkFormValidity = () => {
    if(stepResponse.name[0] != ''){
      setNameError(false);
      return true;
    }
    setNameError(true);
    return false;
  };

  return (
    <Container className="formBody my-4 px-4">
      <Row className="mb-3">
        <h2 className="formHeading">{formName}</h2>
      </Row>

      <Row className="mt-4">
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>
            Name(n) der Fluggäste 
            <span className="red-error">* </span>
          </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <div className="d-flex">
            <span className='mt-2'>1. </span>
            <Form.Control
              type="text"
              value={stepResponse.name[0]}
              disabled={disabled}
              onChange={(event) => {
                let name = stepResponse['name'];
                name[0] = event.target.value
                setStepResponse({ ...stepResponse, name: name })
              }}
              style={{ width: "100%" }}
            />
            <AiOutlinePlusCircle className='icon' 
              onClick={() => setNameFieldCnt(nameFieldCnt + 1) }
            />
            <AiOutlineMinusCircle className='icon'
              onClick={() => {
                if(nameFieldCnt == 0) 
                  return;
                setNameFieldCnt(nameFieldCnt - 1); 
              }}
            />
          </div>
          <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px', display: nameError ? 'block' : 'none' }}>
            Das ist ein Pflichtfeld
          </Form.Control.Feedback>
        </Col>
        {
          Array.from("a".repeat(nameFieldCnt)).map((_, i) => {
            return (
              <Col xs="12" md="8" lg="8">
                <div className="d-flex">
                  <span className='mt-2'>{i+2}. </span>
                  <Form.Control
                    type="text"
                    value={stepResponse.name[i+1]}
                    disabled={disabled}
                    onChange={(event) => {
                      let name = stepResponse['name'];
                      name[i+1] = event.target.value
                      setStepResponse({ ...stepResponse, name: name })
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            );
          })
        }
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Flugstrecke (von / über / nach) </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="text"
            value={ stepResponse.Flugstrecke ? stepResponse.Flugstrecke : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Flugstrecke: event.target.value })
            }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Flugnummer(n) </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="text"
            value={ stepResponse.Flugnummer ? stepResponse.Flugnummer : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Flugnummer: event.target.value })
            }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xs="12" md="4" lg="4">
          <h4 className='mt-2'><b>Flugdatum </b></h4>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>geplante Start </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="datetime-local"
            value={ stepResponse['geplante Start'] ? stepResponse['geplante Start'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'geplante Start': event.target.value })
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>geplante Ankunftszeit </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="datetime-local"
            value={ stepResponse['geplante Ankunftszeit'] ? stepResponse['geplante Ankunftszeit'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'geplante Ankunftszeit': event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Datum der Annullierung </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            locale="de"
            type="date"
            disabled={disabled}
            value={ stepResponse.Annullierung ? stepResponse.Annullierung : ''}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Annullierung: event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Ersatzbeförderung </h5>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Ersatzbeförderung": event.target.value })
              }}
              value="Ja"
              checked={ stepResponse["Ersatzbeförderung"] == "Ja"}
              control={<Radio size="small" style={radioStyle} />}
              label="Ja"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Ersatzbeförderung": event.target.value })
              }}
              value="Nein"
              checked={ stepResponse["Ersatzbeförderung"] == "Nein" }
              control={<Radio size="small" style={radioStyle} />}
              label="Nein"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Tatsächliche Abflug </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="datetime-local"
            value={ stepResponse['Tatsächliche Abflug'] ? stepResponse['Tatsächliche Abflug'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'Tatsächliche Abflug': event.target.value })
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Tatsächliche Ankunftszeit </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="datetime-local"
            value={ stepResponse['Tatsächliche Ankunftszeit'] ? stepResponse['Tatsächliche Ankunftszeit'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'Tatsächliche Ankunftszeit': event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Ankunftsort </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="text"
            value={ stepResponse.Ankunftsort ? stepResponse.Ankunftsort : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Ankunftsort: event.target.value })
            }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Ansprüche bei Fluggesellschaft angemeldet, falls ja, wann? </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            locale="de"
            type="date"
            value={ stepResponse['angemeldet'] ? stepResponse['angemeldet'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'angemeldet': event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Schriftl. Zahlungsablehnung durch Fluggesellschaft? Datum der Ablehnung </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            locale="de"
            type="date"
            value={ stepResponse['Ablehnung'] ? stepResponse['Ablehnung'] : ''}
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, 'Ablehnung': event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Reduzierter Tarif? </h5>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Reduzierter Tarif": event.target.value })
              }}
              value="Ja"
              checked={ stepResponse["Reduzierter Tarif"] == "Ja"}
              control={<Radio size="small" style={radioStyle} />}
              label="Ja"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Reduzierter Tarif": event.target.value })
              }}
              value="Nein"
              checked={ stepResponse["Reduzierter Tarif"] == "Nein" }
              control={<Radio size="small" style={radioStyle} />}
              label="Nein"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Mitarbeiter? </h5>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Mitarbeiter": event.target.value })
              }}
              value="Expedienten"
              checked={ stepResponse["Mitarbeiter"] == "Expedienten"}
              control={<Radio size="small" style={radioStyle} />}
              label="Expedienten"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
        <Col xs="6" md="4" lg="4">
          <RadioGroup>
            <FormControlLabel
              onChange={(event) => {
                setStepResponse({ ...stepResponse, "Mitarbeiter": event.target.value })
              }}
              value="Journalistentarif"
              checked={ stepResponse["Mitarbeiter"] == "Journalistentarif" }
              control={<Radio size="small" style={radioStyle} />}
              label="Journalistentarif"
              disabled={disabled}
            />
          </RadioGroup>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2 mb-0'>Teilzahlung erhalten? Betrag </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type="number"
            value={ stepResponse.Betrag ? stepResponse.Betrag : '' }
            disabled={disabled}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Betrag: event.target.value })
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Datum </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            locale="de"
            type="date"
            disabled={disabled}
            value={ stepResponse.Datum ? stepResponse.Datum : ''}
            onChange={(event) => {
              setStepResponse({ ...stepResponse, Datum: event.target.value })
            }}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Flugunterlagen </h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            placeholder={stepResponse.Flugunterlagen ? stepResponse.Flugunterlagen : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, ['Flugunterlagen']: event.currentTarget.files[0].name})
              uploadedFiles[`Flugunterlagen`] = event.currentTarget.files[0];
            }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5>Nachweis über weitere Aufwendungen </h5>
          <p><i> (Hotelkosten, Taxikosten, Verpflegung, Zusatzkosten für Weiterreise) </i></p>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            className='mt-3'
            placeholder={stepResponse.Aufwendungen ? stepResponse.Aufwendungen : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, Aufwendungen: event.currentTarget.files[0].name})
              uploadedFiles[`Aufwendungen`] = event.currentTarget.files[0];
            }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <div className="d-flex justify-content-end">
          <button 
            className="btn btn-primary px-3" 
            disabled={!props.preview}
            onClick={() => {
              props.downloadFiles('holidaysherpa_form_uploads', props.gcpFolderName);
            }}
            style={{
              display: props.preview ? 'block' : 'none'
            }}
          >
            Download Files
          </button>

          <button
            className="btn btn-primary px-3" 
            disabled={disabled}
            onClick={() => {
              if(checkFormValidity()) {
                handleSubmit(stepResponse);
              }
            }}
          >
            Weiter
          </button>
        </div>
      </Row>
    </Container>
  );
};

export default Form4;
