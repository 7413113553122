import Form from "react-bootstrap/Form";

export const DateTimeSelector = ({ value, setValue, ...props }) => {
  return (
    <div className="mt-2">
      <Form.Group>
        <Form.Control
          type="datetime-local"
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          disabled={props.disabled}
        />
      </Form.Group>
    </div>
  );
};

export const DateSelector = ({ value, setValue, ...props }) => {
  const convertDateToDots = (str) => str?.split("-").reverse().join(".");
  const convertDateToDashes = (str) => str?.split(".").reverse().join("-");

  return (
    <div className="mt-2">
      <Form.Group>
        <Form.Control
          locale="de"
          type="date"
          disabled={props.disabled}
          value={convertDateToDashes(value)}
          onChange={(event) => {
            setValue(convertDateToDots(event.currentTarget.value));
          }}
        />
      </Form.Group>
    </div>
  );
};

export const TimeSelector = ({ value, setValue, ...props }) => {
  return (
    <div className="mt-2">
      <Form.Group>
        <Form.Control
          lang="de"
          type="time"
          disabled={props.disabled}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </Form.Group>
    </div>
  );
};
