import { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../stores/DocumentStore";
import { ButtonFetch, fetchData } from "../../Utils/Helper";
import Sklate from "./Sklate";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import ReactLoading from "react-loading";
import { toJS } from "mobx";
import "./subdocuments.css";
import { Button, setRef } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { ConditionAdding } from "./ConditionAdding";

const SubDocument = observer((props) => {
  const { subDocumentId } = useParams();

  const documentStore = useContext(DocumentContext);

  useEffect(() => {
    documentStore.currentSubId = subDocumentId;

    const fetchVariables = async () => {
      //if (!documentStore.variablesNew.length > 1) {
      const [data, err] = await fetchData("/flowapi/getAllVariables");

      if (err) return;

      documentStore.variablesNew = [...data];
      //}

      const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      };

      documentStore.variablesNew =
        documentStore.variablesNew.filter(onlyUnique);

      //
    };

    fetchVariables();
  }, []);

  return (
    <div>
      <div className="status-bar">
        <input
          className="header-input custom-margin"
          value={documentStore.currentSubDocument?.name}
          onChange={(e) =>
            (documentStore.currentSubDocument.name = e.target.value)
          }
        />

        <div>
          <ButtonFetch
            className="save-button"
            fetchUrl="/docapi/new-subdocument/subdocument"
            fetchOptions={{
              method: "put",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                id: documentStore.currentSubId,
                ...toJS(documentStore.currentSubDocument),
                conditions: (() => {
                  var ret = {};
                  Object.keys(documentStore.conditions).map((x) => {
                    ret[x] = documentStore.conditions[x];
                  });
                  return ret;
                })(),
                name: toJS(documentStore.currentSubDocument.name),
                value: toJS(documentStore.currentSubDocument.value),
              }),
            }}
            successMessage="Saved"
            failMessage="Failed"
            initMessage="Save"
          />
        </div>
      </div>

      <div>
        <SubdocumentBlock subDocumentId={subDocumentId} />
      </div>
    </div>
  );
});

export const SubdocumentBlock = observer(({ subDocumentId }) => {
  const documentStore = useContext(DocumentContext);

  useEffect(() => {
    const fetchStuff = async () => {
      const [data, err] = await fetchData(
        `/docapi/new-subdocument/subdocument/${subDocumentId}`
      );

      if (err) return;
      if (!data) return;

      documentStore.currentSubDocument = data;
      documentStore.conditions = data.conditions;
    };
    fetchStuff();
  }, [documentStore.currentSubId]);

  return (
    <>
      {Object.keys(documentStore.currentSubDocument).length != 0 ? (
        <div>
          <Sklate
            value={toJS(documentStore.currentSubDocument.value)}
            mentionList={toJS(documentStore.variablesNew)}
            onChange={(value) => {
              var temp = documentStore.currentSubDocument;
              temp.value = [...value];
              documentStore.currentSubDocument = { ...temp };
            }}
          />
          <div className="second-component">
            <ConditionAdding type="subdocument" />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            alignContent: "center",
          }}
        >
          <ReactLoading type="spokes" color="black" />
        </div>
      )}
    </>
  );
});

export default SubDocument;
