import "./NERDisplay.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NERDisplay = (props) => {

  var exampleString = `Unser <mark>neues Hotel</mark> ist einfach <mark>nicht so schön</mark> wie das, was wir gebucht hatten, unsere Zimmer sind <mark>nicht frisch renoviert</mark>, wir <mark>mögen</mark> das <mark>Design nicht</mark> und außerdem ist das Hotel <mark>viel weiter entfernt</mark> vom <mark>Strand als</mark> unser <mark>ursprünglich gebuchtes</mark>, deshalb können wir nicht mehr zu Fuß gehen, sondern <mark>müssen</mark> immer den <mark>Bus nehmen</mark>, was sehr beschwerlich ist mit kleinen Kindern.
Außerdem <mark>sollten</mark> unsere Zimmer <mark>frisch renoviert</mark> sein, <mark>stattdessen</mark> haben wir <mark>Zimmer</mark>, an denen die <mark>Farbe an den Wänden</mark> bereits abgeplatzt ist, die <mark>Bäder</mark> schon <mark>heruntergekommen</mark> sind und dazu die <mark>Klimaanlage extrem laut</mark> ist.
Noch dazu fanden wir den Service wirklich unterirdisch- das Personal war nicht freundlich und hat kaum Englisch verstanden. Wir haben uns einfach nicht wohl gefühlt.
`;

  var html = {
    __html: exampleString,
  };

  return (
    <Row className="mt-4">
      <Col xs="12">
        <h5><b>Erfassung der Kommentare</b></h5>
        <div className="NERContainer mt-3" dangerouslySetInnerHTML={html}></div>
      </Col>
    </Row>
  );
};

export default NERDisplay;
