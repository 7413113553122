import React, { useState, useEffect } from 'react';
import "./form.css";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  FaMicrophone,
  FaMicrophoneSlash
} from "react-icons/fa";

function Form1({ formName, uploadedFiles, disabled, handleSubmit, ...props }) {
  const [stepResponse, setStepResponse] = useState({ more_info: ''});
  let { transcript, listening, resetTranscript, isMicrophoneAvailable } =
      useSpeechRecognition();

  useEffect(() => {
    const populateTextArea = () => {
      setStepResponse({ ...stepResponse, more_info: transcript });
    };

    if (!disabled) populateTextArea();
  }, [transcript]);
  useEffect(() => {
    if(props.preview) {
      console.log('ye hai', props.responses);
      if(Object.keys(props.responses).length != 0) {
        setStepResponse(props.responses);
      }
    }
  }, []);

  return ( 
    <Container className="formBody my-4 px-4">
      <Row className="mb-3">
        <h2 className="formHeading">{formName}</h2>
      </Row>

      <Row className="mt-4">
        <Col xs="12" md="4" lg="4">
          <h5 className='mt-2'>Reisebestätigung</h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            placeholder={stepResponse.Reisebestätigung ? stepResponse.Reisebestätigung : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, Reisebestätigung: event.currentTarget.files[0].name});
              uploadedFiles[`Reisebestätigung`] = event.currentTarget.files[0];
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5>Reisebeschreibung</h5>
          <p>(Katalog/Internet): </p>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            className='mt-3'
            placeholder={stepResponse.Reisebeschreibung ? stepResponse.Reisebeschreibung : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, Reisebeschreibung: event.currentTarget.files[0].name})
              uploadedFiles[`Reisebeschreibung`] = event.currentTarget.files[0];
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5  className='mt-2'>Mängelanzeige</h5>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            placeholder={stepResponse.Mängelanzeige ? stepResponse.Mängelanzeige : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, Mängelanzeige: event.currentTarget.files[0].name})
              uploadedFiles[`Mängelanzeige`] = event.currentTarget.files[0];
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="4" lg="4">
          <h5>Sonstiges</h5>
          <p><i>(z.B. Zahlungsablehnung des Reiseveranstalters sowie weitere Korrespondenz, Dokumentation des Reisemangels durch Fotos, Videos etc., Ärztliches Attest, Belege über getätigte Aufwendungen) </i></p>
        </Col>
        <Col xs="12" md="8" lg="8">
          <Form.Control
            type={ props.preview ? "text": "file" }
            disabled={disabled}
            className='mt-3'
            placeholder={stepResponse.Sonstiges ? stepResponse.Sonstiges : ''}
            onChange={(event) => {
              setStepResponse({...stepResponse, Sonstiges: event.currentTarget.files[0].name})
              uploadedFiles[`Sonstiges`] = event.currentTarget.files[0];
            }}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="12" md="12" lg="12">
          <h5>Bitte mache genaue Angaben zum Reisemangel. Gibt es weitere Informationen, die uns evtl. bei der Durchsetzung Deiner Rechte helfen können? </h5>
        </Col>
        <Col xs="12" md="12" lg="12">
          <Form.Control
            as="textarea"
            disabled={disabled}
            value={stepResponse['more_info']}
            onChange={(e) => {
              setStepResponse({...stepResponse, more_info: e.target.value });
            }}
          />
          {(
            SpeechRecognition.browserSupportsSpeechRecognition() ? (
              <div className="microPhoneButtons">
                <Button
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  disabled={disabled}
                  onClick={async () => {
                    if (isMicrophoneAvailable) {
                      if (listening) {
                        SpeechRecognition.stopListening();
                      } else {
                        SpeechRecognition.startListening({
                          language: "de-DE",
                          continuous: true,
                        });
                      }
                    }
                  }}
                  variant="outline-success"
                  disableElevation
                  className="text-uppercase"
                >
                  {listening ? <FaMicrophone /> : <FaMicrophoneSlash />}{" "}
                  {listening ? " \thalt" : " \tbeginnen"}
                </Button>
                <Button
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  disabled={disabled}
                  onClick={resetTranscript}
                  variant="outline-danger"
                  className="text-uppercase"
                  disableElevation
                >
                  zurücksetzen
                </Button>
              </div>
            ) : (
              <p style={{ color: "red" }}>
                Ihr Browser unterstützt derzeit keine Tonaufnahme.
              </p>
            )
          )}
          {(
            SpeechRecognition.browserSupportsSpeechRecognition() ? (
              isMicrophoneAvailable ? (
                <></>
              ) : (
                <p style={{ color: "red" }}>Allow access to microphone</p>
              )
            ) : (
              "Transcribe not supported in your browser"
            )
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        <div className="d-flex justify-content-end">
          <button 
            className="btn btn-primary px-3" 
            disabled={!props.preview}
            onClick={() => {
              props.downloadFiles('holidaysherpa_form_uploads', props.gcpFolderName);
            }}
            style={{
              display: props.preview ? 'block' : 'none'
            }}
          >
            Download Files
          </button>

          <button 
            className="btn btn-primary px-3" 
            disabled={disabled}
            onClick={() => {
              handleSubmit(stepResponse);
            }}
          >
            Weiter
          </button>
        </div>
      </Row>
    </Container>
  );
};

export default Form1;