import React from 'react';
import "./footer.css";

function Footer() {
  return (
    <div className='footer'
      style={{
        height: "60px",
        backgroundColor: "#3249B4",
        color: "#FFFFFF",
        fontFamily: 'Outfit',
        fontWeight: "400",
        lineHeight: "30px",
      }}
    >
      <div className='pt-1'>©2022 by LegalAI. All Rights Reserved.</div>
      <div className='d-flex pt-1'>
        <a
          className="link foot-link"
          href="https://www.legalai.io/"
          target="_blank"
        >
          LegalAI
        </a> 
        <div className="bar"></div>
        <a
          className="link foot-link"
          href="https://www.legalai.io/impressum"
          target="_blank"
        >
          Impressum
        </a>
        <div className="bar"></div>
        <a
          className="link foot-link"
          href="https://www.legalai.io/privacy"
          target="_blank"
        >
          Datenschutz & cookies
        </a>
      </div>
    </div>
  )
}

export default Footer;
