import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../../stores/ClientStore";
import ResolveElement from "../ResolveElement";

export const ResolveText = observer(
  ({ question, addSuffix, suffix, ...props }) => {
    const clientStore = useContext(ClientContext);

    const [expandNumber, setExpandNumber] = useState([]);

    const getStore = () => (props.adminStore ? props.adminStore : clientStore);

    const resolveQuestionId = () =>
      `${question.id}${addSuffix ? `-${suffix}` : ""}`;

    useEffect(() => {
      setExpandNumber(getArrayNumber);
    }, [
      getStore().currentResponses()
        ? getStore().currentResponses()[`${resolveQuestionId()}`]
        : null,
    ]);

    const getArrayNumber = () => {
      if (!question.expand) return;

      var lengthArray = Math.min(9, Number.parseInt(
        getStore().currentResponses()[question.id]
      ));

      if (isNaN(lengthArray)) return new Array(0);

      return new Array(lengthArray).fill("A");
    };

    const onChange = (event) => {
      console.log({ event });
      event.preventDefault();
      var temp = getStore().currentResponses();

      temp[`${question.id}${addSuffix ? `-${suffix}` : ""}`] =
        event.target.value.length != 0 ? event.target.value : null;

      console.log(
        temp[`${question.id}${addSuffix ? `-${suffix}` : ""}`],
        suffix,
        addSuffix,
        `${question.id}${addSuffix ? `-${suffix}` : ""}`
      );

      getStore().responses[getStore().activeTab[1].formName] = {
        ...temp,
      };
    };

    return (
      <>
        <div id={question.name}>
          <div className="red-error">
            {getStore().errorMessages[question.id] ||
              getStore().errorMessages[question.name]}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Am I getting submitted or something?");
            }}
            onSubmitCapture={(e) => e.preventDefault()}
            action="/hahaerror"
          >
            <input
              onKeyPress={(key) => {
                if (key.keyCode == 13) {
                  console.log("Enter was pressed");
                  return;
                }
              }}
              onSubmit={(e) => e.preventDefault()}
              onSubmitCapture={(e) => e.preventDefault()}
              disabled={props.disabled}
              lang="de"
              language="de"
              value={
                getStore().currentResponses()[
                `${question.id}${addSuffix ? `-${suffix}` : ""}`
                ]
              }
              onChange={onChange}
              type="text"
              style={{ width: "85%" }}
            />
          </form>

          <div className="sub-question">
            {question.expand && question.expand.enable ? (
              <div>
                {expandNumber.map((_, suffix) => {
                  return question.expand.question.map((subquestion) => {
                    return (
                      <div>
                        <ResolveElement
                          key={`elem-${question.id}-${suffix}`}
                          parentType={"question"}
                          questionId={subquestion}
                          addSuffix={true}
                          suffix={suffix}
                          adminStore={props.adminStore}
                        />
                      </div>
                    );
                  });
                })}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
);
