const { observer } = require("mobx-react-lite");

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { ClientContext } from "../../stores/ClientStore";

const EarlyEnd = observer(() => {
  const clientStore = useContext(ClientContext);

  return (
    <Container className="mt-4 container-report-box">
      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="8">
          <div
            style={{
              fontSize: "150%",
              height: "100%",
              width: "50vw",
              display: "flex",
              marginTop: "20%",
            }}
          >
            {clientStore.endMessage}
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default EarlyEnd;
