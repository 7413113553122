import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./ClientsPage.css";

const ClientsPage = observer((props) => {
  const [clientsData, setClientsData] = useState([]);
  const [fetchClient, setFetchClient] = useState(false);
  const [allFlows, setAllFlows] = useState([]);
  const [showModal, setShowModal] = useState(false);

  let [validate, setValidate] = useState(false);
  const [flowFile, setFlowFile] = useState("");
  const [domainName, setDomainName] = useState("");

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() == false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      // add data to database
      saveClientData().then((val) => {
        setFetchClient(!fetchClient);
        setShowModal(false);
        setDomainName("");
        setFlowFile("");
        setValidate(false);
      });
    }
    setValidate(true);
  };

  const saveClientData = async () => {
    let result = await fetch("api/createNewClient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domainName: domainName,
        flowFile: flowFile,
      }),
    });
    const finalResp = await result.json();
    if (finalResp.success) {
      //client saved
    } else {
      // error during saving
    }
  };

  useEffect(() => {
    const getClientData = async () => {
      const result = await fetch("/api/clients");
      const data = await result.json();
      if (data.success) {
        setClientsData(data.data);
      } else {
        setClientsData([]);
      }
    };
    getClientData();
  }, [fetchClient]);

  useEffect(() => {
    const getAllFlows = async () => {
      const result = await fetch("/api/getFlows");
      const data = await result.json();
      if (data.success) {
        setAllFlows(data.data);
      } else {
      }
    };
    getAllFlows();
  }, []);

  return (
    <div className="clients-container">
      <Container>
        <Row>
          <Col xs="12" className="mt-2">
            <Button
              className="btn btn-secondary text-white text-uppercase"
              onClick={(e) => {
                setShowModal(true);
              }}
            >
              Add New Client
            </Button>
          </Col>
          {showModal ? (
            <Col xs="12">
              <Form
                id="clientDataForm"
                noValidate
                validated={validate}
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Form.Label htmlFor="domainName">Domain Name</Form.Label>
                  <Form.Control
                    required
                    value={domainName}
                    type="text"
                    name="domainName"
                    placeholder="Enter domain name"
                    id="domainName"
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter a valid domain name
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="flowFile">Select Flow</Form.Label>
                  <Form.Select
                    value={flowFile}
                    required
                    onChange={(e) => {
                      if (e.target.value != "select") {
                        setFlowFile(e.target.value);
                      }
                    }}
                    name="flowFile"
                    id="flowFile"
                  >
                    <option value="" key={`select`} disabled>
                      Wählen ...
                    </option>
                    {allFlows.map((x) => {
                      return (
                        <option key={x.id} value={x.id}>
                          {x.id}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select a valid flow
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="secondary"
                  className="text-uppercase mt-3"
                  type="submit"
                >
                  Save Client
                </Button>
              </Form>
            </Col>
          ) : (
            <></>
          )}
          <Col xs="12" className="mt-3">
            <h5 className="text-uppercase">Clients</h5>
          </Col>
          {clientsData &&
            clientsData.map((c) => {
              return (
                <Col xs="12" md="6" className="mt-4">
                  <Card>
                    <Card.Header>
                      <Card.Title>{c.domainName}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>Flow File: {c.flowFile}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
});

export default ClientsPage;
