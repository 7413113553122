import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState, useEffect, useContext } from "react";
import { EditContext } from "../../stores/EditStore";
import "./addelement.css";
import Container from "react-bootstrap/Container";

const EditTable = observer(() => {
  const editStore = useContext(EditContext);

  useEffect(() => {
    const getFlows = async () => {
      if (editStore.tableList.length > 0) return;
      const resp = await fetch("/api/tablelist");
      const data = await resp.json();

      editStore.allTables = data.data;
    };
    getFlows();
  }, []);

  useEffect(() => {
    const getTable = async () => {
      if (editStore.currentTableId == "NEW") return;
      const resp = await fetch(`/api/table/${editStore.currentTableId}`);
      const data = await resp.json();

      editStore.currentTable = data.data;
    };
    getTable();
  }, [editStore.currentTableId]);

  const [msg, setMsg] = useState("");

  return (
    <Container className="add-element-container">
      <div>
        <select
          onChange={(e) => {
            editStore.currentTableId = e.target.value;
          }}
        >
          <option value="select">Select...</option>
          {editStore.allTables.map((subdoc, index) => {
            return (
              <option value={subdoc.id} key={index}>
                {subdoc.tableName}
              </option>
            );
          })}
        </select>

        <button
          onClick={() => {
            editStore.currentTable = {
              tableName: "New Table",
              headings: [{ name: "newheading", type: "text" }],
            };
            editStore.currentTableId = "NEW";
          }}
        >
          New Table
        </button>
        <button
          onClick={async () => {
            console.log("Saving table ...", toJS(editStore.currentTableId));

            if (editStore.currentTableId === "NEW") {
              const result = await fetch("/api/table", {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  tableName: editStore.currentTable.tableName,
                  headings: editStore.currentTable.headings,
                }),
              });
              const resp = await result.json();

              setMsg(
                resp.status || resp.success
                  ? "Subdocument saved successfully!"
                  : "Error saving the subdocument"
              );

              editStore.currentTableId = resp.data;
            } else {
              const result = await fetch(`/api/table`, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  tableId: editStore.currentTableId,
                  tableName: editStore.currentTable.tableName,
                  headings: editStore.currentTable.headings,
                }),
              });
              const resp = await result.json();

              setMsg(
                resp.status || resp.success
                  ? "Table saved successfully!"
                  : "Error saving the table"
              );
            }
          }}
        >
          Save Table
        </button>
      </div>

      <div>{msg}</div>

      <div>
        {editStore.currentTable && (
          <div>
            <div>
              <div>
                <b>Table Name:</b>
                <input
                  type="text"
                  onChange={(e) => {
                    editStore.currentTable.tableName = e.target.value;
                  }}
                  value={editStore.currentTable.tableName}
                />
                {editStore.currentTable.headings
                  ? editStore.currentTable.headings.map((x, i) => {
                    //
                    return (
                      <div
                        className="border-table-heading"
                        key={`${i}-${editStore.currentTable.tableName}`}
                      >
                        <ResolveAddElement element={x} />
                      </div>
                    );
                  })
                  : null}
              </div>
            </div>
            <div>
              <button
                onClick={(e) => {
                  if (editStore.currentTable.headings) {
                    editStore.currentTable.headings.push({
                      name: "newheading",
                      type: "text",
                    });
                  } else {
                    editStore.currentTable.headings = [
                      { name: "newheading", type: "text" },
                    ];
                  }
                }}
              >
                Add Heading
              </button>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
});

const ResolveAddElement = observer((props) => {
  // const editStore = useContext(EditContext);

  const [showSub, setSubStatus] = useState(false);
  // const [isSub, setSub] = useState(false);

  const editStore = useContext(EditContext);

  const questionTypes = [
    "Parent",
    "Text",
    "Text Area",
    "Table",
    "Dropdown",
    "Radio",
    "Checkbox",
    "Date",
    "File",
    "Comment",
    "Inline Radio",
    "Time",
    "Single Select",
    "Multi Select"
  ];

  const changeToVariable = (title) => {
    return title.replace(/[^a-zA-Z0-9]/g, "");
    //return title.toLowerCase().trim().split(" ").join("");
  };

  useEffect(() => {
    const validationObject = props.element.validation;
    if (!validationObject) {
      props.element.validation = {
        required: false,
        type: "alphanumeric",
        regex: null,
      };
    }
  }, []);

  const flowMapSection = (
    <div>
      {editStore.flowMap?.map((m) => {
        if (m.question == props.element.name) {
          return (
            <div>
              <b>Flow Mapping:</b>
              <br />
              {props.element.options.map((option, index) => {
                return (
                  <div>
                    <li>
                      {props.element.type == "checkbox"
                        ? option.value
                        : option.text}
                      :{" "}
                      <input
                        value={
                          m.flowMapping[
                          props.element.type == "checkbox"
                            ? option.value
                            : option.text
                          ]
                        }
                        onChange={(e) => {
                          m.flowMapping[
                            props.element.type == "checkbox"
                              ? option.value
                              : option.text
                          ] = e.target.value;
                        }}
                        type="text"
                      />
                    </li>
                  </div>
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );

  const validation = (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={props.element.validation?.required}
            value={props.element.validation?.required}
            onChange={() =>
            (props.element.validation.required =
              !props.element.validation.required)
            }
            name={`${props.element.name}-required`}
          />
          required?
        </label>
      </div>

      <div>
        Type:
        <select
          value={props.element.validation?.type}
          onChange={(e) => (props.element.validation.type = e.target.value)}
          name={`${props.element.name}-typeValidation`}
        >
          <option value={"numeric"}>Numeric</option>
          <option value={"alphanumeric"}>Alphanumeric</option>
          <option value={"email"}>Email</option>
          <option value={"custom"}>Custom</option>
        </select>
      </div>

      <div className="center">
        <span>Regex:</span>
        <textarea
          placeholder="regex"
          className="mt-2 ml-2"
          minLength={1}
          value={props.element.validation?.regex}
          onChange={(e) => (props.element.validation.regex = e.target.value)}
          name={`${props.element.name}-regex`}
          style={{
            height: "40px",
            minHeight: "40px",
          }}
        />
      </div>
    </div>
  );

  const header = (
    <div>
      <button
        onClick={(e) => {
          toJS(editStore.currentTable.headings);
          const removeHeadings = editStore.currentTable.headings.filter(
            (heading) => {
              toJS(heading.name), toJS(props.element.name);
              return heading.name != props.element.name;
            }
          );

          ({ removeHeadings });

          editStore.currentTable.headings = [...removeHeadings];
        }}
      >
        Delete Question
      </button>
      {editStore.flowMap &&
        editStore.flowMap.findIndex((f) => f.question == props.element.name) !=
        -1 ? (
        <button
          onClick={(e) => {
            let index = editStore.flowMap.findIndex(
              (f) => f.question == props.element.name
            );
            if (index != -1) {
              editStore.flowMap.splice(index, 1);
            }
          }}
        >
          Delete Flow Mapping
        </button>
      ) : (
        <button
          onClick={(e) => {
            editStore.flowMap.push({
              question: props.element.name,
              type: props.element.type,
              flowMapping: {},
            });
          }}
        >
          Add Flow Mapping
        </button>
      )}
      <div>
        <b>Question Title:</b>{" "}
        <input
          value={props.element.title}
          type="text"
          onChange={(e) => {
            props.element.title = e.target.value;
          }}
        />
      </div>
      <div>
        <b>Question Name:</b>{" "}
        <input
          value={props.element.name}
          type="text"
          onChange={(e) => {
            props.element.name = changeToVariable(e.target.value);
          }}
        />
      </div>
      <div>
        <b>Question Disclaimer:</b>{" "}
        <input
          value={props.element.subtext}
          type="text"
          onChange={(e) => {
            props.element.subtext = e.target.value;
          }}
        />
      </div>
      <div>
        <b>Question Type:</b>
      </div>
      <div>
        <select
          value={props.element.type}
          onChange={(e) => {
            props.element.type = e.target.value;
          }}
        >
          {questionTypes.map((x) => {
            return (
              <option value={x.toLocaleLowerCase().split(" ").join("")}>
                {x}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );

  if (
    props.element.type === "radio" ||
    props.element.type === "dropdown" ||
    props.element.type === "checkbox"
  ) {
    return (
      <div>
        <div>{flowMapSection}</div>
        <div>{validation}</div>
        <div>
          {header}
          <ul>
            {props.element.options
              ? props.element.options.map((x, i) => {
                return (
                  <div>
                    <li>
                      <input
                        type="text"
                        value={
                          props.element.options[i].text ??
                          props.element.options[i].title
                        }
                        onChange={(e) => {
                          props.element.options[i].text = e.target.value;

                          props.element.options[i].title = e.target.value;

                          props.element.options[i].value = changeToVariable(
                            e.target.value
                          );

                          props.element.options[i].name = changeToVariable(
                            e.target.value
                          );
                        }}
                      />
                      <button
                        onClick={(e) => {
                          props.element.options.splice(i, 1);
                        }}
                      >
                        X
                      </button>
                      <button
                        onClick={(e) => {
                          props.element.options[i].disabled =
                            !props.element.options[i].disabled;
                        }}
                      >
                        {props.element.options[i].disabled
                          ? "Enable"
                          : "Disable"}{" "}
                        Option
                      </button>
                    </li>
                    {showSub ? (
                      <div>
                        Subquestions:
                        <button
                          onClick={(e) => {
                            if (!props.element.options[i].triggers) {
                              props.element.options[i].triggers = [
                                {
                                  type: "addQuestions",
                                  questions: [],
                                },
                              ];
                            }
                            props.element.options[
                              i
                            ].triggers[0].questions.push({
                              title: "New Subquestion",
                              name: "newsubquestion",
                              type: "text",
                            });
                          }}
                        >
                          Add Subquestion
                        </button>
                        {props.element.options[i].triggers
                          ? props.element.options[i].triggers.map((x) => {
                            if (
                              x.type === "addQuestions" ||
                              x.type === "addQuestion"
                            ) {
                              return x.questions.map((x, i) => {
                                return (
                                  <div className="edit-card">
                                    <ResolveAddElement element={x} />
                                  </div>
                                );
                              });
                            }
                            return null;
                          })
                          : null}
                        <div>
                          <button onClick={(e) => setSubStatus(!showSub)}>
                            Toggle SubQuestion Visibility
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button onClick={(e) => setSubStatus(!showSub)}>
                        Toggle SubQuestion Visibility
                      </button>
                    )}
                  </div>
                );
              })
              : null}
          </ul>
          <button
            onClick={(e) => {
              if (props.element.options) {
                props.element.options.push({
                  value: "New",
                  text: "New",
                  name: "New",
                  title: "New",
                });
              } else {
                props.element.options = [
                  { value: "New", text: "New", title: "New" },
                ];
              }
            }}
          >
            New Option
          </button>
        </div>
      </div>
    );
  }
  if (props.element.type === "parent") {
    return (
      <div>
        <div>{validation}</div>
        <div>
          {header}
          {showSub ? (
            <div>
              Children:
              {props.element.children?.map((x) => {
                return <ResolveAddElement element={x} />;
              })}
            </div>
          ) : null}
          <div>
            <button onClick={(e) => setSubStatus(!showSub)}>
              Toggle SubQuestion Visibility
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (props.element.type === "inlineradio") {
    return (
      <div>
        <div>{flowMapSection}</div>
        <div>{validation}</div>
        <div>
          {header}
          <ul>
            {props.element.options
              ? props.element.options.map((x, i) => {
                return (
                  <div>
                    <li>
                      <input
                        type="text"
                        value={
                          props.element.options[i].text ??
                          props.element.options[i].title
                        }
                        onChange={(e) => {
                          props.element.options[i].text = e.target.value;

                          props.element.options[i].title = e.target.value;

                          props.element.options[i].value = changeToVariable(
                            e.target.value
                          );

                          props.element.options[i].name = changeToVariable(
                            e.target.value
                          );
                        }}
                      />
                      <button
                        onClick={(e) => {
                          props.element.options.splice(i, 1);
                        }}
                      >
                        X
                      </button>
                    </li>
                    {showSub ? (
                      <div>
                        Subquestions:
                        <button
                          onClick={(e) => {
                            if (!props.element.options[i].triggers) {
                              props.element.options[i].triggers = [
                                {
                                  type: "addQuestions",
                                  questions: [],
                                },
                              ];
                            }
                            props.element.options[
                              i
                            ].triggers[0].questions.push({
                              title: "New Subquestion",
                              name: "newsubquestion",
                              type: "text",
                            });
                          }}
                        >
                          Add Subquestion
                        </button>
                        {props.element.options[i].triggers
                          ? props.element.options[i].triggers.map((x) => {
                            if (
                              x.type === "addQuestions" ||
                              x.type === "addQuestion"
                            ) {
                              return x.questions.map((x, i) => {
                                return (
                                  <div className="edit-card">
                                    <ResolveAddElement element={x} />
                                  </div>
                                );
                              });
                            }
                            return null;
                          })
                          : null}
                        <div>
                          <button onClick={(e) => setSubStatus(!showSub)}>
                            Toggle SubQuestion Visibility
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button onClick={(e) => setSubStatus(!showSub)}>
                        Toggle SubQuestion Visibility
                      </button>
                    )}
                  </div>
                );
              })
              : null}
          </ul>
          <button
            onClick={(e) => {
              if (props.element.options) {
                props.element.options.push({
                  value: "New",
                  text: "New",
                  name: "New",
                  title: "New",
                });
              } else {
                props.element.options = [
                  { value: "New", text: "New", title: "New" },
                ];
              }
            }}
          >
            New Option
          </button>
        </div>
      </div>
    );
  }
  if (props.element.type === "table") {
    return (
      <div>
        <div>{validation}</div>
        <div>
          {header}
          <div>
            <b>Headings:</b>
            {props.element.headings
              ? props.element.headings.map((x, i) => {
                return (
                  <div key={`${i}-${x.name}-${props.element.name}`}>
                    <ResolveAddElement element={x} />
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div>
          <button
            onClick={(e) => {
              if (props.element.headings) {
                props.element.headings.push({
                  name: "newheading",
                  type: "text",
                });
              } else {
                props.element.headings = [{ name: "newheading", type: "text" }];
              }
            }}
          >
            Add Heading
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>{validation}</div>
        <div>
          {header}
          <input
            type={`${props.element.type}`}
            placeholder={`${props.element.type}`}
            disabled
          />
        </div>
      </div>
    );
  }
});

export default EditTable;
