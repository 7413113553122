import { useState } from "react";
import ReactLoading from "react-loading";
import { Button } from "@material-ui/core";

export const fetchData = async (url, options) => {
  try {
    const result = await fetch(url, options);
    const resp = await result.json();
    return [resp.data, null];
  } catch (err) {
    return [null, err];
  }
};

export const ButtonFetch = (props) => {
  /*
  0 => init
  1 => success
  2 => failure
  */

  const {
    fetchUrl,
    fetchOptions,
    successMessage = "Success",
    failMessage = "Failed",
    initMessage,
    customFunction,
  } = props;

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    var resp;
    if (customFunction) {
      resp = await customFunction();
    } else {
      const result = await fetch(fetchUrl, fetchOptions);
      resp = await result.json();
    }

    if (resp.success || resp.status) {
      setStatus(1);
    } else {
      setStatus(2);
    }

    setLoading(false);
  };

  const resolveMessage = () => {
    if (status == 0) return initMessage;
    if (status == 1) return successMessage;
    if (status == 2) return failMessage;
  };

  const loader = () => {
    return <ReactLoading type="spin" color="black" height="20" width="20" />;
  };

  return (
    <Button
      onClick={fetchData}
      size="medium"
      variant="contained"
      color="primary"
      disableElevation
    >
      {loading && loader() && "Loading..."}
      {!loading && resolveMessage()}
    </Button>
  );
};

export const PrimaryButton = ({ props, onClick, disabled, children }) => {
  return (
    <Button
      style={{
        backgroundColor: !disabled ? "#3249B4" : "offwhite",
        color: "white",
        margin: "10px 0"
      }}
      className="text-uppercase"
      variant="primary"
      onClick={onClick}
      onSubmit={(e) => e.preventDefault()}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton = ({ props, onClick, disabled, children }) => {
  return (
    <Button
      style={{
        backgroundColor: disabled ? "#e4e4e4" : "offwhite",
        border: "1px solid black",
        color: "black",
      }}
      className="text-uppercase"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
