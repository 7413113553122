import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../stores/AdminStore";
import "./displayWebResponses.css";
import { observer } from "mobx-react-lite";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineUser } from "react-icons/ai";
import firebase from "../../Utils/firebase";
import HolidaySherpaForm from "../HolidaySherpaForm";

export const DisplayWebResponses = observer(() => {
  const adminStore = useContext(AdminContext);
  const id = adminStore.currentCase.id;
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [showCase, setShowCase] = useState(false);
  const [showForms, setShowForms] = useState(false);
  const [responses, setResponses] = useState({});
  const [caseVariable, setCaseVariable] = useState({ variableName: '', variableValue: '' });

  useEffect(()=>{
    const fetchComments = async() => {
      try {
        const receive = await fetch(`/api/admin/comment/${id}`);
        const data = await receive.json();
        setComments(data.data);
      } catch(err) {
        console.log(err);
      }
    };

    const fetchFormResponses = async() => {
      try {
        const receive = await fetch(`/api/admin/formResponses/${id}`);
        const data = await receive.json();
        console.log('aaya response ', data);
        setResponses(data.data);
      } catch(err) {
        console.log(err);
      }
    };

    fetchComments();
    fetchFormResponses();
  }, []);

  const toggleShowCase = () => {
    if(showCase){
      setShowCase(false);
    } else {
      setShowCase(true);
    }
  };

  const toggleShowForms = () => {
    if(showForms){
      setShowForms(false);
    } else {
      setShowForms(true);
    }
  };

  const sendComment = async() => {
    let message = comment;
    setComment("");
    const milliseconds = new Date().getTime();
    let messageData = {
      comment: message,
      user: firebase.auth().currentUser.email,
      timestamp: {
        _seconds: Math.round(milliseconds/1000)
      }
    };
    comments.push(messageData);
    setComments(comments);

    try {
      const send = await fetch('/api/admin/comment', {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          comment: message,
          user: firebase.auth().currentUser.email,
          caseId: id
        })
      });
      const result = await send.json();
    } catch(err) {
      console.log(err);
    }
  };

  const getDateInFormate = (timestamp) => {
    let milliseconds = timestamp?._seconds * 1000;
    let date = new Date(milliseconds);
    let mm = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    return `${hh}:${min} ${dd}-${mm}-${date.getFullYear()}`;
  }
  
  const downloadFiles = async(folderName, id) => {
    const result = await fetch(`/api/admin/downloadFiles/${folderName}/${id}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    const blob = await result.blob();
    download(blob, id);
  };

  function download(data, id) {
    const url = URL.createObjectURL(new Blob([data]));
    const a = document.createElement('a');
    a.download = `${id}.zip`;
    a.href = url;
    a.target = '_self';
  
    a.click();
  
    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  };

  const updateStatus = async (status) => {
    const result = await fetch("/api/admin/updateStatus", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id : id,
        dest: 'wordpress',
        status : status
      }),
    });
  };

  const addCaseVariable = async() => {
    try{
      const result = await fetch("/api/holidaysherpa/caseVariable", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          caseId : id,
          variableName: caseVariable.variableName,
          variableValue: caseVariable.variableValue,
        }),
      });
      const resp = await result.json();
      if(resp.success) {
        setCaseVariable({ variableName: '', variableValue: '' });
      }
    } catch(err) {
      console.log(err);
    }
  };

  const renderResponse = (response)=>{
    if(typeof response == 'string'){
      return response;
    } else {
      return <ul>
      {
        Object.values(response).map((option)=>{
          return <li>{option}</li>
        })
      }
      </ul>;
    }
  }

  return (
    <Container>
      <Row className="my-4" id="show-case">
        <div className="d-flex">
          <button className="btn btn-primary" 
            onClick={()=> toggleShowCase() }
          >
            { showCase ? "Hide Case" : "Show Case"}
          </button>

          <button className="btn btn-primary" 
            onClick={()=> toggleShowForms() }
          >
            { showForms ? "Hide Forms" : "Show Forms"}
          </button>
        </div>
      </Row>

      <Row className="my-4">
        <div className="d-flex flex-wrap">
          <input type="text" 
            value={caseVariable.variableName}
            onChange={(e) => setCaseVariable({...caseVariable, variableName: e.target.value })}
            style={{
              width: '240px'
            }}
          />
          <input type="text" 
            value={caseVariable.variableValue}
            onChange={(e) => setCaseVariable({...caseVariable, variableValue: e.target.value })}
            style={{
              width: '240px'
            }}
          />
          <button className="btn btn-primary" 
            onClick={()=> addCaseVariable() }
            style={{
              width: '240px'
            }}
          >
            Add Case Variable
          </button>
        </div>
      </Row>

      {
        showCase ? (
          <Row className="justify-content-center" id="currentCase" style={{ width: '100%' }}>
            <Col xs="12" md="12" lg="12">
              {
                Object.keys(adminStore.currentCase).map((question)=>{
                  if(['id', 'timestamp', 'status', 'contractStatus', 'formStatus', 'communicationStatus', 'caseVariables'].includes(question))
                    return <></>;
                  return <div>
                    <div className="questionText">{question}</div>
                    <div>
                      <label className="mb-3">
                        { renderResponse(adminStore.currentCase[question]) }
                      </label>
                    </div>
                  </div>
                })
              }
            </Col>
          </Row>
        ) : <></>
      }

      {
        showForms ? <HolidaySherpaForm preview={true} responses={responses} downloadFiles={downloadFiles} caseId={id} /> : <></>
      }

      <Row className="my-4" id="case-comments">
        <h3 className="mb-4"><b> { comments.length != 0 ? 'Comments' : 'No Comments Yet' } </b></h3>
        {
          comments.map((comm) => {
            return (
              <div className="comment-box mx-3 pt-3 pb-0 mb-2">
                <div className="d-flex mb-0">
                  <AiOutlineUser/>
                  <h5 className="px-2">{comm.user}</h5>
                </div>
                <p className="ps-4 pb-0 mb-0">{comm.comment}</p>
                <div className="d-flex flex-row-reverse m-0 p-0">
                  <span className="timestamp m-0 pb-1">{ getDateInFormate(comm.timestamp) }</span>
                </div>
              </div>
            );
          })
        }
      </Row>


      <Row className="my-4" id="post-comment">
        <h3 className="mb-4"><b>Post a new Comment</b></h3>
        <textarea 
          type="textarea"
          rows="4" 
          cols="50"
          className="mb-2"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
          }}
        />
        <div className="d-flex flex-row-reverse">
          <button className="btn btn-primary" 
            onClick={()=> sendComment()}
          >
            Post
          </button>
        </div>
      </Row>

      <Row className="my-4" id="case-features">
        <Col xs="12" md="6" lg="6">
          <div className="d-flex">
            <button className="btn btn-primary" 
              onClick={() => window.print() }
            >
              Download Case
            </button>
            <button className="btn btn-primary" 
              onClick={() => {
                downloadFiles( 'wordpress_uploads', id);
              }}
              disabled
            >
              Download Files
            </button>
          </div>
        </Col>
        <Col xs="12" md="6" lg="6">
          <div className="d-flex flex-row-reverse">
            <button className="btn btn-primary" 
              onClick={()=> updateStatus('Resolve')}
            >
              Resolve
            </button>
            <button className="btn btn-primary" 
              onClick={()=> updateStatus('In Progress')}
            >
              In Progress
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
});