import { useLocalStore } from "mobx-react-lite";
import { createContext } from "react";

export const createAdminStore = () => {
  return {
    currentCase: {}, // selected case from caseList
    currentCaseID: "", // id of currentCase - currentCase.id
    caseList: [], // list of all the documents in "responses" collection
    currentReport: {},
    responses: {},
    errorMessages: {},

    currentResponses() {
      return this.responses;
    },
  };
};

export const AdminContext = createContext(null);

export const AdminProvider = ({ children }) => {
  const adminStore = useLocalStore(createAdminStore);

  return (
    <AdminContext.Provider value={adminStore}>{children}</AdminContext.Provider>
  );
};
