import "../compstyle.css";
import "./report.css";
import NERDisplay from "./NERDisplay";
import { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../stores/DocumentStore";
import { observer } from "mobx-react-lite";
import { AdminContext } from "../../stores/AdminStore";
import { useHistory } from "react-router-dom";
import { toJS } from "mobx";

import ReactLoading from "react-loading";

import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Report = observer((props) => {
  const documentStore = useContext(DocumentContext);
  const adminStore = useContext(AdminContext);

  useEffect(() => {}, []);

  const sliderBox = (perc) => {
    var stylebar = {
      width: "100%",
      height: "5px",
      backgroundColor: "grey",
    };

    var stylepoint = {
      height: "5px",
      width: `${perc}%`,
      backgroundColor: "skyblue",
      zIndex: 1,
    };

    return (
      <div>
        <div style={stylebar}>
          <div style={stylepoint}></div>
        </div>
      </div>
    );
  };

  const [docView, setDoc] = useState(false);
  const [showResp, setResp] = useState(false);

  const history = useHistory();

  if (adminStore.currentCase) {
    return (
      <>
        <Row className="justify-content-between mt-3">
          <Col xs="12" md="8" lg="9">
            <h5>
              <b>Prognose zum Ausgang des Rechtsstreits</b>
            </h5>
          </Col>
          <Col xs="12" md="4" lg="3">
            <Button
              variant="secondary"
              onClick={() => {
                history.push(
                  `/preview/vS1sQhoiqktMM9NqwZkv26`
                );
              }}
            >
              {`Schriftsatz generieren`}
            </Button>
          </Col>
        </Row>

        <>
          <Row className="m-1 mt-3">
            <Col xs="12" md="6" className="report-box p-2">
              <h6>
                <b>Prognostizierter Ausgang</b>
              </h6>
              <p>
                Compensation Amount:{" "}
                {(() => {
                  if (adminStore.currentCase && adminStore.currentCase.report) {
                    if (!adminStore.currentCase.report.empty) {
                      return adminStore.currentCase.report.compensationAmount;
                    }
                    return adminStore.currentCase.responses.compensationAmount;
                  }
                })()}{" "}
                Euros
              </p>
            </Col>
            <Col xs="12" md="6" className="report-box p-2">
              <h6>
                <b>Zuverlässigkeit der Prognose</b>
              </h6>
              <p>
                {(() => {
                  if (adminStore.currentCase && adminStore.currentCase.report) {
                    if (!adminStore.currentCase.report.empty) {
                      return adminStore.currentCase.report.predictionAccuracy;
                    }
                    return adminStore.currentCase.responses.predictionAccuracy;
                  }
                })()}
                %
              </p>
              <div>{sliderBox(80)}</div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs="12">
              <h5>
                <b>Ähnlich gelagerte Gerichtsfälle</b>
              </h5>
            </Col>
            <Col xs="12" className="mt-2">
              <Table striped bordered hover size="lg" variant="light">
                <thead>
                  <tr>
                    <th>Gerichtsentscheidung</th>
                    <th>Reisemangel</th>
                    <th>Minderungsquote</th>
                    <th>Ähnlichkeitsfaktor</th>
                  </tr>
                </thead>
                <tbody>
                  {props.similarCases ? (
                    props.similarCases.map((x) => {
                      return (
                        <tr>
                          <td>{x.juris}</td>
                          <td>{x.summary}</td>
                          <td>{x.reductionRate}</td>
                          <td>{sliderBox(x.factors)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <ReactLoading type="spokes" colors="black" />
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          <NERDisplay />
        </>
      </>
    );
  } else {
    return <div>Select a case</div>;
  }
});

export default Report;
