import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useState, useEffect, useContext } from "react";
import { EditContext } from "../../stores/EditStore";

import "./addelement.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ReactLoading from "react-loading";

import { ButtonFetch, fetchData } from "../../Utils/Helper";

import Select from "react-select";

import {
  BsChevronCompactDown,
  BsChevronCompactUp,
  MdDelete,
} from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { CacheContext } from "../../stores/CacheStore";
import { DragList } from "../../Utils/DragList";

const AddElement = observer((props) => {
  const editStore = useContext(EditContext);
  const cacheStore = useContext(CacheContext);
  const history = useHistory();

  const { formId } = useParams();

  const [refresh, setRefresh] = useState(false);

  editStore.formId = formId;

  useEffect(() => {
    const fetchForm = async () => {
      const result = await fetch(`/flowapi/form/${formId}`);
      const resp = await result.json();
      editStore.elements = resp.data.questionList ?? [];
      editStore.formName = resp.data.formName;
    };
    fetchForm();
  }, [refresh]);

  const [reorder, setReorder] = useState(false);

  return (
    <Container className="add-element-container">
      <input
        className="header-input custom-margin"
        value={editStore.formName}
        onChange={(e) => (editStore.formName = e.target.value)}
      />
      <div>
        {editStore.elements != [] ? (
          <>
            <Button
              size="sm"
              variant="secondary"
              onClick={async (e) => {
                const resp = await fetch(`/flowapi/question`, {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    formId: editStore.formId,
                  }),
                });

                const data = await resp.json();

                cacheStore.updateElementsCached(editStore.formId, data.data);

                setRefresh(!refresh);
              }}
            >
              New Question
            </Button>
            <Button
              onClick={(e) => {
                history.push("/preview");
              }}
              size="sm"
              variant="secondary"
            >
              Preview Form
            </Button>

            <ButtonFetch
              fetchUrl={`/flowapi/form/elementlist`}
              fetchOptions={{
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  formId: `${editStore.formId}`,
                  formName: editStore.formName,
                  elements: [...editStore.elements],
                }),
              }}
              successMessage={`Form Saved`}
              failMessage={`Save Error`}
              initMessage={`Save Form`}
            />
            <div>
              <Button
                onClick={(e) => {
                  setReorder(!reorder);
                }}
              >
                {reorder ? "Save" : "Reorder"}
              </Button>
            </div>
            {reorder ? (
              <DragList
                list={editStore.elements}
                setList={(newList) => (editStore.elements = [...newList])}
                resolver={(e, i) => {
                  return (
                    <ResolveAddElement
                      questionId={e}
                      parent={editStore.formId}
                      parentType={"form"}
                      setParentRefresh={setRefresh}
                      disabled={true}
                    />
                  );
                }}
              />
            ) : (
              editStore.elements.map((e, i) => {
                return (
                  <ResolveAddElement
                    questionId={e}
                    parent={editStore.formId}
                    parentType={"form"}
                    setParentRefresh={setRefresh}
                  />
                );
              })
            )}
          </>
        ) : (
          <div>
            Click on
            <Button>New Question</Button>
            to Continue.
          </div>
        )}
      </div>
    </Container>
  );
});

const ResolveAddElement = observer((props) => {
  const [question, setQuestion] = useState({});
  const [tableList, setTableList] = useState([]);

  const [showValidation, setShowValidation] = useState(false);
  const [error, setError] = useState(false);

  const [listAllQuestions, setAllQ] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const editStore = useContext(EditContext);
  const cacheStore = useContext(CacheContext);

  const [copied, setCopied] = useState(false);

  const { setParentRefresh } = props;

  const [showFlows, setShowFlows] = useState({
    append: false,
    modify: false,
    add: false,
  });

  useEffect(() => {
    const listFetcher = async () => {
      const result = await fetch("/flowapi/formlist");
      const resp = await result.json();

      editStore.allFlows = [...resp.data];
    };

    if (editStore.allFlows.length == 0) {
      listFetcher();
    }
  }, []);

  useEffect(() => {
    const getQuestion = async () => {
      const resp = await fetch(`/flowapi/question/${props.questionId}`);

      const json = await resp.json();
      if (props.questionId == "o7uAemhL6zZyBhdsGa2M5D") {
      }

      if (json.success || json.status) {
        cacheStore.updateQuestionCached(props.questionId, json.data);
        var temp = { ...json.data };
        temp.copyId = null;
        setQuestion({ ...temp });
      } else {
        setError(true);
      }
      if (json.data.options) {
        setExpand(json.data.options.map((x) => false));
      }
    };

    if (!cacheStore.questionCached(props.questionId)) {
      getQuestion();
    } else {
      setQuestion({ ...cacheStore.questionCached(props.questionId) });
    }
  }, [props.questionId, refresh]);

  useEffect(() => {
    const fetchTableList = async () => {
      if (!(editStore.tableList.length > 0)) {
        const resp = await fetch(`/api/tablelist`);
        const json = await resp.json();

        editStore.tableList = [...json.data];
      }

      setTableList([...editStore.tableList]);
    };

    fetchTableList();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!(editStore.variablesNew.length > 0)) {
        const [data, err] = await fetchData("/flowapi/getAllVariables");

        if (err) return;

        editStore.variablesNew = [...data];
      }

      setAllQ([...editStore.variablesNew]);
    };

    fetchQuestions();
  }, []);

  const [expandOption, setExpand] = useState([]);

  const onClickFunctions = {
    deleteQuestion: async (e) => {
      const res = await fetch(`/flowapi/question`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          questionId: props.questionId,
          parent: props.parent,
          parentOption: props.parentOption,
          parentType: props.parentType,
        }),
      });

      const result = await res.json();

      setParentRefresh(false);
      setParentRefresh(true);
    },
    addOption: (e) => {
      var temp = { ...question };

      if (!temp.options) {
        temp.options = [];
      }

      temp.options.push({
        text: "Enter option text...",
        hide: false,
        disabled: false,
      });

      cacheStore.updateQuestionCached(question.id, { ...temp });

      setQuestion({ ...temp });
    },
    addSubquestion: async (e, i) => {
      const res = await fetch(`/flowapi/subquestion`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          optionText: i != -1 ? question.options[i].text : null,
          questionId: question.id,
          currentQuestion: question,
          subQuestion: {
            text: "New Question",
            name: "newQuestion",
            type: "text",
          },
        }),
      });

      const updatedQuestion = await res.json();

      cacheStore.updateQuestionCached(question.id, updatedQuestion.data);

      setRefresh(!refresh);

      setParentRefresh(true);
      setParentRefresh(false);
    },
    deleteOption: (e, i) => {
      var temp = { ...question };
      temp.options.splice(i, 1);

      cacheStore.updateQuestionCached(question.id, {
        ...temp,
      });
      setQuestion({ ...temp });
      setRefresh(!refresh);
    },
  };

  const questionTypes = [
    "Parent",
    "Text",
    "Text Area",
    "Table",
    "Dropdown",
    "Radio",
    "Checkbox",
    "Date",
    "File",
    "Comment",
    "Inline Radio",
    "Time",
    "DateTime",
    "Single Select",
    "Multi Select",
    //uc_heading
    "Heading"
  ];

  const [reorder, setReorder] = useState(false);

  const flowChangeMap = (title, change) => {
    return (
      <div className="change-map">
        {question.options?.map((x) => {
          return (
            <div className="change-map-option">
              <span>{x.text}</span>
              <select
                value={question[change] ? question[change][x.text] : "select"}
                onChange={async (e) => {
                  if (e.target.value === "select") return;

                  var temp = { ...question };

                  if (!temp[change]) {
                    temp[change] = {};
                  }

                  temp[change][x.text] = e.target.value;

                  cacheStore.updateQuestionCached(question.id, {
                    ...temp,
                  });

                  setQuestion({ ...temp });
                }}
                className="resolve-element-select margin-plz"
              >
                <option value={"select"}>Select</option>
                {editStore.allFlows?.map((x, i) => {
                  return (
                    <option value={x.id} key={i}>
                      {x.formName}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
      </div>
    );
  };

  const saveBar = (
    <div>
      {process.env.REACT_APP_DEBUG ? question.id : ""}

      <ButtonFetch
        fetchUrl={`/flowapi/question`}
        fetchOptions={{
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            questionId: props.questionId,
            question: question,
          }),
        }}
        successMessage="Saved"
        failMessage="Error Saving"
        initMessage="Save Question"
      />
      <Button
        variant="light"
        size="sm"
        onClick={onClickFunctions.deleteQuestion}
      >
        Delete Question
      </Button>
    </div>
  );

  const header = (
    <div>
      {saveBar}
      <div>
        Type:{" "}
        <select
          className="resolve-element-select"
          onChange={async (e) => {
            var temp = { ...question };
            temp.type = e.target.value;

            cacheStore.updateQuestionCached(question.id, { ...temp });
            setQuestion({ ...temp });
          }}
          value={question.type}
        >
          <option value="select">Select type ...</option>
          {questionTypes?.map((x, i) => {
            return (
              <option value={x.toLowerCase().split(" ").join("")} key={`${i}`}>
                {x}
              </option>
            );
          })}
        </select>
      </div>
      <div className="resolve-element-questiontext">
        <input
          type="text"
          value={question.text}
          className="question-text-input"
          placeholder="Enter Question Text"
          onChange={(e) => {
            const temp = { ...question };
            temp.text = e.target.value;

            cacheStore.updateQuestionCached(question.id, { ...temp });
            setQuestion({ ...temp });
          }}
        />
      </div>

      <input
        type="text"
        placeholder="Enter Question Name"
        value={question.name ?? "Question Name..."}
        className="question-name-input"
        onChange={(e) => {
          const temp = { ...question };
          temp.name = e.target.value;

          cacheStore.updateQuestionCached(question.id, { ...temp });
          setQuestion({ ...temp });
        }}
      />
      <div>
        <input
          type="text"
          placeholder="Disclaimer"
          value={question.disclaimer}
          className="question-name-input"
          onChange={(e) => {
            const temp = { ...question };
            temp.disclaimer = e.target.value;

            cacheStore.updateQuestionCached(question.id, { ...temp });
            setQuestion({ ...temp });
          }}
        />
      </div>
      <div>
        <input
          type="checkbox"
          checked={showFlows.append}
          onChange={(e) => {
            var temp = { ...showFlows };
            temp.append = !temp.append;
            setShowFlows({ ...temp });
          }}
        />{" "}
        Append to this flow
      </div>
      {showFlows.append ? flowChangeMap("Append", "appendFlow") : <></>}
      <div>
        <input
          type="checkbox"
          checked={showFlows.modify}
          onChange={(e) => {
            var temp = { ...showFlows };
            temp.modify = !temp.modify;
            setShowFlows({ ...temp });
          }}
        />{" "}
        Modify this flow
      </div>
      {showFlows.modify ? flowChangeMap("Modify", "modifyFlow") : <></>}
      <div>
        <input
          type="checkbox"
          checked={showFlows.add}
          onChange={(e) => {
            var temp = { ...showFlows };
            temp.add = !temp.add;
            setShowFlows({ ...temp });
          }}
        />{" "}
        Add new flow
      </div>
      {showFlows.add ? flowChangeMap("Add", "addFlow") : <></>}
    </div>
  );

  const validation = (
    <div>
      {showValidation ? (
        <BsChevronCompactUp
          onClick={(e) => {
            setShowValidation(!showValidation);
          }}
        />
      ) : (
        <BsChevronCompactDown
          onClick={(e) => {
            setShowValidation(!showValidation);
          }}
        />
      )}
      <span style={{ marginLeft: "20px" }}>Validation</span>
      <div>
        {showValidation ? (
          <div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={question.validation?.required}
                  value={question.validation?.required}
                  onChange={(e) => {
                    var temp = { ...question };
                    if (!temp.validation) {
                      temp.validation = {
                        required: false,
                        type: "Custom",
                        regex: "",
                      };
                    }
                    temp.validation.required = !temp.validation.required;

                    setQuestion({ ...temp });
                  }}
                  name={`${question.name}-required`}
                />
                required?
              </label>
            </div>

            <div>
              Type:
              <select
                className="resolve-element-select"
                value={question.validation?.type}
                onChange={(e) => {
                  var temp = question;

                  if (!temp.validation) {
                    temp.validation = {
                      required: false,
                      type: "Custom",
                      regex: "",
                    };
                  }
                  temp.validation.type = e.target.value;

                  setQuestion({ ...temp });
                }}
                name={`${question.name}-typeValidation`}
              >
                <option value={"numeric"}>Numeric</option>
                <option value={"alphanumeric"}>Alphanumeric</option>
                <option value={"email"}>Email</option>
                <option value={"custom"}>Custom</option>
              </select>
            </div>

            <div className="center">
              <span>Regex:</span>
              <textarea
                placeholder="regex"
                minLength={1}
                value={question.validation?.regex}
                onChange={(e) => {
                  var temp = { ...question };
                  if (!temp.validation) {
                    temp.validation = {
                      required: false,
                      type: "Custom",
                      regex: "",
                    };
                  }
                  temp.validation.regex = e.target.value;

                  setQuestion({ ...temp });
                }}
                name={`${question.name}-regex`}
                style={{
                  height: "40px",
                  minHeight: "40px",
                }}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );

  const mainElement = () => {
    var element = <div></div>;
    if (question.type == "text") {
      element = (
        <div>
          <input type="text" placeholder="Text Box" disabled />
        </div>
      );
    }

    if (
      ["radio", "inlineradio", "checkbox", "dropdown", "singleselect", "multiselect"].includes(question.type)
    ) {
      element = (
        <div>
          {question.options?.map((x, i) => {
            return (
              <div>
                <li className="editform-option">
                  {expandOption[i] ? (
                    <BsChevronCompactUp
                      onClick={(e) => {
                        var temp = [...expandOption];
                        temp[i] = !temp[i];
                        setExpand([...temp]);
                      }}
                    />
                  ) : (
                    <BsChevronCompactDown
                      onClick={(e) => {
                        var temp = [...expandOption];
                        temp[i] = !temp[i];
                        setExpand([...temp]);
                      }}
                    />
                  )}
                  <input
                    className="editform-option-text"
                    onChange={(e) => {
                      const temp = { ...question };
                      temp.options[i].text = e.target.value;
                      temp.options[i].value = e.target.value;

                      cacheStore.updateQuestionCached(question.id, {
                        ...temp,
                      });
                      setQuestion({ ...temp });
                    }}
                    value={
                      question.options[i].text ??
                      question.options[i].title ??
                      "Option Value..."
                    }
                  />

                  <div>
                    <button
                      className="editform-button"
                      onClick={(e) => {
                        var temp = { ...question };

                        temp.options[i].disabled = !temp.options[i].disabled;

                        setQuestion({ ...temp });
                      }}
                    >
                      {question.options[i].disabled ? "Enable" : "Disable"}
                    </button>
                    <button
                      className="editform-button"
                      onClick={(e) => {
                        var temp = { ...question };

                        temp.options[i].hide = !temp.options[i].hide;

                        setQuestion({ ...temp });
                      }}
                    >
                      {question.options[i].hide ? "Show" : "Hide"}
                    </button>
                    <button
                      className="editform-button"
                      onClick={(e) => onClickFunctions.deleteOption(e, i)}
                    >
                      X
                    </button>
                  </div>
                </li>
                <div>
                  {expandOption[i] ? (
                    <div>
                      <div>
                        <Button
                          onClick={(e) => {
                            setReorder(!reorder);
                          }}
                        >
                          {reorder ? "Save" : "Reorder"}
                        </Button>
                        {reorder ? (
                          <DragList
                            list={question.options[i].subquestions}
                            setList={(newList) => {
                              var temp = question;
                              temp.options[i]["subquestions"] = [...newList];
                              setQuestion({ ...temp });
                            }}
                            resolver={(e, i) => {
                              return (
                                <ResolveAddElement
                                  questionId={e}
                                  parent={question.id}
                                  parentType={"parent"}
                                  disabled={true}
                                  setParentRefresh={setParentRefresh}
                                />
                              );
                            }}
                          />
                        ) : (
                          question.options[i].subquestions?.map((x, y) => {
                            return (
                              <ResolveAddElement
                                questionId={x}
                                parent={question.id}
                                parentOption={i}
                                setParentRefresh={setParentRefresh}
                              />
                            );
                          })
                        )}
                      </div>
                      <Button
                        variant="light"
                        size="sm"
                        onClick={(e) => onClickFunctions.addSubquestion(e, i)}
                      >
                        Add Subquestion
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
          <Button size="sm" onClick={onClickFunctions.addOption}>
            Add Option
          </Button>
        </div>
      );
    }

    if (question.type == "parent") {
      element = (
        <div>
          <Button
            onClick={(e) => {
              setReorder(!reorder);
            }}
          >
            {reorder ? "Save" : "Reorder"}
          </Button>
          {reorder ? (
            <DragList
              list={question.children ?? []}
              setList={(newList) => {
                var temp = question;
                temp["children"] = [...newList];
                setQuestion({ ...temp });
              }}
              resolver={(e, i) => {
                return (
                  <ResolveAddElement
                    questionId={e}
                    parent={question.id}
                    parentType={"parent"}
                    disabled={true}
                    setParentRefresh={setParentRefresh}
                  />
                );
              }}
            />
          ) : (
            (question.children ?? []).map((e, i) => {
              return (
                <ResolveAddElement
                  questionId={e}
                  parent={question.id}
                  parentOption={i}
                  parentType={"parent"}
                  setParentRefresh={setParentRefresh}
                />
              );
            })
          )}
          <Button
            variant="light"
            size="sm"
            onClick={(e) => onClickFunctions.addSubquestion(e, -1)}
          >
            Add Subquestion
          </Button>
        </div>
      );
    }

    if (question.type == "table") {
      element = (
        <div>
          Table Template:
          <select
            value={question.tableId}
            onChange={(e) => {
              var temp = question;
              temp.tableId = e.target.value;
              setQuestion({ ...temp });
            }}
          >
            <option value={null}>Select...</option>
            {tableList?.map((table, index) => {
              return (
                <option value={table.id} key={index}>
                  {table.tableName}
                </option>
              );
            })}
          </select>
        </div>
      );
    }

    return element;
  };

  const copyQuestion = () => {
    return (
      <div>
        {saveBar}
        <Select
          value={question.copyId}
          onChange={async (e) => {
            var temp = { ...question };
            temp.copyId = e;
            cacheStore.updateQuestionCached(question.id, { ...temp });
            setQuestion({ ...temp });
          }}
          options={listAllQuestions.map((x) => {
            return { value: x.id ?? "Select.", label: x.name+ "   HASH "+x.id ?? "Select." };
          })}
        />
      </div>
    );
  };

  return error == false ? (
    !question.id ? (
      <div className="full-size-center resolve-element-card">
        <ReactLoading type="spokes" color="black" />
      </div>
    ) : (
      <div className="resolve-element-card">
        <input
          type="checkbox"
          checked={copied}
          onChange={(e) => setCopied(!copied)}
        />{" "}
        Copy Question
        <div>
          {copied ? (
            copyQuestion()
          ) : (
            <div>
              {" "}
              {header}
              {!props.disabled ? (
                <>
                  {validation} {mainElement()}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    )
  ) : (
    <div className="resolve-element-card">Error Loading Question.</div>
  );
});

export default AddElement;
