import firebase from "firebase/app";
import 'firebase/auth'; 

var config = {
  apiKey: "AIzaSyAcad60BRzVdpLwzpn9ymjtaxHVFWBNEl0",
  authDomain: "authentication.holidaysherpa.de",
};

firebase.initializeApp(config);

export default firebase;
