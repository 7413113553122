import { v4 as uuid } from "uuid";
import { Editor, Text, Transforms } from "slate";

const PREFIX = "condition_";

export function getMarkForCondition(conditionId) {
  return `${PREFIX}${conditionId}`;
}

export const getConditionsOnTextNode = (textNode) => {
  return new Set(
    Object.keys(textNode).filter(isConditionIDMark).map(getConditionIDFromMark)
  );
};

export const getConditionIDFromMark = (mark) => {
  if (!isConditionIDMark(mark)) {
    throw new Error("...");
  }

  return mark.replace(PREFIX, "");
};

export const isConditionIDMark = (thread) => {
  return thread.indexOf(PREFIX) === 0;
};

export const insertConditionThread = (editor, addConditionThreadToState) => {
  const conditionID = uuid();

  const newConditionThread = {
    conditions: [
      {
        modifier: "equal",
        operator1: "select...",
        operator2: "select...",
      },
    ],
  };

  addConditionThreadToState(conditionID, newConditionThread);
  Editor.addMark(editor, getMarkForCondition(conditionID), true);
  return conditionID;
};

export const getSmallestConditionThreadAtTextNode = (editor, textNode) => {
  const conditionThreads = getConditionsOnTextNode(textNode);

  const conditionThreadAsArray = [...conditionThreads];

  let shortestConditionThreadID = conditionThreadAsArray[0];

  const reverseTextNodeIterator = (slateEditor, nodePath) =>
    Editor.previous(slateEditor, {
      at: nodePath,
      mode: "lowest",
      match: Text.isText,
    });

  const forwardTextNodeIterator = (slateEditor, nodePath) =>
    Editor.next(slateEditor, {
      at: nodePath,
      mode: "lowest",
      match: Text.isText,
    });

  if (conditionThreads.size > 1) {
    const conditionThreadsLengthByID = new Map(
      conditionThreadAsArray.map((id) => [id, textNode.text.length])
    );

    updateConditionThreadLengthMap(
      editor,
      conditionThreads,
      reverseTextNodeIterator,
      conditionThreadsLengthByID
    );

    // traverse in the forward direction and update the map
    updateConditionThreadLengthMap(
      editor,
      conditionThreads,
      forwardTextNodeIterator,
      conditionThreadsLengthByID
    );

    let minLength = Number.POSITIVE_INFINITY;

    // Find the thread with the shortest length.
    for (let [threadID, length] of conditionThreadsLengthByID) {
      if (length < minLength) {
        shortestConditionThreadID = threadID;
        minLength = length;
      }
    }
  }

  return shortestConditionThreadID;
};

function updateConditionThreadLengthMap(
  editor,
  commentThreads,
  nodeIterator,
  map
) {
  let nextNodeEntry = nodeIterator(editor);

  while (nextNodeEntry != null) {
    const nextNode = nextNodeEntry[0];
    const commentThreadsOnNextNode = getConditionsOnTextNode(nextNode);

    const intersection = [...commentThreadsOnNextNode].filter((x) =>
      commentThreads.has(x)
    );

    if (intersection.length === 0) {
      break;
    }

    for (let i = 0; i < intersection.length; i++) {
      map.set(intersection[i], map.get(intersection[i]) + nextNode.text.length);
    }

    // call the iterator to get the next text node to consider
    nextNodeEntry = nodeIterator(editor, nextNodeEntry[1]);
  }

  return map;
}

export const removeCondition = (editor, conditionID) => {
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });

  Editor.removeMark(editor, `condition_${conditionID}`);

  Transforms.deselect(editor);
};
