// import fetch from "node-fetch";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { ClientContext } from "../stores/ClientStore";
import { UserContext } from "../Utils/UserContext";
import "../App.css";
import "./LandingPage2.css";
import { ButtonFetch, PrimaryButton, SecondaryButton } from "../Utils/Helper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const LandingPage2 = observer(() => {
  const history = useHistory();

  const clientStore = useContext(ClientContext);

  const { authState, setAuthState } = useContext(UserContext);

  const [userSelect, setUserSelect] = useState('Fill Form');

  const reRoute = async (readFromFile) => {
    setUploadError(null);
    if (readFromFile) {
      var data = new FormData();
      data.append("document", file);

      const resp = await fetch("/api/visionPDF", {
        method: "post",
        body: data,
      });

      const respdata = await resp.json();

      if (respdata.success) {
        let extractedData = respdata.data;
        Object.keys(extractedData).map((a) => {
          clientStore.genericResponses[a] = extractedData[a];
        });
        history.push("/1");
      } else {
        setUploadError(respdata.data);
        setUpload(null);
        setFile(null);
      }
    } else if(authState.user && authState.mfa_enabled) {
      history.push("/1");
    } else {
      history.push("/login");
    }
    return { success: true };
  };

  const [file, setFile] = useState(null);
  let [uploadError, setUploadError] = useState(null);

  const [uploadButtonMessage, setUpload] = useState(null);

  const fileRef = useRef(null);

  const customRefFileUpload = useRef(null);

  return (
    <Container className="mt-3 landing-container">
        <Row>
            <Col xs="12" md="12" lg="6">
            <div 
                style={{
                backgroundColor: "#F7F8FF",
                borderRadius: "23px"
                }}
            >
                <div className="leftHalf pt-4 pe-2">
                    <h3 className="textStyle">
                        <b>Reisemängel während Ihrer Pauschalreise?</b>
                    </h3>
                    <h3 className="textStyle pt-4 pb-5 heading2" 
                        style={{
                        color: "#3249B4"
                        }}
                    >
                        <b><u>Wir holen Ihr Geld zurück!</u></b>
                    </h3>

                    <div className="pb-4">
                        <div className="mb-3">
                            <span className="circle">01</span> 
                            <span className="textStyle item-heading ps-4"><b>Reisedetails eingeben</b></span>
                        </div>
                        <div className="rule" style={{paddingLeft: "27px"}}></div>
                        <div className="my-3">
                            <span className="circle">02</span>
                            <span className="textStyle item-heading ps-4"><b>Erstattung beauftragen</b></span>
                        </div>
                        <div className="rule" style={{paddingLeft: "27px"}}></div>
                        <div className="my-3">
                            <span className="circle">03</span>
                            <span className="textStyle item-heading ps-4"><b>Geld erhalten</b></span>
                        </div>
                    </div>
                </div>
            </div>
            </Col>

            <Col xs="12" md="12" lg="4" className="mt-5 rightHalf offset-lg-1">
                <div className="sideCard">
                    <div className="d-flex justify-content-between px-4 pt-2 pb-2">
                        <div className="d-flex flex-column">
                            <h5 className="textStyle rightHalf-heading"><b>Bitte laden Sie Ihre Reisebestätigung hoch</b>
                            </h5>
                            <figcaption class="figure-caption">{file ? file.name : "Datei auswählen"}</figcaption>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="circle-2" 
                            onClick={(e) => {
                                if(userSelect != 'Upload'){
                                    return;
                                }
                                setUserSelect('Upload');
                                customRefFileUpload.current.click();
                            }}
                            style={{
                                background: userSelect == 'Upload' ? '#3249B4' : '#73757d'
                            }}
                            >+</span>
                            <input
                                ref={customRefFileUpload}
                                id="file-input"
                                placeholder="placeholder"
                                type="file"
                                onChange={async (e) => {
                                setFile(e.target.files[0]);
                                setUpload("WEITER");
                                }}
                                style={{ display: "none" }}
                            />
                        </div>
                    </div>
                    {file ? (
                        <div className='ms-5 ps-5'
                        style={{
                            margin: "5px",
                        }}
                        >
                        <PrimaryButton
                            size="small"
                            onClick={async (e) => {
                            if (file) {
                                setUpload("Loading...");
                                await reRoute(true);
                            } else {
                                setUploadError("Please upload a file");
                            }
                            }}
                        >
                            {uploadButtonMessage}
                        </PrimaryButton>
                        </div>
                    ) : (
                        <></>
                    )}
                    {uploadError ? (
                        <p style={{ color: "red" }}>{uploadError}</p>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="d-flex justify-content-center my-2">
                    <hr className="mx-2"></hr>
                    <span className="mt-2" style={{fontSize: "10px"}}>ODER</span>
                    <hr className="mx-2"></hr>
                </div>

                <div className="sideCard">
                    <div className="d-flex justify-content-between px-4 pt-2 pb-2">
                        <div className="d-flex flex-column"
                            style={{
                            width: "60%"
                            }}
                        >
                            <h5 className="textStyle rightHalf-heading"><b>Ohne Hochladen der Reisebestätigung fortfahren</b></h5>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="circle-3 d-flex align-items-center justify-content-center" id='circle-3'>
                                <span className="circle-4"
                                    id='circle-4'
                                    style={{
                                        background: userSelect == 'Fill Form' ? '#3249B4' : '#73757d',
                                    }}
                                    onClick={()=>{ 
                                        if(file){
                                            return;
                                        }
                                        setUserSelect('Fill Form'); 
                                        document.getElementById('circle-3').style.background = '#C59547';
                                        document.getElementById('circle-4').style.background = '#485bac';
                                        setTimeout(()=>{
                                            document.getElementById('circle-3').style.background = '#FFFFFF';
                                            document.getElementById('circle-4').style.background = '#3249B4';
                                        }, 200);
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="d-flex justify-content-center align-items-center mt-4"
                    style={{
                        height: "25%",
                        width: "100%"
                    }}
                >
                    <button 
                    className="btn-weiter"
                    onClick={() => reRoute(false)}
                    >
                    WEITER
                    </button>
                </div> 
            </Col>
        </Row>
    </Container>
  );
});

export default LandingPage2;
