import React, { useState, useContext, useEffect, useRef } from "react";

import "./element.css";
import { observer } from "mobx-react-lite";
import { ClientContext } from "../../stores/ClientStore";
import { EditContext } from "../../stores/EditStore";
import { toJS } from "mobx";
import ReactLoading from "react-loading";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import ResolveElement from "./ResolveElement";
import PreviewElement from "./PreviewElement";

//Disable validation

const maintainOrderFlows = [
  "MangelSpecify",
  "PauschalorIndividualTrip",
  "dateOfDeficiency",
  "mangelBeseitigung",
  "deficiencyNotice",
  "typeOfDeficiencyReplacementAccomodation",
  "defectBalconyTerraceOrSeaview",
  "bugsLocation",
  "bugsDeadOrAlive",
  "baularmSub",
  "tierGehort",
  "acProblemsLocation",
  "locationVerschmutzung",
  "LocationSmell",
  "heatingProblemsLocation",
  "reasonsForCancellationPriorToHolidays",
  "bugsExactPlacesPrivate",
  "typeofDeficiencySanitarySupplies",
  "artLarm",
  "extentVolume3",
  "bugsQuantity",
  "whichRoomTypeSizeOfApartment",
  "assuredRoomTypeTravelDocuments",
  "newRoomType",
  "oldRoomType",
  "HowOftenWasTheRoomCleaned",
  "HowOftenNewHandtuecher",
  "specificHowOftenNewBedclothes",
];

const PreviewElementList = observer((props) => {
  const clientStore = useContext(ClientContext);
  const editStore = useContext(EditContext);

  let myRef = useRef(null);

  let microPhoneQues = props.questionId.name == "Kontextinformationen";

  return clientStore.loadingOptions.questions ? (
    <div className="full-size-center" id={props.id} style={{ height: "90vh" }}>
      <ReactLoading type="spokes" color="black" />
    </div>
  ) : (
    <Container id={props.id} className={`pt-4 `}>
      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="8" className={``}>
          <Form>
            <PreviewElement
              key={`elem-${props.questionId}`}
              questionId={props.questionId}
              // disabled={props.questionId != clientStore.currentQuestion}
              microPhoneQues={microPhoneQues}
              ref={myRef}
            />
            <hr />
          </Form>
        </Col>
      </Row>
    </Container>
  );
});

export default PreviewElementList;
