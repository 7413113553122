import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../stores/DocumentStore";

import { ButtonFetch } from "../../Utils/Helper";

import CustomEditor from "ckeditor5-custom-build/build/ckeditor";

import Select from "react-select";
import Creatable, { useCreatable } from "react-select/creatable";

import { toJS } from "mobx";
// import { DragList } from "../../Utils/DragList";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Subdocument = observer((props) => {
  const documentStore = useContext(DocumentContext);

  // subdocuments list
  const [list, setList] = useState([]);

  const [msg, setMsg] = useState("");

  useEffect(() => {
    const getSubDocument = async () => {
      if (
        documentStore.currentSubID === "NEW" ||
        documentStore.currentSubID == ""
      )
        return;
      const result = await fetch(
        `/docapi/subdocument/${documentStore.currentSubID}`
      );
      const resp = await result.json();

      documentStore.currentSubDocument = resp.data;
    };

    getSubDocument();
  }, [documentStore.currentSubID]);

  useEffect(() => {
    const getSubDocumentList = async () => {
      const result = await fetch(`/docapi/subdocumentlist`);
      const resp = await result.json();

      setList(resp.data);
    };

    getSubDocumentList();
  }, []);

  const handleDrag = (list, start, end) => {
    const result = Array.from(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);

    return result;
  };

  const subDocParagraphResolver = (block, index) => {
    return (
      <ParagraphBlock key={index} block={block} list={list} index={index} />
    );
  };

  return (
    <Container className="main-doc-container">
      <Row>
        <Col xs="12">
          <div>
            <div>
              <GlobalVariables />
            </div>
            <select
              onChange={(e) => {
                documentStore.currentSubID = e.target.value;
              }}
            >
              <option value="select">Select...</option>
              {list.map((subdoc, index) => {
                return (
                  <option value={subdoc.id} key={index}>
                    {subdoc.title}
                  </option>
                );
              })}
            </select>

            <button
              onClick={() => {
                documentStore.currentSubID = "NEW";
                documentStore.currentSubDocument = {
                  title: "New Subdocument",
                  paragraphs: [
                    {
                      type: "Plain",
                      content: "",
                      condition: "",
                    },
                  ],
                };
              }}
            >
              New Subdocument
            </button>
            <button
              onClick={async () => {
                setMsg("Saving subdocument ....");

                if (documentStore.currentSubID === "NEW") {
                  const result = await fetch("/docapi/subdocument", {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      title: documentStore.currentSubDocument.title,
                      paragraphs: documentStore.currentSubDocument.paragraphs,
                    }),
                  });
                  const resp = await result.json();

                  setMsg(
                    resp.status
                      ? "Subdocument saved successfully!"
                      : "Error saving the subdocument"
                  );
                } else {
                  const result = await fetch(`/docapi/subdocument`, {
                    method: "put",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      title: documentStore.currentSubDocument.title,
                      id: documentStore.currentSubID,
                      paragraphs: documentStore.currentSubDocument.paragraphs,
                    }),
                  });
                  const resp = await result.json();

                  setMsg(
                    resp.status
                      ? "Subdocument saved successfully!"
                      : "Error saving the subdocument"
                  );
                }
              }}
            >
              Save Subdocument
            </button>

            <button
              onClick={() => {
                documentStore.currentSubDocument.paragraphs?.push({
                  type: "Plain",
                  content: "",
                  condition: "",
                });
              }}
            >
              Add Paragraph
            </button>
          </div>
          <div>
            {documentStore.currentSubDocument.paragraphs && (
              <div>
                <input
                  type="text"
                  value={documentStore.currentSubDocument.title}
                  onChange={(e) => {
                    documentStore.currentSubDocument.title = e.target.value;
                  }}
                />
                <button
                  onClick={async () => {
                    const result = await fetch(
                      `/docapi/subdocument/${documentStore.currentSubID}`,
                      {
                        method: "delete",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    const resp = await result.json();

                    setMsg(resp.success);
                  }}
                >
                  Delete Subdocument
                </button>
              </div>
            )}
          </div>
          <div>{msg}</div>
          <div>
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) return;

                const items = handleDrag(
                  documentStore.currentSubDocument.paragraphs,
                  result.source.index,
                  result.destination.index
                );

                documentStore.currentSubDocument.paragraphs = items;
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {/* <ObsItems /> */}
                    {documentStore.currentSubDocument.paragraphs &&
                      documentStore.currentSubDocument.paragraphs.map(
                        (x, i) => {
                          return (
                            <Draggable
                              key={`${i}`}
                              draggableId={`${i}`}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="edit-card"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {subDocParagraphResolver(x, i)}
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* {documentStore.currentSubDocument.paragraphs &&
              documentStore.currentSubDocument.paragraphs.map((block, index) => {
                
                return (
                  <ParagraphBlock
                    key={index}
                    block={block}
                    list={list}
                    index={index}
                  />
                );
              })} */}
          </div>
        </Col>
      </Row>
    </Container>
  );
});

const GlobalVariables = observer((props) => {
  const [vars, setVars] = useState([]);

  const [show, setShow] = useState(false);

  const [newVar, setNew] = useState({ name: "", value: "" });

  useEffect(() => {
    const getGlobals = async () => {
      const res = await fetch("/docapi/globalvariables");
      const data = await res.json();

      setVars(data.data);
    };
    getGlobals();
  }, [show]);

  return (
    <div className="global-main">
      <div>
        <input
          type="text"
          placeholder="Global Name"
          value={newVar.name}
          onChange={(e) => {
            setNew({ value: newVar.value, name: e.target.value });
          }}
        />
        <input
          type="text"
          placeholder="Global Value"
          value={newVar.value}
          onChange={(e) => {
            setNew({ name: newVar.name, value: e.target.value });
          }}
        />
        <ButtonFetch
          initMessage="Save Global Variable"
          successMessage="Saved"
          failMessage="Failed"
          fetchUrl="/docapi/globalvariables"
          fetchOptions={{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...newVar,
            }),
          }}
        />
      </div>
      <button
        onClick={(e) => {
          setShow(!show);
        }}
      >
        Show/Hide Variables
      </button>
      {show ? (
        <div className="global-table">
          <table>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>
                <b>Value</b>
              </td>
            </tr>
            {vars.map((x) => {
              return (
                <tr>
                  <td>{x.name}</td> <td>{x.value}</td>
                </tr>
              );
            })}
          </table>
        </div>
      ) : null}
    </div>
  );
});

const ParagraphBlock = observer((props) => {
  const [vars, setVars] = useState([]);

  const documentStore = useContext(DocumentContext);

  const getFeedItems = (query) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const items = documentStore.variables
          .filter((item) => item.includes(query))
          .map((x) => "@" + x);

        resolve(items);
      }, 100);
    });
  };

  var config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "|",
        "imageUpload",
        "blockQuote",
        "insertTable",
        "mediaEmbed",
        "undo",
        "redo",
        "alignment",
        "CKFinder",
      ],
    },
    plugins: CustomEditor.builtinPlugins,
    // plugins: ["Mention"],
    mention: {
      feeds: [
        {
          marker: "@",
          feed: getFeedItems,
          minimumCharacters: 1,
        },
      ],
    },
    font_style: {
      element: "span",
      styles: { "font-family": "Arial" },
      overrides: [{ element: "font", attributes: { face: null } }],
    },
  };

  useEffect(() => {
    const getVariables = async () => {
      const result = await fetch("/api/dummyResponses");
      const resp = await result.json();
      var temp = Object.keys(resp).map((x) => {
        //
        return Object.keys(resp[x]);
      });
      var a = [].concat.apply([], temp);
      setVars(a);
      documentStore.variables = a;
      //
    };
    getVariables();
  }, []);

  useEffect(() => {
    //
  }, [documentStore.variables]);

  return (
    <div className="para-block">
      <div className="para-header">
        <div>
          {props.block.type == "Conditional"
            ? "ⓘ Paragraph will only be loaded if condition is met"
            : ""}
        </div>
        <button
          onClick={() => {
            documentStore.currentSubDocument.paragraphs.splice(props.index, 1);
          }}
        >
          X
        </button>
      </div>

      <div className="para-options">
        <div className="spread">
          <div className="bold">Type:</div>
          <select
            value={props.block.type}
            onChange={(e) => {
              props.block.type = e.target.value;
            }}
          >
            <option value={"Conditional"}>Conditional</option>
            <option value={"Plain"}>Plain</option>
          </select>
        </div>
        {props.block.type == "Conditional" ? (
          <div>
            <div className="bold">Condition:</div>{" "}
            <Select
              value={props.block.condition ? props.block.condition[0] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[0] = e;
              }}
              className="z-ind"
              options={["equalto", "greaterthan", "lessthan", "if"].map((x) => {
                return { value: x, label: x };
              })}
            />
            First Variable:
            <Select
              value={props.block.condition ? props.block.condition[1] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[1] = e;
              }}
              className="z-ind"
              options={vars.map((x) => {
                return { value: x, label: x };
              })}
            />
            Second Variable:{" "}
            <Creatable
              value={props.block.condition ? props.block.condition[2] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[2] = {
                  label: e.label,
                  value: e.value,
                  custom: e.__isNew__,
                };
              }}
              className="z-ind"
              options={vars.map((x) => {
                return { value: x, label: x };
              })}
              allowCreateWhileLoading
            />
          </div>
        ) : null}
      </div>

      <div className="better-font">
        <div className="bold">Custom Content</div>
        <CKEditor
          editor={CustomEditor}
          config={config}
          data={`${props.block.content}`}
          onReady={(editor) => {}}
          onChange={(event, editor) => {
            const data = editor.getData();

            props.block.content = data;
          }}
        />
      </div>
    </div>
  );
});
