import { isValidPhoneNumber } from 'react-phone-number-input';
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../Utils/firebase";
import "./loginpage.css";
import { UserContext } from "../Utils/UserContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import googleLogo from "../Resources/google.png";
import ContactInput from '../Components/Flow/ResolveElementUtils/ContactInput';

function LoginPage(props) {
  const [email, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [cnfpass, setcnfPass] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [page, setPage] = useState(true);

  // page {true ==> 'Login'  ,   false ==> 'Sign Up'}
  // States with description
  // State 1 ==> Starting state for Login
  // State 2 ==> Starting state for Sign-up
  // State 3 ==> Email Verification Request State on Sign-up Page
  // State 4 ==> Email Verification Request State on Login Page 
  // State 5 ==> Multi-factor authentication Enrollment Request State on Login Page 
  // State 6 ==> Multi-factor authentication Enrollment State with OTP input on Login Page 
  // State 7 ==> Multi-factor authentication to Login with OTP input
  // State 8 ==> Forgot Password Interface
  // State 9 ==> Forgot Password Email Sent Alert Interface

  const [loginError, setLoginError] = useState("");
  const [contactError, setContactError] = useState(false);
  const [validate, setValidate] = useState(false);
  const [disableSendOtp, setDisableSendOtp] = useState(false);
  const [disableSendEmail, setDisableSendEmail] = useState(false);
  const [state, setState] = useState(1);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({});
  const [otpSendCnt, setOtpSendCnt] = useState(0);
  const [emailSendCnt, setEmailSendCnt] = useState(0);
  const [progress, setProgress] = useState(100);
  var [timeLeft, setTimeLeft] = useState(59);

  const { authState, setAuthState } = useContext(UserContext);
  const AuthErrorMessageMap = require('./firebase_auth_error.json');
  const history = useHistory();

  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  useEffect(() => {
    generateRecaptcha();
    // firebase.auth().languageCode = 'de';
    firebase.auth().useDeviceLanguage();
    if (authState.user && (!authState.mfa_enabled)) {
      enroll_MFA_using_mobile();
    }
  }, []);

  const setAlert = (msg) => {
    setMessage(msg);
    setLoginError('');
  }

  const refreshPage = (bool) => {
    setPage(bool);
    bool ? setState(1) : setState(2);
    setAlert('');
    setMail("");
    setPass("");
    setcnfPass("");
    setValidate(false);
  }

  const handleAuthError = (errCode) => {
    let err_message = AuthErrorMessageMap[errCode] ? AuthErrorMessageMap[errCode] : 'Es tut uns leid ! Es ist ein Fehler aufgetreten. Wir bedauern die Unannehmlichkeiten.';
    setLoginError(err_message);
  }

  const update_otp_sent_status = () => {
    setDisableSendOtp(true);
    setOtpSendCnt(otpSendCnt + 1);
    const stopTimer = setInterval(() => {
      timeLeft -= 1;
      setTimeLeft(timeLeft);
    }, 1000);
    setTimeout(() => {
      setTimeLeft(59);
      setDisableSendOtp(false);
      clearTimeout(stopTimer);
    }, 60000);
  }

  const update_email_sent_status = () => {
    setDisableSendEmail(true);
    setEmailSendCnt(emailSendCnt + 1);
    const stopTimer = setInterval(() => {
      timeLeft -= 1;
      setTimeLeft(timeLeft);
    }, 1000);
    setTimeout(() => {
      setTimeLeft(59);
      setDisableSendEmail(false);
      clearTimeout(stopTimer);
    }, 60000);
  }

  const formatTime = (seconds) => {
    let min = Math.floor(seconds / 60);
    let sec = seconds % 60;
    min = min < 10 ? `0${min}` : `${min}`;
    sec = sec < 10 ? `0${sec}` : `${sec}`;
    return `${min} : ${sec}`;
  }

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('reCAPTCHA-container', {
      'size': 'invisible',
      'callback': (response) => { }
    });
  }

  const send_OTP_to_enroll = async (event) => {
    event.preventDefault();
    if (!isValidPhoneNumber(contactNo)) {
      setContactError(true);
      return;
    }
    setContactError(false);

    try {
      let recaptchaVerifier = window.recaptchaVerifier;
      const multiFactorSession = await authState.user.multiFactor.getSession();
      const phoneInfoOptions = {
        phoneNumber: contactNo,
        session: multiFactorSession
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      update_otp_sent_status();
      setState(6);
      setAlert(`Wir haben eine SMS an die von Ihnen angegebene Telefonnummer versandt. Falls Sie diese nicht erhalten haben, klicken Sie auf "erneut senden".`);
    } catch (err) {
      handleAuthError(err.code);
    };
  };

  const send_OTP_to_login = async () => {
    try {
      let resolver = window.resolver;
      let recaptchaVerifier = window.recaptchaVerifier;
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      update_otp_sent_status();
    } catch (err) {
      handleAuthError(err.code);
    }
  }

  const validate_OTP_to_enroll = async () => {
    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(window.verificationId, otp.join(''));
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      const mfaDisplayName = 'multi-factor authentication using phone';
      const enrolled = await authState.user.multiFactor.enroll(multiFactorAssertion, mfaDisplayName);
      setAuthState({ initializing: false, user: firebase.auth().currentUser, mfa_enabled: true });
      setAlert(`Congratulations! You have been successfully enrolled with 2-factor authentication on our website.`);
      if (firebase.auth().currentUser.email.endsWith('legalai.io')) {
        history.push("/admin");
      } else {
        history.push("/1");
      }
    } catch (err) {
      handleAuthError(err.code);
    }
  };

  const validate_OTP_to_login = async () => {
    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(window.verificationId, otp.join(''));
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      const userCredential = await window.resolver.resolveSignIn(multiFactorAssertion);
      setAuthState({ initializing: false, user: firebase.auth().currentUser, mfa_enabled: true });
      setAlert(`Congratulations! You have successfully logged in to our website.`);
      if (firebase.auth().currentUser.email.endsWith('legalai.io')) {
        history.push("/admin");
      } else {
        history.push("/1");
      }
    } catch (err) {
      handleAuthError(err.code);
    }
  };

  const enroll_MFA_using_mobile = () => {
    setState(5);
    setAlert("Bitte fahren Sie mit der Zwei-Faktor-Authentifizierung über ihr mobiles Endgerät fort, um auf alle Funktionen unserer Website zugreifen zu können.");
  }

  const reauthenticate_using_mobile = () => {
    let resolver = window.resolver;
    setState(7);
    setAlert(`Bitte fahren Sie mit der Zwei-Faktor-Authentifizierung fort. Wir haben Ihnen einen SMS Code auf die von Ihnen hinterlegte Mobilnummer ${resolver.hints[0].phoneNumber} versandt. Bitte geben Sie diesen SMS Code ein.`);
    if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
      send_OTP_to_login();
    }
  }

  const sendVerificationEmail = async () => {
    try {
      const sendMail = await user.sendEmailVerification();
      update_email_sent_status();
    } catch (err) {
      setLoginError(err.message);
    }
  }

  const googleSignIn = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const credential = result.credential;
      const token = credential.accessToken;
      const user = result.user;
      const userEmail = result.additionalUserInfo.profile.email;
      setAuthState({ initializing: false, user: firebase.auth().currentUser, mfa_enabled: false });
      enroll_MFA_using_mobile();
    } catch (err) {
      if (err.code == 'auth/multi-factor-auth-required') {
        window.resolver = err.resolver;
        reauthenticate_using_mobile();
      } else {
        handleAuthError(err.code);
      }
    }
  }

  const signupUser = async () => {
    try {
      setProgress(0);
      const userCredentials = await firebase.auth().createUserWithEmailAndPassword(email, pass);
      setProgress(33);
      const sendMail = await userCredentials.user.sendEmailVerification();
      update_email_sent_status();
      setUser(userCredentials.user);
      setProgress(67);
      const signout = await firebase.auth().signOut();
      setState(3);
      setAlert(`Ihr Konto wurde erfolgreich aktiviert. Wir haben eine Bestätigungs-E-Mail an die von Ihnen  angegebene E-Mail-Adresse gesendet "${email}". Bitte verifizieren Sie Ihr Konto und melden Sie sich auf unserer Website an. Falls Sie die E-Mail nicht erhalten haben, klicken Sie auf "erneut senden".`);
      setProgress(100);
    } catch (err) {
      setProgress(100);
      handleAuthError(err.code);
    }
  };

  const loginUser = async () => {
    try {
      setProgress(0);
      const userCredentials = await firebase.auth().signInWithEmailAndPassword(email, pass);
      setProgress(40);
      if (userCredentials.user.emailVerified) {
        setAuthState({ initializing: false, user: firebase.auth().currentUser, mfa_enabled: false });
        enroll_MFA_using_mobile();
        setProgress(100);
      } else {
        const sendMail = await userCredentials.user.sendEmailVerification();
        setProgress(60);
        update_email_sent_status();
        setUser(userCredentials.user);
        setProgress(80);
        const signout = await firebase.auth().signOut();
        setState(4);
        setLoginError(`Bitte verifizieren Sie Ihre E-Mail-Adresse für die Anmeldung. Wir haben eine Bestätigungsmail an das von Ihnen angegebene E-Mail-Konto gesendet "${email}". Falls Sie die E-Mail nicht erhalten haben, klicken Sie auf die Schaltfläche "erneut senden".`);
        setProgress(100);
      }
    } catch (err) {
      setProgress(100);
      if (err.code == 'auth/multi-factor-auth-required') {
        window.resolver = err.resolver;
        reauthenticate_using_mobile();
      } else {
        handleAuthError(err.code);
      }
    };
  };

  const domainNameLayer = async () => {
    let res = await fetch("api/checkDomain");
    let data = await res.json();

    return data;
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (!page) {
      if (pass != cnfpass) {
        document.getElementById('cnfpassword').setCustomValidity('Die Passwörter stimmen nicht überein.');
      } else {
        document.getElementById('cnfpassword').setCustomValidity('');
      }
    } else {
      document.getElementById('cnfpassword').setCustomValidity('');
    }

    if (form.checkValidity() == false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      try {
        const val = await domainNameLayer();
        if (val.success) {
          if (state == 8) {
            const sent = await firebase.auth().sendPasswordResetEmail(email);
            setState(9);
            setAlert('Wir haben eine E-Mail zum Zurücksetzen des Passworts an das von Ihnen angegebene E-Mail-Konto gesendet. Sie können Ihr Passwort jetzt zurücksetzen.');
          } else {
            page ? loginUser() : signupUser();
          }
        } else {
          setLoginError("Sie sind nicht berechtigt, ein Konto zu erstellen.");
        }
      } catch (e) {
        setLoginError(e.message);
      };
    }
    setValidate(true);
  };

  return (
    <>
      <div className='progressBar d-flex m-0 p-0'>
        <div className='m-0 p-0' 
        style={{ 
          width: `${progress}vw`,
          height: '4px',
          background: '#0226B9',
          display: progress == 100 ? 'none' : 'block'
          }}
        ></div>
        <div className='m-0 p-0' 
        style={{ 
          width: `${100 - progress}vw`,
          height: '4px',
          background: '#5290ec',
          display: progress == 100 ? 'none' : 'block'
          }}
        ></div>
      </div>

      <div className="container-signup">
        <Container>
          <Row className="justify-content-center my-4 mx-2">
            <Col xs="12" md="10" lg="7" className="box-signup pt-4 pb-3 px-4 shadow-sm">

              <h2 className="text-center text-uppercase">
                <b>{[8, 9].includes(state) ? 'Passwort vergessen' : page ? 'anmelden' : 'registrieren'}</b>
              </h2>

              {message != "" ? (
                <p className={`box-success p-3 mt-4`}>{message}</p>
              ) : (
                <></>
              )}
              {loginError != "" ? (
                <p className={`box-error p-3 mt-4`}>{loginError}</p>
              ) : (
                <></>
              )}

              <div style={{ display: [1, 2].includes(state) ? 'block' : 'none' }}>
                <div className="d-flex justify-content-center mt-4">
                  <div className="login-option py-2 px-4" onClick={googleSignIn}>
                    <img src={googleLogo} alt="Google" height={30} />
                    <span className="text-uppercase ps-2">Google</span>
                  </div>
                </div>
                <h5 className="text-center mt-3">oder {page ? 'anmelden' : 'registrieren'} über</h5>
              </div>

              <Form
                id="SignupForm"
                noValidate
                validated={validate}
                onSubmit={handleSubmit}
                style={{ display: [1, 2].includes(state) ? 'block' : 'none' }}
              >
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="email" className="text-nowrap mt-2">E-Mail-Adresse</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        required
                        value={email}
                        type="email"
                        name="email"
                        placeholder="E-Mail eingeben"
                        id="email"
                        onChange={(e) => setMail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Bitte geben Sie eine gültige E-Mail-Adresse an.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="password" className="text-nowrap mt-2">Passwort</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        required
                        minLength={6}
                        maxLength={40}
                        value={pass}
                        type="password"
                        name="password"
                        placeholder="Passwort eingeben"
                        id="password"
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Das Passwort muss mindestens sechs Zeichen lang sein.
                      </Form.Control.Feedback>
                      {
                        page ? <p className="text-end my-0 pt-0">
                          <button type="button" className="btn btn-link text-decoration-none m-0 py-0 ps-2"
                            onClick={() => {
                              setState(8);
                              setAlert('Bitte geben Sie Ihre registrierte E-Mail-Adresse ein, um Ihr Login-Passwort zu aktualisieren.');
                              setMail('');
                            }}
                          >
                            passwort vergessen?
                          </button>
                        </p> : <></>
                      }
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group style={{ display: page ? 'none' : 'block' }}>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="cnfpassword" className="text-nowrap mt-2">Passwort bestätigen</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        minLength={6}
                        maxLength={40}
                        value={cnfpass}
                        type="password"
                        name="cnfpassword"
                        placeholder="Passwort erneut eingeben"
                        id="cnfpassword"
                        onChange={(e) => setcnfPass(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Die Passwörter stimmen nicht überein.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Button
                  variant="primary"
                  className="text-uppercase"
                  type="submit"
                  disabled={progress != 100}
                >
                  {page ? 'Anmelden' : 'Registrieren'}
                </Button>
              </Form>

              <div id="reCAPTCHA-container"
                style={{ display: 'none' }}
              ></div>
              <Form
                id="otp-form"
                onSubmit={send_OTP_to_enroll}
                style={{ display: [5, 6].includes(state) ? 'block' : 'none' }}
              >
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="contactNo" className="text-nowrap mt-2">Telefonnummer</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <ContactInput value={contactNo} setValue={setContactNo} id="contactNo" />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          marginTop: '-5px',
                          display: contactError ? 'block' : 'none'
                        }}
                      >
                        Bitte geben Sie eine gültige Telefonnummer ein.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <div className="d-flex flex-row-reverse">
                  <Button
                    variant="primary"
                    className="text-uppercase"
                    type="submit"
                    disabled={disableSendOtp}
                  >
                    {disableSendOtp ? formatTime(timeLeft) : `${otpSendCnt != 0 ? 'erneut' : ''} senden`}
                  </Button>
                </div>
              </Form>

              <div className="d-flex flex-row-reverse">
                <Button
                  variant="primary"
                  className="text-uppercase"
                  onClick={send_OTP_to_login}
                  disabled={disableSendOtp}
                  style={{
                    display: state == 7 ? 'block' : 'none'
                  }}
                >
                  {disableSendOtp ? formatTime(timeLeft) : `${otpSendCnt != 0 ? 'erneut' : ''} senden`}
                </Button>
              </div>

              <div className="d-flex flex-row-reverse">
                <Button
                  variant="primary"
                  className="text-uppercase"
                  onClick={sendVerificationEmail}
                  disabled={disableSendEmail}
                  style={{
                    display: [3, 4].includes(state) ? 'block' : 'none'
                  }}
                >
                  {disableSendEmail ? formatTime(timeLeft) : `${emailSendCnt != 0 ? 'erneut' : ''} senden`}
                </Button>
              </div>

              <Row className="mt-2">
                <Col xs="12" md="8" lg="8">
                  <div className="d-flex justify-content-center">
                    {otp.map((data, index) => {
                      return (
                        <input
                          className="otpInput mx-1"
                          type="text"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={e => handleChange(e.target, index)}
                          onFocus={e => e.target.select()}
                          style={{
                            display: [6, 7].includes(state) ? 'block' : 'none'
                          }}
                        />
                      );
                    })}
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <div className="d-flex flex-row-reverse">
                    <Button
                      variant="primary"
                      className="text-uppercase"
                      onClick={() => {
                        state == 7 ? validate_OTP_to_login() : validate_OTP_to_enroll();
                      }}
                      style={{
                        display: [6, 7].includes(state) ? 'block' : 'none'
                      }}
                    >
                      Verifizieren
                    </Button>
                  </div>
                </Col>
              </Row>

              <Form
                id="ForgotPasswordForm"
                noValidate
                validated={validate}
                onSubmit={handleSubmit}
                style={{ display: state != 8 ? 'none' : 'block' }}
              >
                <Form.Group>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <Form.Label htmlFor="email2" className="text-nowrap mt-2">E-Mail-Adresse</Form.Label>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <Form.Control
                        required
                        value={email}
                        type="email"
                        name="email"
                        placeholder="E-Mail eingeben"
                        id="email2"
                        onChange={(e) => setMail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid" style={{ marginTop: '-5px' }}>
                        Bitte geben Sie eine gültige E-Mail-Adresse an.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                >
                  Passwort zurücksetzen
                </Button>
              </Form>

              <p className="text-center mt-2 mb-0 pb-0"
                style={{
                  display: [5, 6].includes(state) ? 'none' : 'block'
                }}
              >
                {state == 1 ? 'Noch kein Konto?' : 'Haben Sie bereits ein Konto?'}
                <button type="button" className="btn btn-link text-decoration-none m-0 p-2"
                  onClick={() => {
                    state == 1 ? refreshPage(false) : refreshPage(true);
                  }}
                >
                  {" "}
                  {state == 1 ? 'Jetzt registrieren' : 'Anmelden'}
                </button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
