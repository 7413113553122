import { observer } from "mobx-react-lite";
import { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { SimilarityStoreContext } from "../../stores/SimilarityStore";
import "./similarityEngine.css";
import "../Document/maindocument.css";
import Select from "react-select";

const CaseSimilarityEngine = observer(() => {
  let similarityStore = useContext(SimilarityStoreContext);
  let [saveData, setSaveData] = useState(null);
  let [color, setColor] = useState("black");

  useEffect(() => {
    const fetchAllData = async () => {
      let resp = await fetch("api/getSimilarityDatabase");
      let data = await resp.json();
      if (data.success) {
        similarityStore.allSimilarityCases = data.data;
      } else {
      }
    };
    fetchAllData();
  }, []);

  useEffect(() => {
    const fetchAllVariables = async () => {
      let resp = await fetch("api/getAllVariables");
      let data = await resp.json();
      if (data.success) {
        similarityStore.allVarsExportedFromFlows = data.data;
      } else {
      }
    };
    fetchAllVariables();
  }, []);

  return (
    <Container className="add-element-container">
      <Row>
        <Col xs="12">
          <Form className="mb-2">
            <Form.Group as={Row} controlId="selectCases">
              <Form.Label column xs="12" md="2">
                <b>Variable auswählen</b>
              </Form.Label>
              <Col xs="12" md="10" lg="10">
                <Form.Select
                  onChange={async (e) => {
                    if (e.target.value === "select") return;

                    let selectedVar = similarityStore.allSimilarityCases.filter(
                      (c) => c.name == e.target.value
                    );
                    if (selectedVar.length > 0) {
                      similarityStore.currentCaseData = selectedVar[0];
                      similarityStore.currentVarSelected = e.target.value;
                    }
                  }}
                  value={similarityStore.currentVarSelected}
                >
                  <option value="select">Select variable ..</option>
                  {similarityStore.allSimilarityCases?.map((x, i) => {
                    return (
                      <option value={x.name} key={`${i}-${x.name}`}>
                        {x.rawName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Form>

          <button
            onClick={(e) => {
              similarityStore.currentCaseData = {};
              similarityStore.currentVarSelected = "new";
            }}
          >
            New Variable
          </button>

          <button>Delete variable</button>

          <button
            onClick={(e) => {
              if (
                similarityStore.currentCaseData &&
                similarityStore.currentCaseData.cases
              ) {
                similarityStore.currentCaseData.cases.push({
                  name: "",
                  d: "",
                });
              } else {
                similarityStore.currentCaseData.cases = [];
                similarityStore.currentCaseData.cases.push({
                  name: "",
                  d: "",
                });
              }
            }}
          >
            Add New Case
          </button>
          <button
            onClick={async (e) => {
              setColor("black");
              setSaveData("Saving ....");
              if (!similarityStore.currentCaseData.rawName) {
                setSaveData("Select a valid variable to save");
                setColor("danger");
                return;
              }
              const postReq = await fetch(
                "/api/saveCurrentSimilarityVariable",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ...similarityStore.currentCaseData,
                  }),
                }
              );
              let res = await postReq.json();
              if (res.success) {
                setColor("success");
                setSaveData("Saved successfully");
              } else {
                setColor("danger");
                setSaveData("Error occurred while saving");
              }
            }}
          >
            Save
          </button>
          <div className={`text-${color}`}>{saveData}</div>
          {similarityStore.currentVarSelected != "select" ? (
            <SingleVariable />
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
});

const SingleVariable = observer((props) => {
  let similarityStore = useContext(SimilarityStoreContext);

  return (
    <Row>
      <Col xs="12">
        {similarityStore.currentVarSelected == "new" ? (
          <Select
            className="mt-3 mb-3"
            onChange={async (e) => {
              similarityStore.currentCaseData.rawName = e.value;
            }}
            // value={similarityStore.currentCaseData.rawName}
            defaultValue={"Select Variable ..."}
            options={similarityStore.remainingVariables?.map((x, i) => {
              return { value: x, label: x };
            })}
          />
        ) : (
          <Form.Control
            readOnly
            value={similarityStore.currentCaseData.rawName}
          ></Form.Control>
        )}
        <div>
          <b>All Similar Cases</b>
          {similarityStore.currentCaseData &&
            similarityStore.currentCaseData.cases?.map((c, i) => {
              return (
                <div key={i} className="card-single-case">
                  <div className="para-header">
                    <div></div>
                    <button
                      onClick={() => {
                        similarityStore.currentCaseData.cases.splice(i, 1);
                      }}
                    >
                      X
                    </button>
                  </div>
                  <Form.Group as={Row} controlId={`${c.name}-${i}`}>
                    <Form.Label column xs="12" md="4" lg="2">
                      Case Name
                    </Form.Label>
                    <Col xs="12" md="8" lg="10">
                      <Form.Control
                        value={c.name}
                        onChange={(e) => {
                          c.name = e.target.value;
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId={`${c.name}-${i}-desp`}>
                    <Form.Label column xs="12" md="4" lg="2">
                      Description
                    </Form.Label>
                    <Col xs="12" md="8" lg="10">
                      <Form.Control
                        // as="textarea"
                        value={c.d}
                        onChange={(e) => {
                          c.d = e.target.value;
                        }}
                      />
                    </Col>
                  </Form.Group>
                </div>
              );
            })}
        </div>
      </Col>
    </Row>
  );
});

export default CaseSimilarityEngine;
