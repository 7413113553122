import { useEffect, useState } from "react";
import firebase from "./firebase.js";

export const useAuth = () => {
  const [authState, setAuthState] = useState(() => {
    const user = firebase.auth().currentUser;
    return {
      initializing: !user,
      user: user,
      mfa_enabled: user ? ( user.multiFactor.enrolledFactors.length == 0 ? false : true ) : false
    };
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((newUser) => {
      setAuthState({
        initializing: false,
        user: newUser,
        mfa_enabled: newUser ? ( newUser.multiFactor.enrolledFactors.length == 0 ? false : true ) : false
      });
    });
  }, []);

  return { authState, setAuthState };
};
