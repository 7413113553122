import { Transforms, Range, Editor } from "slate";
import { fetchData } from "../../Utils/Helper";
import { v4 as uuid } from "uuid";

export const insertSubdocumentBlock = async (editor, subdocumentId) => {
  const [subdoc, error] = await fetchData(
    `/docapi/new-subdocument/subdocument/${subdocumentId}`
  );

  if (error) return;

  const conditionId = uuid();

  Transforms.insertNodes(editor, [
    {
      type: "subdocument-border",
      children: [
        {
          text: `[===========${subdoc.name}===========]`,
          [`condition_${conditionId}`]: true,
        },
      ],
    },
    ...subdoc.value,
    {
      type: "subdocument-border-end",
      children: [
        {
          text: `[==========================================]`,
          [`condition_${conditionId}`]: true,
        },
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  Transforms.move(editor);

  return {
    subdoc: subdoc,
    blockCondition: {
      [`${conditionId}`]: {
        conditions: [
          {
            modifier: { value: "equals", label: "equals" },
            operator1: { value: "var1", label: "var1" },
            operator2: { value: "var2", label: "var2" },
          },
        ],
      },
    },
  };
};
