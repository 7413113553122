import ListTable from "../../Utils/ListTable";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsFillFileTextFill, BsTrash } from "react-icons/bs";
import { fetchData } from "../../Utils/Helper";
import { EditContext } from "../../stores/EditStore";

const EditForm = observer(() => {
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const editStore = useContext(EditContext);

  const history = useHistory();

  useEffect(() => {
    const listFetcher = async () => {
      const result = await fetch("/flowapi/formlist");
      const resp = await result.json();

      editStore.allFlows = [...resp.data];

      setList([...resp.data]);
    };

    listFetcher();
  }, [refresh]);

  const newForm = async () => {
    const resp = await fetch(`/flowapi/form`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formName: "Untitled Form",
      }),
    });

    const data = await resp.json();

    history.push(`/view-form/${data.data}`);
  };

  return (
    <div>
      <ListTable
        listTitle="Forms"
        list={list}
        newItem={newForm}
        ListItem={FormListItem}
      />
    </div>
  );
});

const FormListItem = observer(({ index, item }) => {
  const history = useHistory();

  const { formName, id, lastUpdated = "01.01.1970" } = item;

  return (
    <div className="list-item">
      <div className="list-icon">
        <BsFillFileTextFill size={30} />
      </div>
      <div className="vertical-row">
        <div
          className="main-title"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/view-form/${id}`);
          }}
        >
          {formName}
        </div>
      </div>
      <div className="secondary-title">{lastUpdated}</div>
      <div className="list-icon">
        <BsTrash
          size={30}
          onClick={async (e) => {
            const consent = confirm(`Do you really want to delete "${formName}" flow ?`);
            if(consent){
              const [data, err] = await fetchData("/flowapi/form", {
                method: "delete",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  formId: `${id}`,
                }),
              });
            }
          }}
        />
      </div>
    </div>
  );
});

export default EditForm;
