import { useContext, useEffect, useState } from "react";
import { fetchData } from "../../Utils/Helper";

import { BsFillFileTextFill, BsTrash } from "react-icons/bs";

import { observer } from "mobx-react-lite";
import "./subdocumentlistpage.css";
import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";

import { DocumentContext } from "../../stores/DocumentStore";
import ReactLoading from "react-loading";

const SubdocumentListPage = observer((props) => {
  const [subDocList, setSubDoc] = useState([]);

  const history = useHistory();

  const documentStore = useContext(DocumentContext);

  useEffect(() => {
    const fetchSubDocs = async () => {
      const [data, err] = await fetchData(
        `/docapi/new-subdocument/subdocumentslist`
      );

      if (err) return;

      setSubDoc(data);
    };
    fetchSubDocs();
  }, [documentStore.subDocUpdate]);

  return (
    <div className="entire-page">
      <div className="page-header">
        <div>Subdocuments</div>
        <Button
          size="small"
          variant="contained"
          disableElevation
          style={{
            marginBottom: "10px",
          }}
          onClick={async (e) => {
            const [data, err] = await fetchData(
              "/docapi/new-subdocument/subdocument",
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
              }
            );

            if (err) return;

            history.push(`/view-sub/${data.id}`);
          }}
        >
          New Subdocument
        </Button>
      </div>

      <div className="list">
        <div className="header">
          <div className="list-icon fixer">
            <BsFillFileTextFill size={30} color={`transparent`} />
          </div>
          <div className="main-title table-heading">Name</div>
          <div className="secondary-title table-heading">Last Modified</div>
          <div className="list-icon">
            <BsTrash size={30} color="transparent" />
          </div>
        </div>
        <div className="list-scroll">
          {subDocList.length > 0 ? (
            subDocList.map((subDoc, ind) => (
              <SubdocumentListItem
                key={ind}
                subDocName={subDoc.name}
                subDocId={subDoc.id}
              />
            ))
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              <ReactLoading type="spokes" color="black" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const SubdocumentListItem = observer(
  ({ subDocName, subDocId, lastUpdated = "01.01.1970" }) => {
    const history = useHistory();

    const documentStore = useContext(DocumentContext);

    return (
      <div className="list-item">
        <div className="list-icon">
          <BsFillFileTextFill size={30} />
        </div>
        <div
          className="vertical-row"
          onClick={(e) => {
            e.preventDefault();
            documentStore.currentSubId = subDocId;
            history.push(`/view-sub/${subDocId}`);
          }}
        >
          <div className="main-title">{subDocName}</div>
        </div>
        <div className="secondary-title">{lastUpdated}</div>
        <div className="list-icon">
          <BsTrash
            size={30}
            onClick={async (e) => {
              // Delete subdocument here...
              const [data, err] = await fetchData(
                `/docapi/new-subdocument/subdocument`,
                {
                  method: "delete",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: subDocId,
                  }),
                }
              );

              if (err) return;

              documentStore.subDocUpdate = !documentStore.subDocUpdate;
            }}
          />
        </div>
      </div>
    );
  }
);

export default SubdocumentListPage;
