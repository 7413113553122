import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { toJS } from "mobx";
import { useSlate } from "slate-react";
import { DocumentContext } from "../../stores/DocumentStore";

import "./conditiontext.css";
import { getSmallestConditionThreadAtTextNode } from "./ConditionUtils";

export const ConditionText = observer((props) => {
  const { conditionThreads, textNode, ...otherProps } = props;

  const documentStore = useContext(DocumentContext);

  const editor = useSlate();

  const onClick = (event) => {
    documentStore.activeConditionThread = getSmallestConditionThreadAtTextNode(
      editor,
      textNode
    );
    documentStore.refresh += 1;
  };

  return (
    <span
      {...otherProps}
      className={`condition ${
        conditionThreads.has(documentStore.activeConditionThread)
          ? "is-active"
          : ""
      }`}
      onClick={onClick}
    >
      {props.children}
    </span>
  );
});
