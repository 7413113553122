import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../stores/DocumentStore";
import Select from "react-select";
import { toJS } from "mobx";
import "./conditionadding.css";

export const ConditionAdding = observer(({ type }) => {
  const documentStore = useContext(DocumentContext);

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setRefresh(refresh + 1);
  }, [documentStore.activeConditionThread]);

  const currentConditions =
    type == "subdocument"
      ? documentStore.conditions[documentStore.activeConditionThread]
          ?.conditions
      : documentStore.mainDocumentConditions[
          documentStore.activeConditionThread
        ]?.conditions;

  const helper = () => {
    return type == "subdocument"
      ? documentStore.conditions[documentStore.activeConditionThread]
          ?.conditions
      : documentStore.mainDocumentConditions[
          documentStore.activeConditionThread
        ]?.conditions;
  };

  return (
    <div>
      {documentStore.activeConditionThread}
      {documentStore.activeConditionThread &&
        helper().map((condition, index) => (
          <ConditionBlock key={index} condition={condition} index={index} />
        ))}
    </div>
  );
});

const ConditionBlock = observer(({ condition, index }) => {
  const documentStore = useContext(DocumentContext);

  const [stateCondition, setStateCondition] = useState({ ...condition });

  useEffect(() => {
    setStateCondition({ ...condition });
  }, [condition]);

  if (condition.mixer) {
    return (
      <div>
        <div>
          <DropDown
            options={["and", "or"]}
            value={stateCondition.mixer}
            onChange={(e) => {
              condition.mixer = { ...e };
              documentStore.refresh += 1;
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="condition-adding-block">
      <div>
        Operator 1
        <DropDown
          options={documentStore.variablesNew.map((x) => x.name)}
          value={stateCondition.operator1}
          onChange={(e) => {
            condition.operator1 = { ...e };

            documentStore.refresh += 1;
          }}
        />
      </div>
      <div>
        Modifier
        <DropDown
          options={["equals", "not equals", "greater than", "lesser than"]}
          value={stateCondition.modifier}
          onChange={(e) => {
            condition.modifier = { ...e };
          }}
        />
      </div>
      <div>
        Operator 2
        <SecondOperator condition={condition} />
      </div>
    </div>
  );
});

const DropDown = ({ options, onChange, value }) => {
  const [dispValue, setValue] = useState(value);

  useEffect(() => {
    setValue({ ...value });
  }, [value]);

  if (!options) return <></>;

  return (
    <Select
      options={options.map((x) => ({ label: x, value: x }))}
      value={dispValue}
      onChange={(e) => {
        setValue({ ...e });
        onChange(e);
      }}
    />
  );
};

const SecondOperator = observer(({ condition }) => {
  const documentStore = useContext(DocumentContext);

  const [stateCondition, setStateCondition] = useState({ ...condition });

  useEffect(() => {
    setStateCondition({ ...condition });
  }, [condition]);

  const checker = documentStore.variablesNew.find(
    (x) => x.name === condition.operator1.value
  );

  // ({ condition: toJS(condition), checker: toJS(checker) });

  if (!checker) return <></>;

  if (
    checker.type == "dropdown" ||
    checker.type == "inlineradio" ||
    checker.type == "radio"
  ) {
    return (
      <DropDown
        options={checker.options.map((x) => {
          return x ?? x.text;
        })}
        value={stateCondition.operator2.value}
        onChange={(e) => {
          condition.operator2 = {
            type: "no-it",
            value: e,
          };
          documentStore.refresh += 1;
        }}
      />
    );
  }

  if (checker.type == "date") {
    return (
      <DropDown
        options={documentStore.variablesNew.map((x) => x.name || x.text)}
        value={stateCondition.operator2.value}
        onChange={(e) => {
          condition.operator2 = {
            type: "variable",
            value: e,
          };
          documentStore.refresh += 1;
        }}
      />
    );
  }

  if (checker.type == "checkbox") {
    console.log("checkbox", toJS(checker.options));
    return (
      <DropDown
        options={checker.options}
        value={stateCondition.operator2}
        onChange={(e) => {
          condition.operator2 = {
            type: "checkbox",
            value: e,
          };
          documentStore.refresh += 1;
        }}
      />
    );
  }

  return (
    <div>
      <input
        type="text"
        value={stateCondition.operator2?.value}
        onChange={(e) => {
          condition.operator2 = {
            type: "text",
            value: e.target.value,
          };
          documentStore.refresh += 1;
        }}
        placeholder="Enter value..."
      />
    </div>
  );
});
