import { useContext, useEffect, useState } from "react";

import { DragList } from "../../Utils/DragList";

import { observer } from "mobx-react-lite";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import "./maindocument.css";
import { DocumentContext } from "../../stores/DocumentStore";

import Select from "react-select";
import Creatable, { useCreatable } from "react-select/creatable";

import { Editor } from "./Editor";
import { toJS } from "mobx";

import { ButtonFetch, fetchData } from "../../Utils/Helper";
import ReactModal from "react-modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const MainDocument = observer((props) => {
  const documentStore = useContext(DocumentContext);

  const [list, setList] = useState([]);
  const [t, te] = useState(1);

  useEffect(() => {
    const getDocumentList = async () => {
      const resp = await fetch(`/docapi/maindocumentlist`);
      const result = await resp.json();

      setList(result.data);
    };

    getDocumentList();
  }, []);

  return (
    <Container className="main-doc-container">
      <Row>
        <Col xs="12">
          <Form className="mb-2">
            <Form.Group as={Row} controlId="selectCases">
              <Form.Label column xs="12" md="2">
                <b>Dokument auswählen</b>
              </Form.Label>
              <Col xs="12" md="10" lg="10">
                <Form.Select
                  onChange={(e) => {
                    documentStore.currentDocumentID = e.target.value;
                  }}
                  value={documentStore.currentDocumentID}
                >
                  <option value="select">Select document ..</option>
                  {list.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.title}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Form>

          {/* <div> */}
          {/* <select
              onChange={(e) => {
                documentStore.currentDocumentID = e.target.value;
                
              }}
            >
              <option value="select">Select...</option>
              {list.map((option, index) => {
                return (
                  <option value={option.id} key={index}>
                    {option.title}
                  </option>
                );
              })}
            </select> */}
          <button
            onClick={() => {
              documentStore.currentDocumentID = "NEW";
              documentStore.currentDocument = { title: "New Document" };
              documentStore.paragraphs = [
                {
                  type: "Plain",
                },
              ];
            }}
          >
            New Document
          </button>
          <button>Delete Document </button>
          {/* </div> */}
          <div>
            <GlobalVariables />
          </div>

          <div>
            <SingleDocument />
          </div>
        </Col>
      </Row>
    </Container>
  );
});

const GlobalVariables = observer((props) => {
  const [vars, setVars] = useState([]);

  const [show, setShow] = useState(false);

  const [newVar, setNew] = useState({ name: "", value: "" });

  useEffect(() => {
    const getGlobals = async () => {
      const res = await fetch("/docapi/globalvariables");
      const data = await res.json();

      setVars(data.data);
    };
    getGlobals();
  }, [show]);

  return (
    <div className="global-main">
      <div>
        <input
          type="text"
          placeholder="Global Name"
          value={newVar.name}
          onChange={(e) => {
            setNew({ value: newVar.value, name: e.target.value });
          }}
        />
        <input
          type="text"
          placeholder="Global Value"
          value={newVar.value}
          onChange={(e) => {
            setNew({ name: newVar.name, value: e.target.value });
          }}
        />
        <ButtonFetch
          initMessage="Save Global Variable"
          successMessage="Saved"
          failMessage="Failed"
          fetchUrl="/docapi/globalvariables"
          fetchOptions={{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...newVar,
            }),
          }}
        />
      </div>
      <button
        onClick={(e) => {
          setShow(!show);
        }}
      >
        Show/Hide Variables
      </button>
      {show ? (
        <div className="global-table">
          <table>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>
                <b>Value</b>
              </td>
            </tr>
            {vars.map((x) => {
              return (
                <tr>
                  <td>{x.name}</td> <td>{x.value}</td>
                </tr>
              );
            })}
          </table>
        </div>
      ) : null}
    </div>
  );
});

const SingleDocument = observer((props) => {
  const documentStore = useContext(DocumentContext);

  const [subDocList, setList] = useState([]);

  useEffect(() => {
    const getSubDocList = async () => {
      const result = await fetch("/docapi/subdocumentlist");
      const resp = await result.json();
      setList(resp.data);
    };

    //getMainDocument();
    getSubDocList();
  }, []);

  useEffect(() => {
    const getMainDocument = async () => {
      if (
        documentStore.currentDocumentID == "select" ||
        documentStore.currentDocumentID == "NEW"
      )
        return;
      const result = await fetch(
        `/docapi/maindocument/${documentStore.currentDocumentID}`
      );
      const resp = await result.json();

      documentStore.paragraphs = resp.data;
    };

    getMainDocument();
  }, [documentStore.currentDocumentID]);

  const addParagraph = () => {
    documentStore.paragraphs = documentStore.paragraphs.concat({
      type: "Plain",
      content: "",
      subdocument: null,
      condition: null,
    });
  };

  const paragraphResolver = (block, index) => {
    return (
      <ParagraphBlock block={block} index={index} subDocList={subDocList} />
    );
  };

  return (
    <div>
      <div>
        <input
          type="text"
          value={documentStore.currentDocument.title}
          onChange={(e) => {
            documentStore.currentDocument.title = e.target.value;
          }}
        />
        <button
          onClick={() => {
            if (documentStore.displaySection == "PARAGRAPHS") {
              documentStore.displaySection = "PREVIEW";
            } else {
              documentStore.displaySection = "PARAGRAPHS";
            }
          }}
        >
          {documentStore.displaySection == "PARAGRAPHS"
            ? "Preview Document"
            : "Exit Preview"}
        </button>

        <ButtonFetch
          fetchUrl="/docapi/maindocument"
          fetchOptions={{
            method: documentStore.currentDocumentID === "NEW" ? "post" : "put",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              docID: documentStore.currentDocumentID,
              title: documentStore.currentDocument.title,
              paragraphs: documentStore.paragraphs,
            }),
          }}
          successMessage="Saved"
          failMessage="Unable to Save"
          initMessage="Save Document"
        />
        <button
          onClick={() => {
            addParagraph();
          }}
        >
          Add Paragraph
        </button>
      </div>

      {documentStore.displaySection === "PARAGRAPHS" ? (
        <div>
          <DragList
            // list={documentStore.paragraphs}
            resolver={paragraphResolver}
          />
        </div>
      ) : (
        <div>
          <Editor />
        </div>
      )}
    </div>
  );
});

const ParagraphBlock = observer((props) => {
  const [subdoc, setSub] = useState("");

  const [vars, setVars] = useState([]);

  const [cond, setCond] = useState([]);

  const [force, setForce] = useState(false);

  const documentStore = useContext(DocumentContext);

  const customStyle = {
    option: (provided, state) => ({
      ...provided,
      width: "400px",
      zIndex: 100,
    }),
  };

  useEffect(() => {
    const getVariables = async () => {
      const result = await fetch("/api/dummyResponses");
      const resp = await result.json();
      var temp = Object.keys(resp).map((x) => {
        return Object.keys(resp[x]).map((y) => {
          //
          return { name: y, value: resp[x][`${y}`] };
        });
      });
      var a = [].concat.apply([], temp);
      setVars(a);
    };
    getVariables();
  }, []);

  useEffect(() => {
    const getSub = async () => {
      if (props.block.subdocument == `nosub`) {
        setSub("");
        return;
      }
      const result = await fetch(
        `/docapi/subdocument/${props.block.subdocument}`
      );
      const resp = await result.json();

      if (!resp.data.paragraphs) {
        setSub(resp.data.content);
      } else {
        setSub(resp.data.paragraphs.map((x) => x.content).join(""));
      }
    };

    getSub();
  }, [props.block.subdocument]);

  return (
    <div className="para-block">
      <div className="para-header">
        <div>
          {props.block.type == "Conditional"
            ? "ⓘ Paragraph will only be loaded if condition is met"
            : ""}
        </div>
      </div>

      <div className="para-options">
        <div className="spread">
          <div className="bold">Type:</div>
          <select
            value={props.block.type}
            onChange={(e) => {
              props.block.type = e.target.value;
            }}
          >
            <option value={"Conditional"}>Conditional</option>
            <option value={"Plain"}>Plain</option>
          </select>
        </div>
        {props.block.type == "Conditional" ? (
          <div>
            <div className="bold">Condition:</div> Operator:{" "}
            <Select
              value={props.block.condition ? props.block.condition[0] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[0] = e;
              }}
              className="z-ind"
              options={["equalto", "greaterthan", "lessthan", "if"].map((x) => {
                return { value: x, label: x };
              })}
            />
            First Variable:
            <Select
              value={props.block.condition ? props.block.condition[1] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[1] = e;
              }}
              className="z-ind"
              options={vars.map((x) => {
                return { value: x.value, label: x.name };
              })}
            />
            Second Variable:{" "}
            <Creatable
              value={props.block.condition ? props.block.condition[2] : ""}
              onChange={(e) => {
                if (!props.block.condition)
                  props.block.condition = ["", "", ""];
                props.block.condition[2] = {
                  label: e.label,
                  value: e.value,
                  custom: e.__isNew__,
                };
              }}
              className="z-ind"
              options={vars.map((x) => {
                return { value: x.value, label: x.name };
              })}
              allowCreateWhileLoading
            />
          </div>
        ) : null}
        <div className="spread">
          <div className="bold">Subdocument: </div>
          <select
            value={props.block.subdocument}
            onChange={(e) => {
              props.block.subdocument = e.target.value;
            }}
          >
            <option value={`nosub`}>None</option>
            {props.subDocList?.map((doc, index) => {
              return (
                <option key={index} value={doc.id}>
                  {doc.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="better-font">
        <div className="bold">Custom Content</div>
        <CKEditor
          editor={ClassicEditor}
          data={`${subdoc ?? props.block.content}`}
          onReady={(editor) => {}}
          onChange={(event, editor) => {
            const data = editor.getData();

            props.block.content = data;
          }}
          disabled
        />
      </div>
    </div>
  );
});

export default MainDocument;
