import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import './ContractModal.css';
import ReactLoading from "react-loading";

var btnStatus = {
  action_1: false, 
  action_2: false,
  action_3: false,
  action_4: false
};
const FormModal = ({ showFormsStatus, setShowFormsStatus, Case }) => {

  const step_to_form_map = {
    1: "Mängel der Unterkunft bzw. Mängel der Kreuzfahrt", 
    2: "Stornierung der Pauschalreise (inkl. Kreuzfahrt)", 
    3: "Flugverspätung", 
    4: "Flugannullierung"
  };
  const [formStatus, setFormStatus] = useState({});
  const [btnDisableStatus, setBtnDisableStatus] = useState(btnStatus);

  useEffect(()=>{

    const InitializeFormStatus = async() => {
      let initialFormStatus = {};

      Object.keys(step_to_form_map).forEach((formId) => {
        initialFormStatus[formId] = {
          name: step_to_form_map[formId],
          status: "---",
          action: ""
        };
      });
      setFormStatus(initialFormStatus);

      const result = await fetch("/api/holidaysherpa/initiateNewField", {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: Case.id,
          fieldName: 'formStatus',
          fieldData: initialFormStatus,
        })
      });
      const resp = await result.json();
      console.log("update form status: ", resp);
    };

    if (typeof Case.id == 'undefined'){
    } else if (typeof Case.formStatus == 'undefined') {
      InitializeFormStatus();
    } else {
      setFormStatus(Case.formStatus);
    }
  }, [Case]);


  const addForm = async (formId) => {
    const btnId = `action_${formId}`;
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/holidaysherpa/formAction`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          formId: formId,
          caseId: Case.id,
          action: 'add'
        })
      });
      const resp = await result.json();
      console.log('add form status', resp);
      if(resp.success) {
        setFormStatus(resp.formStatus);
      }
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const removeForm = async(formId) => {
    const btnId = `action_${formId}`;
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/holidaysherpa/formAction`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          formId: formId,
          caseId: Case.id,
          action: 'remove'
        })
      });
      const resp = await result.json();
      console.log('form remove status', resp);
      if(resp.success) {
        setFormStatus(resp.formStatus);
      }
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const spinner = (bool) => {
    return (
      bool
        ? <ReactLoading type="spokes" color="black" className='ms-2' height="22px" width="22px"/>
        : <></>
    );
  };

  return (
    <Modal show={showFormsStatus} className="contract">
      <div className="p-4">
        <h4 className="mb-3"><b> Form Status: {Case.id} </b></h4>

        <div className="contract-body">
          <Table className="table table-striped">
            <thead>
              <tr key="0">
                <th scope="col" width="10%">Form Id</th>
                <th scope="col" width="30%">Form Name</th>
                <th scope="col" width="30%">Action</th>
                <th scope="col" width="30%">Status</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(formStatus).map((formId) => {
                  return (
                    <tr key={formId}>
                      <th scope="row" width="10%">{formId}</th>
                      <td width="30%"> {formStatus[formId].name} </td>
                      <td width="30%"> 
                        <button
                          className='px-4'
                          disabled={ btnDisableStatus[`action_${formId}`] || (formStatus[formId].status == 'completed') }
                          onClick= {() => {
                            formStatus[formId].action != 'added' ? addForm(formId) : removeForm(formId);
                          }}
                        >
                          <div className='d-flex'>
                            { formStatus[formId].action != 'added' ? "Add" : "Remove" }
                            { spinner(btnDisableStatus[`action_${formId}`]) }
                          </div>
                        </button>
                      </td>
                      <td width="30%"> {formStatus[formId].status} </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>

        <div className="d-flex mt-2">
          <button className="contract-btn" onClick={() => setShowFormsStatus(false)} >Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;

