import "./questionarea.css";
import React, { useContext, useEffect } from "react";
import PreviewElementList from "./PreviewElementList";
import { ClientContext } from "../../stores/ClientStore";
import { observer } from "mobx-react-lite";

import { EditContext } from "../../stores/EditStore";

const Preview = observer((props) => {
  const clientStore = useContext(ClientContext);
  const editStore = useContext(EditContext);

  useEffect(() => {
    editStore.activeQuestion = 0;
  }, []);

  const resolve = () => {
    let ques = [];
    for (let i = 0; i < editStore.elements.length; i++) {
      //  if (i == 0) editStore.currentQuestion = editStore.elements[0];
      ques.push(
        <PreviewElementList
          id={i.toString()}
          questionId={editStore.elements[i]}
          totalQuestions={editStore.elements.length}
          preview={true}
          nextFunction={async (e) => {}}
          prevFunction={async (e) => {}}
        />
      );
    }
    return <>{ques}</>;
  };

  return (
    <div className="question-area-container">
      <div className="q-list">
        <div className="question-element activeQues">
          <div className="question-flex-buttons">{resolve()}</div>
        </div>
      </div>
    </div>
  );
});

export default Preview;
