import {useLocalStore} from "mobx-react-lite";
import {createContext} from "react";

export const createSimilarityStore = () => {
    return {
        currentVarSelected: "select",
        currentCaseData: {},
        allVarsExportedFromFlows: [],
        allSimilarityCases: [],

        get remainingVariables() {
            let data = [];
            this.allVarsExportedFromFlows.forEach((v) => {
                if(this.allSimilarityCases.findIndex((s) => s.rawName == v) == -1) {
                    data.push(v);
                }
            });
            return data;
        }
    };
}

export const SimilarityStoreContext = createContext(null);

export const SimilarityEngineProvider = ({ children }) => {
    const similarityStore = useLocalStore(createSimilarityStore);

    return (
        <SimilarityStoreContext.Provider value={similarityStore}>{ children }</SimilarityStoreContext.Provider>
    );
}