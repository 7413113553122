import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../stores/AdminStore";
import { fetchData } from "../../Utils/Helper";
import "./displayresponses.css";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import ResolveElement from "../Flow/ResolveElement";
import { createClientStore } from "../../stores/ClientStore";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const DisplayResponses = observer(() => {
  const adminStore = useContext(AdminContext);
  var id;

  const downloadFiles = async ()=>{
    const result = await fetch(`/api/admin/downloadFiles/uploads/${id}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    const blob = await result.blob();
    download(blob);
  }

  function download(data) {
    const url = URL.createObjectURL(new Blob([data]));
    const a = document.createElement('a');
    a.download = `${id}.zip`;
    a.href = url;
    a.target = '_self';
  
    a.click();
  
    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }

  const updateStatus = async (status) => {
    const result = await fetch("/api/admin/updateStatus", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id : id,
        dest: 'main-website',
        status : status
      }),
    });

    const resp = await result.json();
    // console.log(resp);
  };

  if (!adminStore.currentCase.formsUsed) return <></>;

  useEffect(() => {
    adminStore.responses = adminStore.currentCase.responses;
    id =
      `${adminStore.responses.firstName}${adminStore.responses.lastName}${adminStore.responses.ReiseveranstalterNameGeneric}`.replace(
        / /g,
        "_"
      );
  }, []);

  return (
    <Container>
      <Row className="justify-content-center" style={{ width: '100%'}}>
        <Col xs="12" md="12" lg="12">
          {adminStore.currentCase.formsUsed.map((form) => {
            return (
              <IndividualForm
                id={form}
                responses={adminStore.currentCase.responses}
              />
            );
          })}
        </Col>
      </Row>

      <Row className="my-4">
        <Col xs="12" md="4" lg="4">
          <div className="d-flex">
            <button className="btn btn-primary" 
              onClick={downloadFiles}
            >
              Download Files
            </button>
          </div>
        </Col>
        <Col xs="12" md="8" lg="8">
          <div className="d-flex flex-row-reverse">
            <button className="btn btn-primary" 
              onClick={()=> updateStatus('Resolve')}
            >
              Resolve
            </button>
            <button className="btn btn-primary" 
              onClick={()=> updateStatus('In Progress')}
            >
              In Progress
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

const IndividualForm = observer(({ id, responses }) => {
  const [questionList, setQuestions] = useState([]);
  const [formName, setFormName] = useState(null);

  const adminStore = useContext(AdminContext);

  useEffect(() => {
    const getForm = async () => {
      if (!id) return;
      const [data, error] = await fetchData(`/flowapi/form/${id}`);
      if (error) return;

      setQuestions([...data.questionList]);
      setFormName(data.formName);
    };

    getForm();
  }, []);

  console.log({ questionList });

  return (
    <>
      <div style={{ fontSize: "120%", fontWeight: "bold" }}>{formName}:</div>
      <div>
        {questionList?.map((question) => {
          return (
            <ResolveElement
              questionId={question}
              adminStore={adminStore}
              disabled={true}
            />
          );
        })}
      </div>
    </>
  );
});

const IndividualQuestion = observer(({ id, response }) => {
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    console.log({ id, response });

    const getQuestion = async () => {
      const [data, error] = await fetchData(`/flowapi/question/${id}`);
      if (error) return;
      setQuestion({ ...data });
    };

    getQuestion();
  }, []);

  //if (!question) return <></>;

  console.log({ question });

  return response ? (
    <div>
      <div style={{ fontWeight: "bold", fontSize: "120%", marginTop: "15px" }}>
        {question?.text}
      </div>
      <div>
        {(() => {
          if (typeof response == "object") {
            return Object.keys(response)
              .map((x) => {
                return `${x} : ${response[x]}`;
              })
              .join("\n");
          }
          return response;
        })()}
      </div>
    </div>
  ) : null;
});
