import { jsx } from "slate-hyperscript";

import escapeHtml from "escape-html";
import { Text } from "slate";

import { v4 as uuid } from "uuid";

const convertToSymbol = (modifier) => {
  switch (modifier) {
    case "not equals":
    case "equals":
      return "==";
    case "greater than":
      return ">";
    case "lesser than":
      return "<";
    case "and":
      return "&&";
    case "or":
      return "||";
  }
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const convertSecondOperator = (operator2) => {
  if (operator2.type == "variable") {
    return `it.${operator2.value}`;
  }

  if (isNumeric(operator2.value)) {
    return operator2.value;
  }
  return `"${operator2.value}"`;
};

const conditionThreadToString = (condition, index) => {
  ////

  var output = ``;

  condition.conditions.map((cnd, i) => {
    if (cnd.mixer) {
      output += convertToSymbol(cnd.mixer);
      return;
    }

    const wrapIf = (str) => {
      return `{{@if(${str})}}`;
    };

    const wrapNot = (str) => {
      return `!(${str})`;
    };

    if (cnd.operator2.type == "checkbox") {
      output += `it.${cnd.operator1.value} && it.${cnd.operator1.value}.${cnd.operator2.value}`;
    } else {
      output += `it.${cnd.operator1.value} ${convertToSymbol(
        cnd.modifier.value
      )} ${convertSecondOperator(cnd.operator2)}`;
    }

    if (cnd.modifier.value == "not equals") {
      output = wrapNot(str);
    }

    output = wrapIf(output);
  });

  return output;
};

const conditionToBorderHelper = (props) => {
  const { node, conditions } = props;

  Object.keys(node).map((opt, ind) => {
    if (opt.startsWith("condition")) {
      conditionLists.push({
        ...conditions[opt.split("condition_")[1]],
      });
    }
  });

  var output = ``;

  output += conditionLists.map(conditionThreadToString);

  return output;
};

const conditionToTemplateHelper = (props) => {
  const { node, conditions } = props;

  var conditionLists = [];

  Object.keys(node).map((opt, ind) => {
    if (opt.startsWith("condition")) {
      conditionLists.push({
        ...conditions[opt.split("condition_")[1]],
      });
    }
  });

  var output = ``;

  output += conditionLists.map(conditionThreadToString);

  output += node.text;

  conditionLists.map((condition, ind) => {
    output += `{{/if}}`;
  });

  return output;
};

export const serialize = (props) => {
  const { node, conditions } = props;

  if (!node) return;

  if (Text.isText(node)) {
    let newNode = { ...node };

    newNode.text = escapeHtml(node.text);

    if (node.mention) {
      newNode.text = `{{it.${newNode.text}}}`;
    }

    if (node.bold) {
      newNode.text = `<strong>${newNode.text}</strong>`;
    }
    if (node.italics) {
      newNode.text = `<i>${newNode.text}</i>`;
    }
    if (node.underlined) {
      newNode.text = `<u>${newNode.text}</u>`;
    }

    return conditionToTemplateHelper({ node: newNode, conditions: conditions });
  }

  const children = node.children
    ?.map((n) => serialize({ node: n, conditions: conditions }))
    .join("");

  //

  switch (node.type) {
    case "subdocument-border":
      return `${conditionToBorderHelper({
        node: node,
        conditions: conditions,
      })}`;
    case "subdocument-border-end":
      return `{{/if}}`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export const deserialize = (el, markAttr = {}, changeThis) => {
  if (el.nodeType == Node.TEXT_NODE) {
    return jsx("text", markAttr, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttr = { ...markAttr };

  switch (el.nodeName) {
    case "strong":
      nodeAttr.bold = true;
  }

  var children;

  if (el.nodeName == "MARK") {
    var conditionId = uuid();

    const conditionFromClass = (str) => {
      var condition = {
        modifier: "equal",
        operator1: {},
        operator2: {},
      };

      var change = "";

      if (str.includes("&gt;") || str.includes(">")) {
        condition.modifier = { label: "greater than", value: "greater than" };
        change = ">";
      }

      if (str.includes("==")) {
        condition.modifier = { label: "equals", value: "equals" };
        change = "==";
      } else if (str.includes("=")) {
        condition.modifier = { label: "equals", value: "equals" };
        change = "=";
      }

      if (str.includes("&lt;") || str.includes("<")) {
        condition.modifier = { label: "lesser than", value: "lesser than" };
        change = "<";
      }

      if (!change) {
        if (str.split(".").length > 2) {
          condition.operator2 = {
            value: str.split(".")[2].trim(),
            label: str.split(".")[2].trim(),
          };
        } else {
          condition.operator2 = {
            value: "select",
            lable: "select",
          };
        }
        condition.operator1 = {
          value: str.split(".")[1].trim(),
          label: str.split(".")[1].trim(),
        };
      } else {
        const temp = str.split(change)[1].trim().split("it.");
        condition.operator2 = {
          value: temp[temp.length - 1],
          label: temp[temp.length - 1],
        };

        condition.operator1 = {
          value: str.split(change)[0].trim().split("it.")[1],
          label: str.split(change)[0].trim().split("it.")[1],
        };
      }

      return condition;
    };

    if (!(el.className.includes("&&") || el.className.includes("||"))) {
      //return children;
      const conditionThread = conditionFromClass(el.className);

      changeThis[`${conditionId}`] = { conditions: [conditionThread] };

      children = Array.from(el.childNodes)
        .map((node) =>
          deserialize(
            node,
            { ...nodeAttr, [`condition_${conditionId}`]: true },
            changeThis
          )
        )
        .flat();
    }
  }

  if (!children) {
    children = Array.from(el.childNodes)
      .map((node) => deserialize(node, nodeAttr, changeThis))
      .flat();
  }

  if (children.length === 0) {
    children.push(jsx("text", nodeAttr, ""));
  }

  if (el.className == "mention") {
    return jsx(
      "text",
      {
        mention: true,
        ...nodeAttr,
      },
      el.innerHTML.slice(1)
    );
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );

    default:
      return children;
  }
};
