import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { ClientContext } from "../../../stores/ClientStore";
import {
  FaInfoCircle
} from "react-icons/fa";
import ResolveElement from "../ResolveElement";
import { observer } from "mobx-react-lite";
import Table from "react-bootstrap/esm/Table";
import { PrimaryButton } from "../../../Utils/Helper";
import { CacheContext } from "../../../stores/CacheStore";
import { toJS } from "mobx";



export const ResolveTable = observer((props) => {
  const { question } = props;

  const [showEntries, setShow] = useState(false);
  const [entryIndex, setEntryIndex] = useState(null);

  return (
    <div>
      <EntryComponent
        showEntries={showEntries}
        setShow={setShow}
        question={question}
        entryIndex={entryIndex}
        setEntryIndex={setEntryIndex}
        adminStore={props.adminStore}
      />
      <DisplayEntered
        showEntries={showEntries}
        setShow={setShow}
        question={question}
        setEntryIndex={setEntryIndex}
        adminStore={props.adminStore}
      />
    </div>
  );
});

const EntryComponent = observer(
  ({ showEntries, question, setShow, ...props }) => {
    const clientStore = useContext(ClientContext);
    const cacheStore = useContext(CacheContext);

    const getStore = () => (props.adminStore ? props.adminStore : clientStore);

    const [showCanvas, setShowCanvas] = useState(showEntries);

    useEffect(() => {
      showEntries;
      setShowCanvas(showEntries);
    }, [showEntries]);

    const validateEntry = (heading, response) => {
      if (!response && heading.validation.required) {
        getStore().errorMessages[heading.name] =
          "Dieses Feld ist ein Pflichtfeld";
        return false;
      }

      if (!heading.validation || (!heading.validation.required && ((typeof response == 'undefined') || (heading.type == "checkbox")))) {
        return true;
      }

      if (heading.type == "text") {
        if (heading.validation.type == "numeric") {
          const isNumeric = (str) => {
            if (typeof str != "string") return false;
            return !isNaN(str) && !isNaN(parseFloat(str));
          };

          if (!isNumeric(str)) {
            getStore().errorMessages[heading.name] =
              "Eintrag im Textfeld muss nummerisch sein";
            return false;
          }
        }
      }

      if (["radio", "inlineradio", "dropdown"].includes(heading.type)) {
        let flag = true
        heading.options.map((opt) => {
          if ((opt.text == response) && (opt.triggers?.length >= 1)) {
          // if (opt.triggers?.length >= 1) {
            opt.triggers.map((questions) => {
              questions.questions.map((question) => {
                console.log(question, "qstnnnn")
                if (!question.validation.regex) return true;

                const b = question.validation.regex.split(" ");
                const c = question.validation.regex.split("/");

                const a = c.length > 1 ? c : b;

                console.log("a: ", a, "b: ", b, "c: ", c)

                for (var x = 0; x < a.length; x++) {
                  a[x] = a[x].trim();
                }

                const convert = (str) => {
                  if (!str) return "1970-01-20";
                  return str.split(".").reverse().join("-");
                };

                let iter = parseInt(`${a.length / 2}`);
                for (let k = 0; k < iter; k++) {
                  if (a[k * 2] == "greater") {
                    let greaterArray = a[k * 2 + 1];
                    if (a[k * 2 + 1][0] == "[") {
                      greaterArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
                    }

                    let greaterArrayData = greaterArray.split(",");
                    var d1 = Date.parse(
                      convert(getStore().currentResponses()[question.name])
                    );
                    for (let i = 0; i < greaterArrayData.length; i++) {
                      var temp = greaterArrayData[i].split(":");
                      let date2 = getStore().currentResponses()[temp[0]?.trim()] ? getStore().currentResponses()[temp[0]?.trim()] : getStore().currentResponses()[cacheStore.questionIdFromName(temp[0]?.trim())];
                      if (date2) {
                        var d2 = Date.parse(
                          convert(date2)
                        );

                        if (d1 >= d2) {
                          continue;
                        } else {
                          console.log("herrr")
                          getStore().errorMessages[question.name] =
                            temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";
                          flag = false
                          return false;
                        }
                      }
                    }
                  }

                  if (a[k * 2 + 0] == "lesser") {
                    let lesserArray = a[k * 2 + 1];
                    if (a[k * 2 + 1][0] == "[") {
                      lesserArray = a[k * 2 + 1].substring(1, a[k * 2 + 1].length - 1);
                    }

                    let lesserArrayData = lesserArray.split(",");
                    var d1 = Date.parse(
                      convert(getStore().currentResponses()[question.name])
                    );
                    for (let i = 0; i < lesserArrayData.length; i++) {
                      var temp = lesserArrayData[i].split(":");
                      let date2 = getStore().currentResponses()[temp[0]?.trim()] ? getStore().currentResponses()[temp[0]?.trim()] : getStore().currentResponses()[cacheStore.questionIdFromName(temp[0]?.trim())];
                      if (date2) {
                        var d2 = Date.parse(
                          convert(date2)
                        );
                        if (d1 <= d2) {
                          continue;
                        } else {
                          getStore().errorMessages[question.name] =
                            temp.length > 1 ? temp[1]?.trim() : "Ungültiges Datum";
                          flag = false
                          return false;
                        }
                      }
                    }
                  }
                }

                if (a[0] == "between") {
                  var d1 = Date.parse(
                    convert(getStore().currentResponses()[question.name])
                  );
                  let date2 = getStore().currentResponses()[a[1]?.trim()] ? getStore().currentResponses()[a[1]?.trim()] : getStore().currentResponses()[cacheStore.questionIdFromName(a[1]?.trim())];
                  var d2 = Date.parse(convert(date2));
                  let date3 = getStore().currentResponses()[a[2]?.trim()] ? getStore().currentResponses()[a[2]?.trim()] : getStore().currentResponses()[cacheStore.questionIdFromName(a[2]?.trim())];
                  var d3 = Date.parse(convert(date3));

                  if (d1 >= d2 && d1 <= d3) {
                    getStore().errorMessages[question.name] = "";
                    return true;
                  } else {
                    getStore().errorMessages[question.name] = "Ungültiges Datum";
                    flag = false
                    return false;
                  }
                }
                getStore().errorMessages[question.name] = "";
                return true;
              })
            })
          }
        })
        if (!flag) {
          return false
        }
      }

      if (heading.type == "checkbox") {
        if (!response) {
          getStore().errorMessages[heading.name] =
            "Dieses Feld ist ein Pflichtfeld";
          return false;
        }
        const somethingEntered = Object.entries(response)
          .map((row) => {
            return row[1];
          })
          .every((x) => x);

        if (!somethingEntered) {
          getStore().errorMessages[heading.name] =
            "Dieses Feld ist ein Pflichtfeld";
          return false;
        }

        getStore().errorMessages[heading.name] = null;
        return true;
      }

      if (heading.type == "date" || heading.type == "time") {
        const allResponses = Object.keys(getStore().responses).reduce(
          (former, key) => ({ ...former, ...getStore().responses[key] }),
          {}
        );

        allResponses;

        const convertToArray = heading.validation.regex
          .split("/")
          .map((term) => {
            return term.trim();
          });

        const convert = (str) => {
          if (heading.type == "date") {
            if (!str) return "1970-01-20";
            return str.split(".").reverse().join("-");
          }

          if (heading.type == "time") {
            return `21st July 1969 ${str} GMT`;
          }
        };

        getStore().errorMessages[heading.name] = "";

        //(convertToArray);

        let valBool = [];

        for (let ind = 0; ind < convertToArray.length; ind++) {
          if (
            convertToArray[ind] == "greater" ||
            convertToArray[ind] == "lesser"
          ) {
            ind, convertToArray[ind];
            valBool = convertToArray[ind + 1]
              .slice(1, convertToArray[ind + 1].length - 1)
              .split(",")
              .map((condition) => {
                const [variable, message] = condition.split(":");

                var date1 = Date.parse(convert(response));

                const checkingDate =
                  allResponses[cacheStore.questionIdFromName(variable)] ||
                  getStore().currentResponses()[variable];

                if (!checkingDate) return true;

                var date2 = Date.parse(convert(checkingDate));

                "date val",
                  convertToArray[ind],
                  variable,
                  date1,
                  date2,
                  date1 < date2,
                  date1 > date2;

                if (convertToArray[ind] == "greater" && date1 < date2) {
                  getStore().errorMessages[heading.name] =
                    message ?? "Ungültiges Datum";
                  return false;
                }

                if (convertToArray[ind] == "lesser" && date1 > date2) {
                  getStore().errorMessages[heading.name] =
                    message ?? "Ungültiges Datum";
                  return false;
                }
              });

            valBool = valBool.map((x) => (x == undefined ? true : x));
            valBool;
          }
          heading.name, valBool, !valBool.every((x) => x);

          if (!valBool.every((x) => x)) {
            return false;
          }
        }
      }

      getStore().errorMessages[heading.name] = null;
      return true;
    };

    const handleHinzufuen = () => {
      //Add entry to the state
      const validationBools = question.headings.map((heading, index) => {
        if (
          !validateEntry(heading, getStore().currentResponses()[heading.name])
        )
          return false;

        return true;
      });

      if (validationBools.every((x) => x)) {
        var response = {};

        question.headings.map((heading, index) => {
          response = {
            ...response,
            [`${heading.name}`]: getStore().currentResponses()[heading.name],
            datumBugs: getStore().currentResponses()["datumBugs"],
            deficiencyDateOfOccurenceStart:
              getStore().currentResponses()["deficiencyDateOfOccurenceStart"],
            deficiencyDateOfOccurenceEnd:
              getStore().currentResponses()["deficiencyDateOfOccurenceEnd"],
          };
        });

        var temp = getStore().currentResponses();

        if (!temp[question.id]) {
          temp[question.id] = [];
        }

        if (!props.entryIndex && props.entryIndex != 0) {
          temp[question.id].push({ ...response });
        } else {
          temp[question.id][props.entryIndex] = { ...response };
        }

        getStore().responses[getStore().activeTab[1].formName] = { ...temp };
        setShowCanvas(false);
        setShow(false);
        props.setEntryIndex(null);

        question.headings.map((heading, index) => {
          toJS(getStore().currentResponses()[heading.name]);
          getStore().currentResponses()[heading.name] = null;
        });
      }

      console.log(toJS(getStore().currentResponses()));
      "debug", { heading: question.headings };
    };

    return (
      <div>
        <Offcanvas
          show={showCanvas}
          placement="end"
          onHide={() => {
            setShowCanvas(false);
            setShow(false);
          }}
          backdrop={false}
        >
          <Offcanvas.Header closeButton={true}>Daten eingeben</Offcanvas.Header>

          <Offcanvas.Body>
            <div>
              {props.entryIndex >= 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    var temp = getStore().currentResponses();

                    temp[question.id].splice(props.entryIndex, 1);

                    getStore().responses[getStore().activeTab[1].formName] = {
                      ...temp,
                    };
                    setShowCanvas(false);
                    setShow(false);
                    props.setEntryIndex(null);
                  }}
                >
                  Löschen
                </button>
              )}
            </div>
            <Container>
              <Row>
                {question?.headings?.map((heading, suffix) => {
                  return (
                    <Col xs="12">
                      <ResolveElement
                        parentType={"question"}
                        rawElement={heading}
                        shouldNotScroll={true}
                        adminStore={props.adminStore}
                        tableNameIfTable={question?.name}
                      />
                    </Col>
                  );
                })}
                <Col xs="12">
                  <PrimaryButton onClick={() => handleHinzufuen()}>
                    Hinzufügen
                  </PrimaryButton>
                </Col>
              </Row>
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  }
);


const DisplayEntered = observer(
  ({ question, showEntries, setShow, setEntryIndex, ...props }) => {
    const clientStore = useContext(ClientContext);

    const getStore = () => (props.adminStore ? props.adminStore : clientStore);

    const [showDisclaimer, setDisclaimer] = useState(false);
    const DisclaimerElement = () => {
      if (!showDisclaimer) return <></>;
      return question.disclaimer && <div>{question.disclaimer}</div>;
    };

    return (
      <>
        <div className="individual-question" id={question.name}>
          <b>{question.text}</b>
          {question.disclaimer}
          <div>
            <Table>
              <thead>
                <tr>
                  {question?.headings &&
                    question?.headings.map((questionHeading) => {
                      return (
                        <th key={questionHeading.title}>
                          {questionHeading.title}
                        </th>
                      );
                    })}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {getStore().currentResponses()[question.id] &&
                  getStore()
                    .currentResponses()
                  [question.id].map((entry, index) => {
                    return (
                      <tr>
                        {question.headings.map((heading) => {
                          if (!entry[heading.name]) return <td>-</td>;

                          console.log(heading.name);

                          if (heading.type == "checkbox") {
                            return (
                              <td>
                                {Object.keys(entry[heading.name])
                                  .filter((x) => entry[heading.name][x])
                                  .map((x) => (
                                    <li>{x}</li>
                                  ))}
                              </td>
                            );
                          }

                          // // Check if question has subquestions and list all of them I guess

                          if (
                            heading.name == "occurrence" &&
                            entry[heading.name] == "In folgendem Zeitraum"
                          ) {
                            return (
                              <td>
                                {entry["deficiencyDateOfOccurenceStart"]} -{" "}
                                {entry["deficiencyDateOfOccurenceEnd"]}
                              </td>
                            );
                          }

                          return <td>{entry[heading.name] ?? " - "}</td>;
                        })}
                        <td>
                          <button
                            onClick={(e) => {
                              e.preventDefault();

                              question.headings.map((heading) => {
                                const temp = getStore().currentResponses();
                                temp[heading.name] = entry[heading.name];
                                getStore().responses[
                                  getStore().activeTab[1].formName
                                ] = { ...temp };

                                setEntryIndex(index);
                                setShow(true);
                                console.log("edit component...", index);
                              });
                            }}
                          >
                            Bearbeiten
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <PrimaryButton
            variant="primary"
            onClick={(event) => {
              "showing", showEntries;
              setShow(true);
            }}
          >
            Daten eingeben
          </PrimaryButton>
        </div>
      </>
    );
  }
);
