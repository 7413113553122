import { useLocalStore } from "mobx-react-lite";
import { createContext } from "react";

export const createCacheStore = () => {
  return {
    questionCache: {},

    elementsCache: {},

    questionCached(id) {
      if (this.questionCache[id]) {
        return this.questionCache[id];
      }
      return 0;
    },

    questionIdFromName(name) {
      return Object.keys(this.questionCache).find(
        (quest) => this.questionCache[quest].name == name
      );
    },

    questionNameFromId(id) {
      return this.questionCached(id).name;
    },

    updateQuestionCached(id, question) {
      this.questionCache[id] = { ...question };
    },

    elementsCached(id) {
      if (this.elementCache[id]) return this.elementCache[id];
      return 0;
    },

    updateElementsCached(id, elements) {
      this.questionCache[id] = [...elements];
    },
  };
};

export const CacheContext = createContext(null);

export const CacheProvider = ({ children }) => {
  const cacheStore = useLocalStore(createCacheStore);

  return (
    <CacheContext.Provider value={cacheStore}>{children}</CacheContext.Provider>
  );
};
