import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import de from 'react-phone-number-input/locale/de.json';

function ContactInput({value, setValue, ...props}) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  return (
    <PhoneInput
      id= {props.id ? props.id : 'phoneId'}
      placeholder="Telefonnummer eingeben"
      value={value}
      onChange={setValue}
      labels={de}
    />
  )
}

export default ContactInput;
