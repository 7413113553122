import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import { Editable, withReact, useSlate } from "slate-react";
import * as SlateReact from "slate-react";

import { useSelected } from "slate-react";

import { useFocused } from "slate-react";

import {
  Transforms,
  Editor,
  Range,
  createEditor,
  Editor as ReactEditor,
  Element as SlateElement,
  Descendant,
} from "slate";
import { withHistory } from "slate-history";

import { isKeyHotkey } from "is-hotkey";

import { cx, css } from "@emotion/css";

import { getConditionsOnTextNode } from "./ConditionUtils";
import { ConditionText } from "./ConditionText";

import { observer } from "mobx-react-lite";
import { DocumentContext } from "../../stores/DocumentStore";

import {
  AddConditionButton,
  AdditionalToolbar,
  SerializeButton,
  EditorToolbar,
  AddSubdocumentButton,
  RemoveConditionButton,
} from "./ToolbarUtils";

import SoftBreak from "slate-soft-break";

import "./sklate.css";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaList,
  FaAlignJustify,
  FaHeading,
} from "react-icons/fa";
import { fetchData } from "../../Utils/Helper";
import { serialize } from "./serializingUtils";
import { toJS } from "mobx";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const plugins = [SoftBreak({ shift: true })];

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const withInLines = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    ["conditional", "button"].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    insertText(text);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    insertData(data);
  };

  return editor;
};

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  return editor;
};

const insertMention = (editor, character) => {
  Editor.addMark(editor, "mention", true);
  Transforms.insertText(editor, character);
  Transforms.move(editor);
  Transforms.insertText(editor, " ");
};

const Sklate = (props) => {
  const ref = useRef();

  const [value, setValue] = useState(props.value);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { SecondComponent = () => <div>Second Component Here</div> } = props;

  const editor = useMemo(
    () => withInLines(withMentions(withHistory(withReact(createEditor())))),
    []
  );

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const chars = props.mentionList
    ?.map((x) => x.name)
    .filter((x) => !!x)
    .filter(onlyUnique)
    .filter((c) => c.toLowerCase().startsWith(search.toLowerCase()))
    .slice(0, 10);

  const onKeyDown = (event) => {
    const { selection } = editor;

    if (target) {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
          setIndex(prevIndex);
          break;
        case "ArrowUp":
          event.preventDefault();
          const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case "Enter":
          event.preventDefault();
          Transforms.select(editor, target);
          insertMention(editor, chars[index]);
          setTarget(null);
          break;
        case "Escape":
          event.preventDefault();
          setTarget(null);
          break;
      }
    }

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isKeyHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    }
  };

  const slateOnChange = (value) => {
    setValue(value);

    props.onChange(value);

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: "word" });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  return (
    <div className="editor-subdoc">
      <SlateReact.Slate editor={editor} value={value} onChange={slateOnChange}>
        <EditorToolbar className="editor-toolbar">
          <SerializeButton value={value} />
          <AddConditionButton />
          <RemoveConditionButton />
          <AddSubdocumentButton />
          <MarkButton format="bold" Icon={FaBold} />
          <MarkButton format="italic" Icon={FaItalic} />
          <MarkButton format="underline" Icon={FaUnderline} />
          <BlockButton format="heading-one" Icon={FaHeading} />
          <BlockButton format="numbered-list" Icon={FaList} />
          <BlockButton format="left" Icon={FaAlignLeft} />
          <BlockButton format="center" Icon={FaAlignCenter} />
          <BlockButton format="right" Icon={FaAlignRight} />
          <BlockButton format="justify" Icon={FaAlignJustify} />
          <ChangeFontSizeButton />
          <div className="spacer" />
        </EditorToolbar>

        <AdditionalToolbar type={props.additionalToolbar} />

        <div className="white-behind">
          <Editable
            className="workarea"
            spellCheck="false"
            renderElement={(props) => <RenderElement {...props} />}
            renderLeaf={(props) => <RenderText {...props} />}
            placeholder="Enter Text..."
            onKeyDown={onKeyDown}
            plugins={plugins}
          />

          {/* <div className="second-component">
            <SecondComponent />
          </div> */}
        </div>
        {target && chars.length > 0 && (
          <div
            ref={ref}
            style={{
              top: "50%",
              left: "50%",
              position: "absolute",
              zIndex: 1000,
              padding: "3px",
              background: "white",
              borderRadius: "4px",
              boxShadow: "0 1px 5px rgba(0,0,0,.2)",
            }}
            data-cy="mentions-portal"
          >
            {chars.map((char, i) => {
              return (
                <div
                  key={char}
                  style={{
                    padding: "1px 3px",
                    borderRadius: "3px",
                    background: i === index ? "#B4D5FF" : "transparent",
                    zIndex: 100,
                  }}
                >
                  {char}
                </div>
              );
            })}
          </div>
        )}
      </SlateReact.Slate>
    </div>
  );
};

const SubdocumentBlock = observer(({ attributes, children, element }) => {
  const { subDocId } = element;

  const [stuff, setStuff] = useState([{ text: "Loading..." }]);
  const editor = useMemo(() => withReact(createEditor()), []);

  useEffect(() => {
    const getSubDoc = async () => {
      const [subdoc, err] = await fetchData(
        `/docapi/new-subdocument/subdocument/${subDocId}`
      );

      if (err) return;

      editor.children = subdoc.value;
      setStuff(subdoc.value);
    };

    getSubDoc();
  }, []);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        padding: "3px 3px 2px",
        margin: "0 1px",
        verticalAlign: "baseline",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: "#eee",
        fontSize: "0.9em",
        boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
      }}
    >
      @{subDocId}
      {children}
    </span>
  );
});

const RenderElement = (props) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  switch (element.type) {
    case "subdocument":
      return <SubdocumentBlock {...props}>{children}</SubdocumentBlock>;
    case "subdocument-border":
      return <p {...attributes}>{children}</p>;
    case "subdocument-border-end":
      return <p {...attributes}>{children}</p>;
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "footer":
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const RenderText = observer((props) => {
  var { attributes, children, leaf } = props;

  const documentStore = useContext(DocumentContext);

  const conditionThreads = getConditionsOnTextNode(leaf);
  // documentStore.activeConditionThread = null;

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.mention) {
    children = <span className="mention">{children}</span>;
  }

  if (conditionThreads.size > 0) {
    const colorList = ["#9ACD32", "#E6E6FA", "#DDA0DD"];

    return (
      <ConditionText
        {...attributes}
        conditionThreads={conditionThreads}
        textNode={leaf}
        style={{
          backgroundColor: `${
            colorList[conditionThreads.size % colorList.length]
          }`,
        }}
      >
        {children}
      </ConditionText>
    );
  }

  return (
    <span
      className={
        leaf.text === ""
          ? css`
              padding-left: 0.1px;
            `
          : null
      }
      {...attributes}
      onClick={(e) => {
        documentStore.activeConditionThread = null;
      }}
    >
      {children}
    </span>
  );
});

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const ChangeFontSizeButton = () => {
  const editor = useSlate();
  
  return (
    <select 
      onChange={(e) => {
        return;
        e.preventDefault();
        console.log('size - ', e.target.value);
        let fontSize = e.target.value;
        if (editor.selection && Range.isCollapsed(editor.selection)) {
          console.warn("No text selected!");
          return;
        }
      
        Transforms.setNodes(editor, { style: { fontSize:  fontSize }});
      }}
      style={{
        width: "100px"
      }}
    >
      {
        Array.from("a".repeat(45)).map((_, i) => {
          return <option value={`${i+4}px`}> {i+4}px</option>
        })
      }
    </select>
  );
}

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const MarkButton = ({ format, Icon, customFunction }) => {
  const editor = useSlate();

  return (
    <Icon
      className={`toolbar-button ${isMarkActive(editor, format) ? "active-toolbar-button" : "passive-toolbar-button" }`}
      onClick={(event) => {
        event.preventDefault();
        if (customFunction) {
          customFunction();
          return;
        }
        toggleMark(editor, format);
      }}
    />
  );
};

const BlockButton = ({ format, Icon }) => {
  const editor = useSlate();

  return (
    <Icon
      className={`toolbar-button ${
        isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )
          ? "active-toolbar-button"
          : "passive-toolbar-button"
      }`}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

export default Sklate;
