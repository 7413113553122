const { observer } = require("mobx-react-lite");
import { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { fetchData, ButtonFetch } from "../../Utils/Helper";
import { useParams } from "react-router-dom";
import { DocumentContext } from "../../stores/DocumentStore";
import { toJS } from "mobx";
import Sklate from "./Sklate";
import { ConditionAdding } from "./ConditionAdding";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";

const MainDocument = observer((props) => {
  const { documentId } = useParams();

  const [showResp, setShowResp] = useState(false);

  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [
        {
          text: "New Document...",
        },
      ],
    },
  ]);

  const documentStore = useContext(DocumentContext);

  useEffect(() => {
    documentStore.currentDocumentId = documentId;

    const fetchStuff = async () => {
      //  if (documentStore.variablesNew.length > 1) return;
      const [vars, err] = await fetchData("/flowapi/getAllVariables");

      if (err) {
        return;
      }

      documentStore.variablesNew = [...vars];

      const [document, error] = await fetchData(
        `/docapi/new-document/document/${documentId}`
      );

      if (error) return;

      documentStore.currentDocument = { ...document };
      documentStore.mainDocumentConditions = { ...document.conditions };
    };

    const fetchSubdocuments = async () => {
      const [subdoclist, error] = await fetchData(
        `/docapi/new-subdocument/subdocumentslist`
      );

      if (error) return;

      documentStore.subDocumentList = subdoclist;
    };

    fetchStuff();
    fetchSubdocuments();
  }, []);

  return Object.keys(documentStore.currentDocument).length != 0 ? (
    <div>
      <div className="status-bar">
        <input
          className="header-input custom-margin"
          value={documentStore.currentDocument?.name}
          onChange={(e) =>
            (documentStore.currentDocument.name = e.target.value)
          }
        />

        <ButtonFetch
          className="save-button"
          fetchUrl="/docapi/new-document/document"
          fetchOptions={{
            method: "put",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: documentStore.currentDocumentId,
              ...toJS(documentStore.currentDocument),
              conditions: (() => {
                var ret = {};
                Object.keys(documentStore.mainDocumentConditions).map((x) => {
                  ret[x] = documentStore.mainDocumentConditions[x];
                });
                return ret;
              })(),
            }),
          }}
          successMessage="Saved"
          failMessage="Failed"
          initMessage="Save"
        />
        <Button
          size="small"
          variant="contained"
          disableElevation
          className="better-button"
          style={{
            margin: "10px",
          }}
          onClick={async (e) => {
            setShowResp(!showResp);
          }}
        >
          Preview Document
        </Button>
      </div>

      <div>{showResp ? <SelectResponseElement /> : <></>}</div>

      <div>
        <Sklate
          value={toJS(documentStore.currentDocument.value) ?? value}
          mentionList={toJS(documentStore.variablesNew)}
          additionalToolbar={toJS(documentStore.additionalToolbar)}
          onChange={(value) => {
            var temp = documentStore.currentDocument;
            temp.value = [...value];
            documentStore.currentDocument = { ...temp };
          }}
        />
        <div className="second-component">
          <ConditionAdding type="maindocument" />
        </div>
      </div>
    </div>
  ) : (
    <div className="entire-page">
      <ReactLoading type="spokes" color="black" />
    </div>
  );
});

export const SelectResponseElement = observer(() => {
  const [respList, setRespList] = useState([]);

  const [responseId, setResponse] = useState("");

  const documentStore = useContext(DocumentContext);

  const history = useHistory();

  useEffect(() => {
    const fetchStuff = async () => {
      const [data, err] = await fetchData(`/api/claimed`);

      if (err) {
        return;
      }

      setRespList(data);
    };
    fetchStuff();
  }, []);

  return (
    <div>
      <select
        className="better-select"
        value={responseId}
        onChange={(e) => setResponse(e.target.value)}
      >
        <option>Select...</option>
        {respList?.map((x, ind) => {
          return (
            <option key={`${x.id}`} value={`${x.id}`}>
              {x.id}
            </option>
          );
        })}
      </select>
      <Button
        variant="outlined"
        size="small"
        style={{
          margin: "10px",
        }}
        onClick={(e) => {
          history.push(
            `/preview/${documentStore.currentDocument.id}/${responseId}`
          );
        }}
      >
        Preview
      </Button>
    </div>
  );
});

export default MainDocument;
