import { observer } from "mobx-react-lite";
import {
  TimeSelector,
  DateTimeSelector,
  DateSelector,
} from "./ResolveDateTime.util";
import { useContext } from "react";
import { ClientContext } from "../../../stores/ClientStore";

export const ResolveDateTime = observer(({ question, ...props }) => {
  const pickerType = question.type;
  const clientStore = useContext(ClientContext);

  console.log(props);

  const resolveQuestionId = () =>
    `${question.id}${props.addSuffix ? `-${props.suffix}` : ""}${
      props.tableFix ? props.tableSuffix : ""
    }`;

  const getStore = () => (props.adminStore ? props.adminStore : clientStore);

  const registerResponse = (value) => {
    var temp = getStore().currentResponses();

    temp[resolveQuestionId()] = value;

    getStore().responses[getStore().activeTab[1].formName] = {
      ...temp,
    };
  };

  const importMap = () => {
    switch (pickerType) {
      case "date":
        return (
          <DateSelector
            value={getStore().currentResponses()[resolveQuestionId()]}
            setValue={registerResponse}
            disabled={props.disabled}
          />
        );
      case "time":
        return (
          <TimeSelector
            value={getStore().currentResponses()[resolveQuestionId()]}
            setValue={registerResponse}
            disabled={props.disabled}
          />
        );
      default:
        return (
          <DateTimeSelector
            value={getStore().currentResponses()[resolveQuestionId()]}
            setValue={registerResponse}
            disabled={props.disabled}
          />
        );
    }
  };

  return (
    <>
      <div className={question.id}>{importMap()}</div>
    </>
  );
});
