import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import firebase from '../../Utils/firebase';
import './ContractModal.css';
import ReactLoading from "react-loading";

var signerEmail, signerName;
const CommunicationModal = ({ showCommunicationStatus, setShowCommunicationStatus, Case, mailTemplates }) => {

  const [communicationStatus, setCommunicationStatus] = useState({});
  const [btnDisableStatus, setBtnDisableStatus] = useState({});
  const resetCredentialsMailTemplateId = 'SetCredentials';

  useEffect(() => {
    signerName = Case.Vorname + ' ' + Case.Nachname;
    signerEmail = Case.Kontaktdaten;
    if( typeof Case.communicationStatus != 'undefined' ) {
      setCommunicationStatus(Case.communicationStatus);
    } else {
      setCommunicationStatus({});
    }
  }, [Case]);

  const setResetCredentialsMailStatus = async(status) => {
    try {
      let temp = {
        status: status
      };
      setCommunicationStatus({...communicationStatus, [resetCredentialsMailTemplateId]: temp });

      const result = await fetch("/api/holidaysherpa/credentialMailStatus", {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: Case.id,
          templateId: resetCredentialsMailTemplateId,
          status: status
        })
      });
      const resp = await result.json();
    } catch(err) {
      console.log(err);
    }
  };

  const sendResetCredentialsMail = async(btnId) => {
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try{
      const sent = await firebase.auth().sendPasswordResetEmail(signerEmail);
      setResetCredentialsMailStatus('sent');
    } catch(err) {
      setResetCredentialsMailStatus(`failed with error ${err}`);
      console.log('error ', err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const sendMail = async (template, btnId) => {
    console.log('template', template, signerName, signerEmail, Case.id);
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/holidaysherpa/sendMail`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          template: template,
          recepientMail: signerEmail,
          caseId: Case.id,
          responses: Case
        })
      });
      const resp = await result.json();
      console.log('communication send status', resp);
      let temp = {
        status: resp.status
      };
      setCommunicationStatus({...communicationStatus, [template.id]: temp });
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const spinner = (bool) => {
    return (
      bool
        ? <ReactLoading type="spokes" color="black" className='ms-2' height="22px" width="22px"/>
        : <></>
    );
  };

  return (
    <Modal show={showCommunicationStatus} className="contract">
      <div className="p-4">
        <h4 className="mb-3"><b> Communication Status: {Case.id} </b></h4>

        <div className="contract-body">
          <Table className="table table-striped">
            <thead>
              <tr key="0">
                <th scope="col" width="10%">S. No.</th>
                <th scope="col" width="30%">Email Name</th>
                <th scope="col" width="30%">Action</th>
                <th scope="col" width="30%">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr key="1">
                <th scope="row" width="10%">1</th>
                <td width="30%"> Send Credentials </td>
                <td width="30%"> 
                  <button
                    className='px-4'
                    disabled={ btnDisableStatus[`action_1`] }
                    onClick= {() => {
                      sendResetCredentialsMail(`action_1`);
                    }}
                  >
                    <div className='d-flex'>
                      { communicationStatus[resetCredentialsMailTemplateId] ? communicationStatus[resetCredentialsMailTemplateId].status == '' ? 'Send' : 'Resend' : 'Send' }
                      { spinner(btnDisableStatus[`action_1`]) }
                    </div>
                  </button>
                </td>
                <td width="30%"> {communicationStatus[resetCredentialsMailTemplateId] ? communicationStatus[resetCredentialsMailTemplateId].status : ''} </td>
              </tr>
              {
                mailTemplates.map((template, i) => {
                  return (
                    <tr key={i+2}>
                      <th scope="row" width="10%">{i + 2}</th>
                      <td width="30%"> {template.title} </td>
                      <td width="30%"> 
                        <button
                          className='px-4'
                          disabled={ btnDisableStatus[`action_${i+2}`] }
                          onClick= {() => {
                            sendMail(template, `action_${i+2}`);
                          }}
                        >
                          <div className='d-flex'>
                            { communicationStatus[template.id] ? communicationStatus[template.id].status == '' ? 'Send' : 'Resend' : 'Send' }
                            { spinner(btnDisableStatus[`action_${i+2}`]) }
                          </div>
                        </button>
                      </td>
                      <td width="30%"> {communicationStatus[template.id] ? communicationStatus[template.id].status : ''} </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>

        <div className="d-flex mt-2">
          <button className="contract-btn" onClick={() => setShowCommunicationStatus(false)} >Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default CommunicationModal;

