import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import './ContractModal.css';
import ReactLoading from "react-loading";

var signerEmail, signerName;
var btnStatus = {
  action_0: false, 
  action_1: false,
  action_2: false,
  refresh_0: false,
  refresh_1: false,
  refresh_2: false
};
const ContractModal = ({ showContractStatus, setShowContractStatus, Case }) => {

  const templates = [
    {
      name: "Vollmacht & Mandanteninformation",
      id: "44488000000034011",
      actionId: "44488000000034032"
    },
    {
      name: "Geschäftsbesorgungsvertrag LegalAI Muster zur Digitalisierung",
      id: "44488000000034097",
      actionId: "44488000000034118"
    }
  ];

  const [contractStatus, setContractStatus] = useState({});
  const [btnDisableStatus, setBtnDisableStatus] = useState(btnStatus);

  useEffect(()=>{

    const InitializeContractStatus = async() => {
      let initialContractStatus = {};

      templates.forEach((template) => {
        initialContractStatus[template.id] = {
          name: template.name,
          status: "Unsent",
          actionId: template.actionId,
          requestId: ""
        };
      });
      setContractStatus(initialContractStatus);

      const result = await fetch("/api/holidaysherpa/initiateNewField", {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: Case.id,
          fieldName: 'contractStatus',
          fieldData: initialContractStatus,
        })
      });
      const resp = await result.json();
      console.log("update contract status: ", resp);
    };

    signerName = Case.Vorname + ' ' + Case.Nachname;
    signerEmail = Case.Kontaktdaten ? Case.Kontaktdaten : Case['Kontaktdaten '];
    console.log("contract status: ", Case.contractStatus, Case.id, signerEmail, Case);
    if (typeof Case.id == 'undefined'){
    } else if (typeof Case.contractStatus == 'undefined') {
      InitializeContractStatus();
    } else {
      setContractStatus(Case.contractStatus);
    }
  }, [Case]);


  const sendContract = async (templateId, actionId, btnId) => {
    console.log('templateId', templateId, signerName, signerEmail, Case.id, actionId);
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/zohoSign/signing_url`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          templateId: templateId,
          signerName: signerName,
          signerEmail: signerEmail,
          caseId: Case.id,
          actionId: actionId
        })
      });
      const resp = await result.json();
      console.log('contract send status', resp);
      if(resp.success) {
        setContractStatus(resp.contractStatus);
      }
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const resendContract = async(requestId, btnId) => {
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/zohoSign/resendContract/`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          caseId: Case.id,
          requestId: requestId
        })
      });
      const resp = await result.json();
      console.log('contract resend status', resp);
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };


  const refreshContractStatus = async( requestId, templateId, btnId) => {
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/zohoSign/document/getStatus`, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          templateId: templateId,
          requestId: requestId,
          caseId: Case.id
        })
      });
      const resp = await result.json();
      console.log('refresh contract status', resp);
      if(resp.success) {
        setContractStatus(resp.contractStatus);
      }
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };


  const downloadContract = async (requestId, templateId, btnId) => {
    setBtnDisableStatus({...btnDisableStatus, [btnId]: true});
    try {
      const result = await fetch(`/api/zohoSign/downloadContract/${requestId}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });
      const blob = await result.blob();
      download(blob, `${Case.id}_${contractStatus[templateId].name}`);
    } catch(err) {
      console.log("error - ", err);
    }
    setBtnDisableStatus({...btnDisableStatus, [btnId]: false});
  };

  const download = (data, name) => {
    const url = URL.createObjectURL(new Blob([data]));
    const a = document.createElement('a');
    a.download = `${name}.pdf`;
    a.href = url;
    a.target = '_self';

    a.click();

    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  };

  const spinner = (bool) => {
    return (
      bool
        ? <ReactLoading type="spokes" color="black" className='ms-2' height="22px" width="22px"/>
        : <></>
    );
  };

  return (
    <Modal show={showContractStatus} className="contract">
      <div className="p-4">
        <h4 className="mb-3"><b> Contract Status: {Case.id} </b></h4>

        <div className="contract-body">
          <Table className="table table-striped">
            <thead>
              <tr key="0">
                <th scope="col" width="10%">S. No.</th>
                <th scope="col" width="30%">Name</th>
                <th scope="col" width="20%">Status</th>
                <th scope="col" width="20%">Action</th>
                <th scope="col" width="20%">Refresh Status</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(contractStatus).map((templateId, i) => {
                  return (
                    <tr key={i+1}>
                      <th scope="row" width="10%">{i + 1}</th>
                      <td width="30%"> {contractStatus[templateId].name} </td>
                      <td width="20%"> {contractStatus[templateId].status} </td>
                      <td width="20%"> 
                        <button
                          className='px-4'
                          disabled={ btnDisableStatus[`send_action_${i}`] }
                          onClick= {() => {
                            sendContract(templateId, contractStatus[templateId].actionId, `send_action_${i}`);
                          }}
                        >
                          <div className='d-flex'>
                            Send
                            { spinner(btnDisableStatus[`send_action_${i}`]) }
                          </div>
                        </button>
                        <button
                          className='px-4'
                          disabled={ btnDisableStatus[`action_${i}`] }
                          onClick= {() => {
                            contractStatus[templateId].status == 'completed' ? downloadContract(contractStatus[templateId].requestId, templateId, `action_${i}`) : resendContract(contractStatus[templateId].requestId, `action_${i}`);
                          }}
                          style={{
                            display: contractStatus[templateId].requestId == "" ? "none" : "block"
                          }}
                        >
                          <div className='d-flex'>
                            { contractStatus[templateId].status == 'completed' ? "Download" : "Resend" }
                            { spinner(btnDisableStatus[`action_${i}`]) }
                          </div>
                        </button>
                      </td>
                      <td width="20%">
                        <button
                          className='px-4'
                          disabled={ btnDisableStatus[`refresh_${i}`] || (contractStatus[templateId].requestId == '') }
                          onClick={()=>{
                            refreshContractStatus(contractStatus[templateId].requestId, templateId, `refresh_${i}`);
                          }}
                        >
                          <div className='d-flex'>
                            Refresh
                            { spinner(btnDisableStatus[`refresh_${i}`]) }
                          </div>
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>

        <div className="d-flex mt-2">
          <button className="contract-btn" onClick={() => setShowContractStatus(false)} >Close</button>
          <button className="contract-btn" style={{background: '#3249B4'}} disabled> Download Contracts</button>
        </div>
      </div>
    </Modal>
  );
};

export default ContractModal;

