import { maintainOrderFlows } from "../../../Utils/constants";

export const registerResponse = (event, question, clientStore) => {
  ({ event, question });

  var temp = clientStore.currentResponses();

  temp[question.id] = event.target.value;
  temp[question.id] = event.target.value;

  clientStore.responses[clientStore.activeTab[1].formName] = {
    ...temp,
  };
};

export const renderOptionsForQues = (options, name, element) => {
  return options;

  if (!options) return [];

  let gesamteIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("gesamte")
  );

  let gesamteRemoved;
  if (gesamteIndex != -1) {
    [gesamteRemoved] = options?.splice(gesamteIndex, 1);
  }

  let andereIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("andere")
  );

  let andereRemoved;
  if (andereIndex != -1) {
    [andereRemoved] = options?.splice(andereIndex, 1);
  }

  let sonstigeIndex = options?.findIndex((op) =>
    op.value?.toLowerCase().startsWith("sonstige")
  );

  let sonstigeRemoved;
  if (sonstigeIndex != -1) {
    [sonstigeRemoved] = options?.splice(sonstigeIndex, 1);
    //
  }

  if (maintainOrderFlows.findIndex((e) => e == name) == -1) {
    options = options
      ?.slice()
      .sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1
      );
  } else {
  }
  if (andereIndex != -1) {
    //
    options.push(andereRemoved);
  }
  if (sonstigeIndex != -1) {
    //
    options.push(sonstigeRemoved);
  }

  if (gesamteIndex != -1) {
    options.splice(0, 0, gesamteRemoved);
  }

  element.optionsSorted = true;
  element.gesamtes = gesamteRemoved;
  element.options = options;
  return options;
};

export const subQuestionElements = (
  question,
  ResolveElement,
  resolveProps,
  clientStore,
  rawElement,
  adminStore
) => {
  if (rawElement) {
    console.log({ question });
    return question.options?.map((option) => {
      if (
        option.triggers &&
        option.triggers[0].questions &&
        option.text == clientStore.currentResponses()[question.name]
      ) {
        return option.triggers[0].questions.map((subquestion, index) => {
          return (
            <div>
              <ResolveElement
                rawElement={subquestion}
                parentType={"question"}
                disabled={resolveProps.disabled}
                shouldNotScroll={resolveProps.shouldNotScroll}
                adminStore={adminStore}
                tableFix={resolveProps.tableFix}
                tableSuffix={resolveProps.tableSuffix}
              />
            </div>
          );
        });
      }
    });
  }

  return question.options?.map((option) => {
    if (
      option.subquestions &&
      option.text === clientStore.currentResponses()[question.id]
    ) {
      return option.subquestions.map((subquestionId, index) => {
        return (
          <div key={`${index}-${subquestionId}`}>
            <ResolveElement
              parentType={"question"}
              questionId={subquestionId}
              disabled={resolveProps.disabled}
              shouldNotScroll={resolveProps.shouldNotScroll}
              adminStore={adminStore}
            />
          </div>
        );
      });
    }
    return null;
  });
};
