import { useLocalStore } from "mobx-react-lite";
import { createContext } from "react";

export const createDocumentStore = () => {
  return {
    // - /maindocument route
    // list of paragraphs in the current document selected
    paragraphs: [], // structure of paragraphs {type: "",content:"",subdocument="",condition=[list of conditions] || null}

    previewID: "select", // response id for which we are previewing the main document
    previewData: "", // preview data populated with main document and response id data from firestore
    displaySection: "PARAGRAPHS", // for maindocument page, if it is set to PARAGRAPHS, then we see main documents and if this is set to PREVIEW, then we see the preview of main document
    currentDocument: {}, // current document
    currentDocumentId: "", // current main document id selected in dropdown

    // for /subdocument route
    currentSubDocument: {},
    currentSubId: "",

    variables: [], // global variables and variables exported from all the flows

    getParagraph(index) {
      return this.paragraphs[index];
    },

    //New scratch rich-text-editor here:

    activeConditionThread: null,
    conditions: {},
    variablesNew: [
      { id: "12guopnY3CiKMZ86VNi4PT", name: "beginDate", type: "date" },
    ],

    additionalToolbar: "",
    subDocumentList: [],
    mainDocumentConditions: {},

    subDocUpdate: false,
    mainDocUpdate: false,

    refresh: 0,
  };
};

export const DocumentContext = createContext(null);

export const DocumentProvider = ({ children }) => {
  const documentStore = useLocalStore(createDocumentStore);

  return (
    <DocumentContext.Provider value={documentStore}>
      {children}
    </DocumentContext.Provider>
  );
};
