export const maintainOrderFlows = [
  "MangelSpecify",
  "PauschalorIndividualTrip",
  "dateOfDeficiency",
  "mangelBeseitigung",
  "deficiencyNotice",
  "typeOfDeficiencyReplacementAccomodation",
  "defectBalconyTerraceOrSeaview",
  "bugsLocation",
  "bugsDeadOrAlive",
  "baularmSub",
  "tierGehort",
  "acProblemsLocation",
  "locationVerschmutzung",
  "LocationSmell",
  "heatingProblemsLocation",
  "reasonsForCancellationPriorToHolidays",
  "bugsExactPlacesPrivate",
  "typeofDeficiencySanitarySupplies",
  "artLarm",
  "extentVolume3",
  "bugsQuantity",
  "whichRoomTypeSizeOfApartment",
  "assuredRoomTypeTravelDocuments",
  "newRoomType",
  "oldRoomType",
  "HowOftenWasTheRoomCleaned",
  "HowOftenNewHandtuecher",
  "specificHowOftenNewBedclothes",
];
