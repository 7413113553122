import { observer } from "mobx-react-lite";

import { useContext, useState, useEffect } from "react";

import { DocumentContext } from "../../stores/DocumentStore";

import { toJS } from "mobx";

import { serialize } from "./serializingUtils";

import { v4 as uuid } from "uuid";

import { deserialize } from "./serializingUtils";

import { useSlate } from "slate-react";

import { insertSubdocumentBlock } from "./addSubdocumentBlockUtils";

import { insertConditionThread, removeCondition } from "./ConditionUtils";

import { Button } from "@material-ui/core";
import { Transforms } from "slate";

import { parse } from "node-html-parser";
import { fetchData } from "../../Utils/Helper";

export const SerializeButton = observer((props) => {
  // const editor = useSlate();

  const documentStore = useContext(DocumentContext);

  //

  const mentions = (htmlContent) => {
    // htmlContent = processAnchor(htmlContent);
    const root = parse(htmlContent);
    try {
      const mentions = root.querySelectorAll(".mention");

      var ret = mentions.map((element) => {
        element.setAttribute("data-mention", "mention");
        element.setAttribute("class", "processed");
        try {
          var text = element.childNodes[0].rawText
            .split("@")[1]
            .replace("#", ".");

          element.set_content(
            `{{@if(it.${text})}}{{it.${text}}}{{#else}}${text} (undefined){{/if}}`
          );
        } catch (err) {}
        return element.childNodes[0].toString();
      });

      return root.toString();
    } catch (err) {
      return null;
    }
  };

  const processAnchor = (input) => {
    try {
      let root = parse(input);
      let a = root.getElementsByTagName("a");

      a.map((tag) => {
        if (!tag.getAttribute("href").includes("@")) {
          tag.innerHTML = `{{@if(${tag.getAttribute("href")})}}${
            tag.innerHTML
          }{{#else}}{{/if}}`;
          tag.removeAttribute("href");
        }
        //
        //
      });
      let output = root.toString();
      output = output.replace(/<a[^>]*>/g, "").replace(/<\/a>/g, "");

      return output;
    } catch (e) {
      return { e };
    }
  };

  const subdocumentFetcher = async (id) => {
    try {
      const paragraph = await fetch(`/docapi/subdocument/${id}`);

      var paragraphContent = await paragraph.json();

      paragraphContent = paragraphContent.data;

      var accumulated = "";

      const changeToVariable = (title) => {
        let val = title.replace(/[^a-zA-Z0-9]/g, "");
        if (val) {
          if (val[0] >= "0" && val[0] <= "9") {
            return "test";
          } else {
            return val;
          }
        } else {
          return "test";
        }
        //return title.toLowerCase().trim().split(" ").join("");
      };

      const processForComparision = (value) => {
        return value.toLowerCase();
      };

      paragraphContent.paragraphs.map((block) => {
        try {
          if (block.type == "Plain") {
            accumulated += block.content;
          } else if (block.type == "Conditional") {
            const testing = (str) => {
              if (!str) return "";

              var t = str.split("#");
              var ret = "";
              ret += `it.${str.replace("#", ".").replace("-", "")}`;
              return ret;
            };

            if (block.condition[0].value == "if") {
              accumulated += `{{@if(${testing(block.condition[1].value)})}}`;
              //accumulated += `<mark class='${testing(block.condition[1].value)}'>`
            } else {
              var map = {
                equalto: "==",
                lessthan: "<",
                greaterthan: ">",
              };
              var a = `${block.condition[1].value}`;
              var b = `${block.condition[2].value}`;

              if (block.condition[0].value == "if") {
                accumulated += `{{@if(${testing(a)})}}`;
              } else {
                if (b.includes("_option_")) {
                  b = `'${b.split("_option_")[1]}'`;
                  accumulated += `{{@if(it.${a} ${
                    map[block.condition[0].value]
                  } ${processForComparision(b)})}}`;
                } else if (block.condition[2].custom) {
                  accumulated += `{{@if(${testing(a)} ${
                    map[block.condition[0].value]
                  } "${processForComparision(b)}"))}}`;
                } else {
                  accumulated += `{{@if(it.${a} ${
                    map[block.condition[0].value]
                  } it.${b})}}`;
                }
              }
            }
            accumulated += block.content;
            accumulated += "{{/if}}";

            accumulated = accumulated
              .replace(/“/g, '"')
              .replace(/”/g, '"')
              .replace(/<p>\s<\/p>/g, "");
          }
        } catch (err) {}
      });

      return [
        null,
        {
          title: paragraphContent.title,
          paragraphs: paragraphContent.paragraphs,
          accumulated,
        },
      ];
    } catch (err) {
      return [err, null];
    }
  };

  const doStuff = async () => {
    const data = [
      {
        id: "0911cdf9-8a24-4e94-8ed3-6d21f41029d4",
        title: "Letter Header",
      },
      {
        id: "10d4d609-720e-46cc-8eb3-fe28e4d68173",
        title: "bugs",
      },
      {
        id: "17978e37-3a88-4ff6-979a-9747215003d7",
        title: "Sanitäre Einrichtungen und Versorgung",
      },
      {
        id: "2577bf6d-d43c-477f-ad49-3e04e2f11d1c",
        title: "larm",
      },
      {
        id: "429a8453-bed8-431c-91d5-2f49b6ee68d8",
        title: "cancelTrip",
      },
      {
        id: "4a1308a4-8a02-49b6-b262-7b9ac0be22d5",
        title: "Generic Letter Ending",
      },
      {
        id: "4ecb7ef8-759d-43d8-85e3-18214e149425",
        title: "AC",
      },
      {
        id: "5d2a836e-3ac4-4928-8953-5fb470f5f498",
        title: "Ersatzunterbringung",
      },
      {
        id: "6c268223-5fb9-433d-864d-d1671d938eb6",
        title: "testPool",
      },
      {
        id: "6c2efad7-33eb-40c8-bc83-7382ce7ecf8b",
        title: "larmTest",
      },
      {
        id: "96ed15fc-de20-4bda-9184-0c105fe93b17",
        title: "BalkonMeerblickundTerrasse",
      },
      {
        id: "9cdf00ee-43a5-4f2d-9c7b-bfc4ce88ef60",
        title: "sub",
      },
      {
        id: "b1b42624-4840-44bb-8784-023cc820b328",
        title: "conditionalWrapping",
      },
      {
        id: "b41aa6d7-675a-45bc-b0e5-5abb94f23c6c",
        title: "pool",
      },
      {
        id: "ce611636-acea-4647-bb81-365cbaac441e",
        title: "cancelTrip",
      },
      {
        id: "cfaaaefb-a77e-4adc-8271-a988bb4b079f",
        title: "recreationalFacilities",
      },
      {
        id: "e0334b53-08fe-4881-8e61-acf2b575e70a",
        title: "BalkonTest",
      },
      {
        id: "e4a23c4d-a60e-4ac0-ad48-50b562fefc25",
        title: "testSubdoc",
      },
      {
        id: "e893aee5-9c0e-45de-b8e2-dc95fca91c5a",
        title: "letterEndingTest",
      },
      {
        id: "f4201537-6f0e-49b0-8903-3ccf1062c493",
        title: "conditionalWrapping",
      },
    ];

    const data2 = [
      {
        id: "cfaaaefb-a77e-4adc-8271-a988bb4b079f",
        title: "recreationalFacilities",
      },
    ];

    await Promise.all(
      data.map(async (x) => {
        const [err, subDoc] = await subdocumentFetcher(x.id);

        var str = processAnchor(subDoc.accumulated);

        str = str.replaceAll("{{#else}}", "{{/if}}{{@if(it.beginDate > 0)}}");

        str = str.replaceAll("{{@if(", `<mark class="`);
        str = str.replaceAll(")}}", `">`);
        str = str.replaceAll("{{/if}}", "</mark>");

        const document = new DOMParser().parseFromString(str, "text/html");

        var conditionMap = { 0: "0" };

        const output = deserialize(
          document.body,
          { bold: false },
          conditionMap
        );

        const [data, err2] = await fetchData(
          `/docapi/new-subdocument/subdocument`,
          {
            method: "put",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: uuid(),
              value: output,
              conditions: conditionMap,
              name: x.title,
            }),
          }
        );
      })
    );
  };

  return (
    <button
      className="taskbar-button"
      onMouseDown={async (event) => {
        event.preventDefault();
        await doStuff();
      }}
    >
      Serialize
    </button>
  );
});

export const EditorToolbar = (props) => {
  return <div className="editor-toolbar">{props.children}</div>;
};

export const AdditionalToolbar = observer(({ type }) => {
  const [subDocId, setSubDocId] = useState(null);

  const editor = useSlate();

  const documentStore = useContext(DocumentContext);

  if (type == "subdocument") {
    return (
      <div className="editor-toolbar">
        <select
          className="better-select"
          value={subDocId}
          onChange={(e) => setSubDocId(e.target.value)}
        >
          <option>Select...</option>
          {documentStore.subDocumentList?.map((opt, ind) => {
            return (
              <option key={ind} value={opt.id}>
                {opt.name}
              </option>
            );
          })}
        </select>
        <Button
          variant="outlined"
          size="small"
          onClick={async (e) => {
            e.preventDefault();
            const { subdoc, blockCondition } = await insertSubdocumentBlock(
              editor,
              subDocId
            );

            documentStore.mainDocumentConditions = {
              ...toJS(documentStore.mainDocumentConditions),
              ...subdoc.conditions,
              ...blockCondition,
            };

            documentStore.additionalToolbar = "";
          }}
        >
          Add Subdocument
        </Button>
      </div>
    );
  }

  return <></>;
});

export const RemoveConditionButton = observer(() => {
  const editor = useSlate();

  const documentStore = useContext(DocumentContext);

  return (
    <button
      className="taskbar-button"
      onMouseDown={(event) => {
        event.preventDefault();
        documentStore.activeConditionThread = null;
        removeCondition(editor, documentStore.activeConditionThread);
      }}
    >
      Remove Condition
    </button>
  );
});

export const AddConditionButton = observer(() => {
  const editor = useSlate();

  const documentStore = useContext(DocumentContext);

  const addConditionThread = (threadId, newThread) => {
    documentStore.conditions[threadId] = newThread;
    documentStore.mainDocumentConditions[threadId] = newThread;
  };

  return (
    <button
      className="taskbar-button"
      onMouseDown={(event) => {
        event.preventDefault();
        insertConditionThread(editor, addConditionThread);
      }}
    >
      Condition
    </button>
  );
});

export const AddSubdocumentButton = observer(() => {
  const editor = useSlate();

  const documentStore = useContext(DocumentContext);

  return (
    <button
      className="taskbar-button"
      onMouseDown={(event) => {
        event.preventDefault();
        documentStore.additionalToolbar = "subdocument";
      }}
    >
      Subdocument
    </button>
  );
});
