import { useEffect, useContext, useState } from "react";
import Report from "../Components/Admin/Report";
import "./pagestyle.css";
import "../App.css";
import { observer } from "mobx-react-lite";
import { AdminContext } from "../stores/AdminStore";
import { toJS } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { DisplayResponses } from "../Components/Admin/DisplayResponses";
import { PrimaryButton } from "../Utils/Helper";
import { useHistory } from "react-router-dom";

const AdminPage = observer((props) => {
  const adminStore = useContext(AdminContext);

  let [similarCases, setSimilarCases] = useState([]);
  let [similarCasesMessage, setSimilarCasesMessage] = useState("");
  let [startDate, setStartDate] = useState("1971-01-01"); 
  let [endDate, setEndDate] = useState("2099-01-01"); 

  const history = useHistory();

  const getDateInFormate = (milliseconds)=>{
    let date = new Date(milliseconds);
    let mm = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    return `${dd}-${mm}-${date.getFullYear()} ${hh}:${min}:${ss}`;
  }

  const initAdmin = async () => {
    const result = await fetch(`/api/claimed/${startDate}/${endDate}`);
    const data = await result.json();

    adminStore.caseList = data.data;
  };

  useEffect(() => {
    initAdmin();
  }, []);

  useEffect(() => {
    const fetchSimilarCases = async () => {
      setSimilarCasesMessage("");
      const result = await fetch(
        `/api/similarCases/case_resolution`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(adminStore.currentCase.responses),
        }
      );

      const data = await result.json();

      if (data.success) {
        setSimilarCasesMessage("");
        setSimilarCases(data.data.juris);
      } else {
        setSimilarCasesMessage(
          "Some error occured during fetching similar cases, please try again!\nShowing some dummy cases for now"
        );
        setSimilarCases([]);
      }
    };

    if (adminStore.currentCase && adminStore.currentCase.id) {
      fetchSimilarCases();
    } 
  }, [adminStore.currentCase]);

  const resolveTab = () => {
    return (
      <Row className="justify-content-center">
        <Col xs="12" className="mt-4">
          <Form>
            <Form.Group as={Row} controlId="selectCases">
              <Form.Label column xs="12" md="2">
                <b>Rechtsfall</b>
              </Form.Label>
              <Col xs="12" md="10" lg="10">
                <Form.Select
                  value={
                    adminStore.currentCase
                      ? adminStore.currentCase.id
                      : "select"
                  }
                  onChange={(e) => {
                    const a = adminStore.caseList.find(
                      (c) => c.id === e.target.value
                    );

                    adminStore.currentCase = { ...a };
                  }}
                >
                  <option value="select">Select Case</option>
                  {adminStore.caseList.map((x) => {
                    let ms = x.timestamp?._seconds * 1000;
                    let date = ms ? getDateInFormate(ms) : null;
                    return (
                      <option key={`${x.id}`} value={`${x.id}`}>
                        {x.id} {date ? date : ''}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Form>

          <div className="d-flex justify-content-around flex-wrap">
            <div className="mt-2">
              <Form.Group>
                <Form.Control
                  locale="de"
                  type="date"
                  value={startDate}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <div className="mt-2">
              <Form.Group>
                <Form.Control
                  locale="de"
                  type="date"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <div className="mt-2">
              <button className="btn-outline-success" onClick={initAdmin}>Search</button>
            </div>
          </div>

          {adminStore.currentCase.id ? (
            <div>
              <Report
                case={adminStore.currentCase}
                similarCases={similarCases}
              />

              <PrimaryButton
                onClick={() => {
                  history.push("/display-response");
                }}
              >
                Display Responses
              </PrimaryButton>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    );
  };

  return <Container className="container-report">{resolveTab()}</Container>;
});

export default AdminPage;
