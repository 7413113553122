import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../stores/DocumentStore";
import { observer } from "mobx-react-lite";

import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { toJS } from "mobx";

export const Editor = observer((props) => {
  const documentStore = useContext(DocumentContext);

  // claimed cases from /api
  const [caseList, setList] = useState([]);

  var config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "|",
        "imageUpload",
        "blockQuote",
        "insertTable",
        "mediaEmbed",
        "undo",
        "redo",
        "alignment",
      ],
    },
    plugins: CustomEditor.builtinPlugins,
    // plugins: ["Mention"],
  };

  // will run everytime documentStore.previewID changes
  useEffect(() => {
    const getDocument = async (id) => {
      if (id != "" && id != "select") {
        const result = await fetch(
          `/docapi/document/${documentStore.currentDocumentID}/${id}`
        );
        const resp = await result.json();

        documentStore.previewData = resp.data;
      } else {
        documentStore.previewData = "";
      }
    };

    getDocument(documentStore.previewID);
  }, [documentStore.previewID, documentStore.currentDocumentID]);

  useEffect(() => {
    const getList = async () => {
      const result = await fetch("/api/claimed");
      const resp = await result.json();

      setList(resp.data);
    };

    getList();
  }, []);

  return (
    <div>
      <div>
        <select
          value={documentStore.previewID}
          onChange={(e) => {
            documentStore.previewID = e.target.value;
          }}
        >
          <option value={"select"}>Select...</option>
          {caseList.map((x) => {
            return (
              <option key={`${x.id}`} value={`${x.id}`}>
                {x.id}
              </option>
            );
          })}
        </select>
      </div>
      <CKEditor
        editor={CustomEditor}
        config={config}
        data={`${documentStore.previewData}`}
        onReady={(editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        disabled
      />
    </div>
  );
});
